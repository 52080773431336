import styled from "styled-components";
import NewProductCard from "../Cards/NewProductCard";
import bannerimage from '../../assets/smallbanner.png'

const FeaturedProducts = ({ onView, products }) => {
  
  return (
    <Container>
      <header>
        <div className="border-2 border-[#009900] w-full">
          <article className="parallelogram bg-[#009900]">
            <h1 className="text-white font-semibold">Featured Products</h1>
          </article>
        </div>
      </header>

      <main className="flex flex-col xl:flex-row gap-4 items-center">
        <div className="rotate-0 xl:rotate-90 w-full lg:w-[30%] h-full">
        <img src={bannerimage} alt={"just the title to be used"} className="w-full h-full"/>
        </div>
        <div className="top w-full lg:w-[80%]">
          {products?.slice(0, 5).map((product, idx) => (
            <NewProductCard
              onView={onView}
              key={idx}
              price={product.selling_price}
              product_name={product.product_name}
              rating={product.productRating}
              image={"https://api.ebn.ng/public" + product.product_thumbnail}
              id={product.id}
              tag={product.productTag}
              discount={product.discount_price}
              createdBy={product.created_by}
              slug={product.product_slug}
              {...product}
            />
          ))}
        </div>
      </main>
    </Container>
  );
};

export default FeaturedProducts;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  justify-content: center;
  margin: 40px 30px;

  main {
    margin-top: 30px;
    .top {
      display: flex;
      flex-direction: row;
      gap:10px;
      justify-content:start;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 20px;
    }
    
  }
`;
