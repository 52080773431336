import React, { useState } from "react";
import VendorShopCard from "../components/Cards/VendorShopCard";
import Bag from "./../assets/ebn.png";
import ReactPaginate from "react-paginate";
import { AiOutlineSearch } from "react-icons/ai";
import { useEffect } from "react";
import api from "../services/api";
import Loading from "../components/Loading";

const Vendors = () => {
  const [vendors, setVendors] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(0);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    const fetchVendorsWithProductCounts = async () => {
      setIsFetching(true);
      try {
        // Fetch the list of vendors
        const res = await api.customer.getVendors();
        const vendors = res.data?.vendors.filter(
          (val) => val.status === "active" && val.vendor_short_info !== ""
        );

        // Fetch product count for each vendor using their ID
        const vendorsWithProductCounts = await Promise.all(
          vendors.map(async (vendor) => {
            try {
              const vendorData = await api.customer.getVendor(vendor.id); // Assuming getVendor takes vendor ID as parameter
              return {
                ...vendor,
                productCount: vendorData.data?.productCount, // Adjust based on actual response structure
              };
            } catch (err) {
              console.error(
                `Error fetching product count for vendor ${vendor.id}`,
                err
              );
              return { ...vendor, productCount: 0 }; // Default to 0 or handle the error appropriately
            }
          })
        );

        // Update state with vendors and their product counts
        setVendors(vendorsWithProductCounts);
      } catch (err) {
        console.error("Error fetching vendors", err);
      } finally {
        setIsFetching(false);
      }
    };

    fetchVendorsWithProductCounts();
  }, []);

  const filteredVendors = vendors.filter((vendor) =>
    vendor.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const handleSearchInputChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const pageCount = Math.ceil(filteredVendors.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredVendors.slice(startIndex, endIndex);

  return (
    <main>
      <section className="vendorsPageTopSection">
        <h1 className="title">All Registered Vendors</h1>
        <div>
          <article>
            <input
              type="text"
              name=""
              id=""
              className=""
              placeholder="Search Vendors (by store name)...."
              value={searchQuery}
              onChange={handleSearchInputChange}
            />
            <AiOutlineSearch className="icon" />
          </article>
          <p className="text-xl font-semibold">
            we have{" "}
            <span className="text-green-400 font-medium italic">
              {filteredVendors.length}
            </span>{" "}
            active vendors
          </p>
        </div>
      </section>
      {isFetching ? (
        <div className="flex justify-center items-center">
          <Loading className={'w-[40px]'}/>
        </div>
      ) : (
        <section className="my-8 vendorsPageBottomSection">
          <div className="flex gap-3 flex-wrap justify-center">
            {paginatedData.map((item, index) => (
              <VendorShopCard
                key={index}
                image={item.image || Bag}
                shopName={item.name}
                shopInfo={item.vendor_short_info}
                id={item.id}
                stock={item.productCount}
              />
            ))}
          </div>
          <div className="my-8">
            <ReactPaginate
              previousLabel={"⇽"}
              nextLabel={"⇾"}
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageChange}
              containerClassName="paginateContainer"
              pageClassName="cursor-pointer py-1 px-2 rounded-full bg-gray-200 font-bold hover:bg-green-500 hover:text-white"
              previousClassName="nextPrev"
              nextClassName="nextPrev"
              activeClassName="bg-green-500 text-white"
            />
          </div>
        </section>
      )}
    </main>
  );
};

export default Vendors;
