import React, { useState } from 'react';
import { FaCheck, FaTruck, FaShippingFast } from 'react-icons/fa';


const OrderTracking = () => {
 const [orderStatus, setOrderStatus] = useState('');

 // Simulated order status stages
 const orderStages = [
  { stage: 'Processing', icon: <FaShippingFast /> },
  { stage: 'Shipped', icon: <FaTruck /> },
  { stage: 'In Transit', icon: <FaTruck /> },
  { stage: 'Delivered', icon: <FaCheck /> },
 ];

 return (
  <div className='w-full mb-8'>
   <div className="bg-white rounded shadow-lg w-full p-8">
    <h2 className="text-2xl font-semibold mb-4">Order Tracking</h2>
    <div className="mb-4">
     <label htmlFor="orderNumber" className="block text-sm font-medium text-gray-700">
      Order Number
     </label>
     <input
      type="text"
      id="orderNumber"
      className="w-full py-2 px-3 border border-gray-300 rounded-md"
      placeholder="Enter your order number"
     />
    </div>
    <button
     onClick={() => {
      // Simulate fetching order status
      setTimeout(() => {
       setOrderStatus('Processing'); // Replace with your actual order status data
      }, 2000);
     }}
     className="w-full py-2 px-4 bg-blue-600 text-white rounded-md hover:bg-blue-700 transition duration-300"
    >
     Track Order
    </button>
    {orderStatus && <div className="mt-4">
     <h3 className="text-lg font-semibold">Order Status: {orderStatus}</h3>
     <div className="my-4 flex flex-col sm:flex-row gap-4 items-start space-x-4">
      {orderStages.map((stage, index) => (
       <div key={index} className="flex justify-between items-center space-x-2 text-2xl lg:text-3xl">
        {orderStatus === stage.stage ? (
         <div className="text-green-700">{stage.icon}</div>
        ) : (
         <div className="text-gray-400">{stage.icon}</div>
        )}
        <div>{stage.stage}</div>
       </div>
      ))}
     </div>
    </div>}
   </div>
  </div>
 );
};

export default OrderTracking;
