import React from "react";
// import api from "../../services/api";
import { useEffect, useState } from "react";
import Cards from "../../components/Role/Dashboard/Cards";
import ReactPaginate from "react-paginate";


const AdminD = () => {
 const [transactions, setTransactions] = useState([])

 // pagination
 const itemsPerPage = 7;
 const [currentPage, setCurrentPage] = useState(0);

 const handlePageChange = ({ selected }) => {
   setCurrentPage(selected);
 };

 const pageCount = Math.ceil(transactions.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = transactions.slice(startIndex, endIndex);

 useEffect(() => {
  setTransactions([1,2,3,4,5])
  // api.admin.transactions.getAllTransactions().then(res => { console.log(res); setTransactions(res.data?.Transactions) }).catch(res => console.log(res))
 }, [])
 return (
  <main>
   <div className="grid grid-cols sm:grid-cols-2 lg:grid-cols-4 gap-3">
    <Cards cardTitle={"Total Product"} cardValue={"1830"} />
    <Cards cardTitle={"Total Order"} cardValue={"1830"} />
    <Cards cardTitle={"Confirmed Orders"} cardValue={"1830"} />
    <Cards cardTitle={"Delivered Orders"} cardValue={"1830"} />
   </div>
   <section className="my-4">
    <h1 className="roleHeaders">Recent Transactions</h1>
    <div className="p-3 overflow-x-auto">
     <table className="min-w-full divide-y divide-gray-200">
      <thead>
       <tr className="tableHeadRow">
        <th className="tableHead">S/N</th>
        <th className="tableHead">User Id</th>
        <th className="tableHead">Order Number</th>
        {/* <th className="tableHead">Buyer</th>
        <th className="tableHead">Product Price</th>
        <th className="tableHead">Delivery Price</th> */}
        <th className="tableHead">Total Price</th>
        <th className="tableHead">Paystack Ref</th>
        <th className="tableHead">Payment Status</th>
       </tr>
      </thead>
      <tbody>
       {transactions ? (
        paginatedData.map((transaction, idx) => {
         return (
          <tr key={transaction.id}>
           <td>{idx + 1}</td>
           <td>
            {transaction.user_id}
           </td>
           <td>{transaction.order_number}</td>
           {/* <td>{transaction.buyer}</td>
           <td>₦{transaction.product_price}</td>
           <td>₦{transaction.delivery_price}</td> */}
           <td>₦{transaction.total_price}</td>
           <td>{transaction.paystack_ref}</td>
           <td className="px-6 py-4 whitespace-nowrap">
            {transaction.status === "0" ? "Unpaid" : "Paid"}
           </td>
          </tr>
         );
        })
       ) : (
        <div>
         <h2>Loading...</h2>
        </div>
       )}
      </tbody>
     </table>
     <div className="my-8">
      <ReactPaginate
       previousLabel={"⇽"}
       nextLabel={"⇾"}
       breakLabel={"..."}
       pageCount={pageCount}
       marginPagesDisplayed={2}
       pageRangeDisplayed={5}
       onPageChange={handlePageChange}
       containerClassName="paginateContainer"
       pageClassName="cursor-pointer py-1 px-2 rounded-full bg-gray-200 font-bold hover:bg-green-500 hover:text-white"
       previousClassName="nextPrev"
       nextClassName="nextPrev"
       activeClassName="bg-green-500 text-white"
      />
     </div>
    </div>
   </section>
  </main>
 );
};

export default AdminD;
