import React, { useEffect, useState } from "react";
import api from "../../../services/api";
import { toast } from "react-toastify";
import Loading from "../../../components/Loading";
import ReactPaginate from "react-paginate";
import Modal from '../../../components/Cards/Modal'
import Danger from '../../../assets/danger-sign.png'



const AllVendors = () => {
  const [vendors, setVendors] = useState([]);
  const [displayType, setDisplayType] = useState("active");
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);


  const [deactivateId, setDeactivateId] = useState(null);
  const [activateId, setActivateId] = useState(null);



  useEffect(() => {
    setLoading(true);
    if (displayType === "active") {
      api.admin.vendor
        .getActive()
        .then((res) => {
          toast.success("Active Vendors Fetched...");
          setVendors(res.data?.data);
        })
        .catch((res) => console.log(res))
        .finally(() => setLoading(false));
    } else {
      api.admin.vendor
        .getInActive()
        .then((res) => {
          toast.success("Inactive Vendors Fetched...");
          setVendors(res.data?.data);
        })
        .catch((res) => console.log(res))
        .finally(() => setLoading(false));
    }
  }, [displayType]);

  // pagination
  const itemsPerPage = 7;
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };
  const pageCount = Math.ceil(vendors.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = vendors.slice(startIndex, endIndex);

  const openModal = (id) => {
    if(displayType === 'inactive'){
      setActivateId(id);
    }else{
      setDeactivateId(id);
    }
    setIsModalOpen(true);
  };

  const activateVendor = (id) => {
    api.admin.vendor
      .activateVendor(id)
      .then((res) => {
        setIsModalOpen(false)
        toast.success("Vendor activated");
        setVendors((prev) => prev.filter((vendor) => vendor.id !== id));
      })
      .catch((res) => console.log(res));
  };

  const deactivateVendor = (id) => {
    api.admin.vendor
      .deactivateVendor(id)
      .then((res) => {
        setIsModalOpen(false)
        toast.error("Vendor deactivated");
        setVendors((prev) => prev.filter((vendor) => vendor.id !== id));
      })
      .catch((res) => console.log(res));
  };

  return (
    <div>
      <h1 className="roleHeaders">All Vendors</h1>
      <div className="flex gap-4 justify-center items-center my-6">
        <button
          type="button"
          onClick={() => setDisplayType("active")}
          className={displayType === "active" ? "currentVendor" : "vendorBtn"}
        >
          Active Vendors
        </button>
        <button
          type="button"
          onClick={() => setDisplayType("inactive")}
          className={displayType === "inactive" ? "currentVendor" : "vendorBtn"}
        >
          Inactive Vendors
        </button>
      </div>
      <section>
        <h2 className="text-xl my-4 font-bold">
          {displayType === "active" ? "Active Vendors" : "Inactive Vendors"}
        </h2>
        <div className="p-3 overflow-x-auto">
          {loading ? (
            <div className="flex justify-center">
              <Loading className={"w-[40px]"} />
            </div>
          ) : (
            <div>
              <table className="min-w-full divide-y divide-gray-200 border">
                <thead>
                  <tr className="tableHeadRow">
                    <th className="tableHead">S/N</th>
                    <th className="tableHead">Vendor Id</th>
                    <th className="tableHead">Vendor Name</th>
                    <th className="tableHead">Vendor Username</th>
                    <th className="tableHead">Vendor Email</th>
                    <th className="tableHead">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {vendors &&
                    paginatedData.map((vendor, index) => {
                      return (
                        <tr className="py-[10px] tableRow" key={vendor.id}>
                          <td>{index + 1}</td>
                          <td>#{vendor.id}</td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {vendor.name}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {vendor.username}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {vendor.email}
                          </td>
                          <td>
                            {displayType === "inactive" ? (
                              <button
                                type="button"
                                className="actionBtn bg-green-600"
                                onClick={() => openModal(vendor.id)}
                              >
                                Activate
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="actionBtn bg-red-600"
                                onClick={() => openModal(vendor.id)}
                              >
                                Deactivate
                              </button>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              <div className="my-8">
                <ReactPaginate
                  previousLabel={"⇽"}
                  nextLabel={"⇾"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageChange}
                  containerClassName="paginateContainer"
                  pageClassName="cursor-pointer py-1 px-2 rounded-full bg-gray-200 font-bold hover:bg-green-500 hover:text-white"
                  previousClassName="nextPrev"
                  nextClassName="nextPrev"
                  activeClassName="bg-green-500 text-white"
                />
              </div>
            </div>
          )}
        </div>
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <div className='flex flex-col items-center gap-4'>
            <img src={Danger} alt="" className='w-[100px]' />
            <h2 className='text-2xl'>Are you sure you want to delete?</h2>
            <div className='flex  gap-4 items-center'>
              <button type="button" className='bg-green-600 px-4 rounded-md py-1 text-white font-medium' onClick={() => { 
                if(displayType === "inactive"){
                  activateVendor(activateId)
                }else{
                  deactivateVendor(deactivateId)
                }
                }}>Yes</button>
              <button type="button" className='bg-red-600 px-4 rounded-md py-1 text-white font-medium' onClick={() => setIsModalOpen(false)}>No</button>
            </div>
          </div>
        </Modal>
      </section>
    </div>
  );
};

export default AllVendors;
