import React, { useEffect } from "react";
import api from "../../../services/api";

const Claims = () => {
 useEffect(() => {
  api.admin.claims
   .viewClaim()
   .then((res) => console.log(res))
   .catch((res) => console.log(res));
 }, []);
 const CloseClaim = (id) => {
  api.admin.claims
   .closeClaim(id)
   .then((res) => console.log(res))
   .catch((res) => console.log(res));
 };
 return <div>
  <button onClick={CloseClaim}>close</button>
 </div>;
};

export default Claims;
