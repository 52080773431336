import React, { useEffect, useState } from "react";
import CustomTextInput from "../../components/CustomTextInput";
import CustomButton from "../../components/CustomButton";
import { colors } from "../../utils/colors";
// import { Checkbox } from "antd";
import { auth } from "../../services/api/auth";
import { useDispatch } from "react-redux";
import service from "../../services";
import { setUser } from "../../redux/Features/authSlice";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Loading from "../Loading";

const LoginDetails = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  // const [number, setNumber] = useState("");
  const [canSubmit, setCanSubmit] = useState(false);
  const [loading, setIsLoading] = useState(false)
  const dispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    email !== "" && password !== ""
      ? setCanSubmit(true)
      : setCanSubmit(false);
  }, [email, password]);

  function onSubmit(e) {
    e.preventDefault();
    setIsLoading(!loading)
    const data = {
      email: email,
      password: password,
    };
    auth
      .login(data)
      .then((res) => {
        // setUser
        dispatch(setUser(res.data.user));
        // setToken
        service.setTokensLocalStorage({ access: res.data.access_token });
        service.setUserLocalStorage(res.data?.user);

        const role = res.data.user?.role
        toast.success('Login successful ', {
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          // progress: undefined,
          theme: "light",
        });
        if (role === "user") {
          navigate('/myaccount/profile');
        } else {
          navigate(`/${role}/dashboard`)
        }
      })
      .catch((res) => {
        setIsLoading(loading)
        if (res.response?.status === 422) {
          toast.error("Invalid Credentials");
        }
      });
  }

  return (
    <div className="flex flex-col items-start mx-3 gap-4">
      <div>
        <h3 className="text-4xl font-bold">Login</h3>
        <p className="font-medium text-gray-500">
          Don't have an account?{" "}
          <Link to="/register" className="text-green-600">
            Register
          </Link>
        </p>
      </div>
      <div className="flex flex-col gap-6 w-full">
        <CustomTextInput
          fieldName={""}
          placeholder={"Email"}
          value={email}
          setValue={setEmail}
          className={"w-full"}
          height={53}
          borderRadius={7}
          borderColor={colors.green}
        />
        <CustomTextInput
          fieldName={""}
          placeholder={"Password"}
          type={"password"}
          value={password}
          setValue={setPassword}
          height={53}
          borderRadius={7}
          borderColor={colors.green}
        />
        {/* <div className="flex items-center gap-8 mb-4 mt-5">
          <CustomTextInput
            fieldName={""}
            placeholder={"Security code"}
            type={"text"}
            value={number}
            setValue={setNumber}
            height={53}
            borderRadius={7}
            className={"text-xs"}
            borderColor={colors.green}
          />
          <div
            className="w-[120px] h-[53px] flex flex-row justify-center items-center text-xs rounded-md"
            style={{ backgroundColor: colors.green, opacity: 0.5 }}
          >
            1293845
          </div>
        </div> */}
        {/* <Checkbox>I agree to terms & policy</Checkbox> */}

        <div className="mb-1">
          <CustomButton
            onClick={onSubmit}
            text={loading ? <Loading /> : 'Submit'}
            disabled={!canSubmit ? true : false}
            paddingTop={16}
            paddingBottom={16}
            paddingLeft={36}
            paddingRight={36}
            textWeight={400}
            background={colors.green}
            color={colors.white}
            borderRadius={4}
          />
        </div>
        <div>
          <Link to='/forgot-password' className="text-green-600 font-bold italic">Forgot Password?</Link>
        </div>
      </div>

      <div className="text-xs font-semibold" style={{ color: colors.gray4 }}>
        <span style={{ color: colors.gray3 }} className="text-sm pr-1 font-bold">
          Note:
        </span>
        Your personal data will be used to support your experience throughout this
        website to manage acess to your account, and for other purposes described in
        our privacy policy
      </div>
    </div>
  );
};

export default LoginDetails;
