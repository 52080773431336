import { createSlice } from "@reduxjs/toolkit";

const initialState = {
 user: localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
}

export const authSlice = createSlice({
 name: "auth",
 initialState,
 reducers: {
  setUser: (state, action) => {
   state.user = action.payload;
  },
  logout: (state)=>{
   state.user = null;
   localStorage.removeItem('user');
   localStorage.removeItem('tokens');
  }
 },
});

export const { setUser, logout } = authSlice.actions;
export default authSlice.reducer;
