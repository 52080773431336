/* eslint-disable no-unused-vars */
import React from "react";
import { NavLink, Link } from "react-router-dom";
import { AiOutlineDashboard, AiOutlineSetting } from "react-icons/ai";
import { LiaProductHunt } from "react-icons/lia";
import { FaClipboardList } from "react-icons/fa";
import { BiMoneyWithdraw } from "react-icons/bi";
import { RiAuctionLine } from "react-icons/ri";
import { IoIosArrowForward, IoIosArrowDown } from "react-icons/io";
import { useState } from "react";
import { useSelector } from "react-redux";

const SideBar = ({ isOpen,setIsSidebarOpen }) => {
  const [isExpand1, setIsExpand1] = useState(true);
  const [isExpand2, setIsExpand2] = useState(true);
  const [isExpand3, setIsExpand3] = useState(true);
  const [isExpand4, setIsExpand4] = useState(true);
  const [isExpand5, setIsExpand5] = useState(true);

  const { user } = useSelector((store) => store.auth);
  const role = user.role;

  const expandToggle = (toggleFunction) => {
    toggleFunction((prevState) => !prevState);
  };
  const closeSideBar =()=>{
    setIsSidebarOpen(!isOpen)
  }
  return (
    <aside
      className={`sidebar ${isOpen
        ? "w-full min-h-screen shadow-lg md:w-1/4 bg-white p-5 text-black text-lg font-semibold"
        : "hidden"
        }`}
    >
      <ul className="flex flex-col gap-5 justify-stretch">
        <li className="roleLi">
          <AiOutlineDashboard />
          <NavLink to={`/${role}/dashboard`} onClick={closeSideBar}>Dashboard</NavLink>
        </li>
        {role === 'admin' && <div className="border-b-2">
          <li className="roleLi" onClick={() => expandToggle(setIsExpand1)}>
            <LiaProductHunt />
            <p>Manage Brands</p>
            <button
              type="button"
              className="flex flex-end ml-auto transition-all duration-300 ease-linear"
            >
              {isExpand1 ? <IoIosArrowDown /> : <IoIosArrowForward />}
            </button>
          </li>
          {isExpand1 && (
            <ul className="flex flex-col gap-3 mt-2 bg-gray-100 p-3 mb-2">
              <li>
                <Link to={`/${role}/allBrands`} onClick={closeSideBar}>All Brands</Link>
              </li>
              <li>
                <Link to={`/${role}/addBrand`} onClick={closeSideBar}>Add Brand</Link>
              </li>
            </ul>
          )}
        </div>}
        <div className="border-b-2">
          <li className="roleLi" onClick={() => expandToggle(setIsExpand2)}>
            <LiaProductHunt />
            <p>Manage Products</p>
            <button
              type="button"
              className="flex flex-end ml-auto transition-all duration-300 ease-linear"
            >
              {isExpand2 ? <IoIosArrowDown /> : <IoIosArrowForward />}
            </button>
          </li>
          {isExpand2 && (
            <ul className="flex flex-col gap-3 mt-2 bg-gray-100 p-3 mb-2">
              <li>
                <Link to={`/${role}/allProducts`} onClick={closeSideBar}>All Products</Link>
              </li>
              <li>
                <Link to={`/${role}/addProduct`} onClick={closeSideBar}>Add Product</Link>
              </li>
            </ul>
          )}
        </div>
        {role === 'admin' && <> <div className="border-b-2">
          <li className="roleLi" onClick={() => expandToggle(setIsExpand3)}>
            <FaClipboardList />
            <p>Categories</p>
            <button
              type="button"
              className="flex flex-end ml-auto transition-all duration-300 ease-linear"
            >
              {isExpand3 ? <IoIosArrowDown /> : <IoIosArrowForward />}
            </button>
          </li>
          {isExpand3 && (
            <ul className="flex flex-col gap-3 mt-2 bg-gray-100 p-3 mb-2">
              <li>
                <Link to={`/${role}/allCategories`} onClick={closeSideBar}>All Categories</Link>
              </li>
              <li>
                <Link to={`/${role}/addCategory`} onClick={closeSideBar}>Add Category</Link>
              </li>
            </ul>
          )}
        </div>
        <div className="border-b-2">
          <li className="roleLi" onClick={() => expandToggle(setIsExpand4)}>
            <FaClipboardList />
            <p>SubCategories</p>
            <button
              type="button"
              className="flex flex-end ml-auto transition-all duration-300 ease-linear"
            >
              {isExpand4 ? <IoIosArrowDown /> : <IoIosArrowForward />}
            </button>
          </li>
          {isExpand4 && (
            <ul className="flex flex-col gap-3 mt-2 bg-gray-100 p-3 mb-2">
              <li>
                <Link to={`/${role}/allSubCategories`} onClick={closeSideBar}>All SubCategories</Link>
              </li>
              <li>
                <Link to={`/${role}/addSubCategory`} onClick={closeSideBar}>Add SubCategory</Link>
              </li>
            </ul>
          )}
        </div> </>}
        {role === "admin" && (
          <div className="border-b-2">
            <li className="roleLi" onClick={() => expandToggle(setIsExpand5)}>
              <BiMoneyWithdraw />
              <p>Vendors</p>
              <button
                type="button"
                className="flex flex-end ml-auto transition-all duration-300 ease-linear"
              >
                {isExpand5 ? <IoIosArrowDown /> : <IoIosArrowForward />}
              </button>
            </li>
            {isExpand5 && (
              <ul className="flex flex-col gap-3 mt-2 bg-gray-100 p-3 mb-2">
                <li onClick={closeSideBar}>
                  <Link to="admin/all-vendors" >Vendors</Link>
                </li>
              </ul>
            )}
          </div>
        )}
        {role === 'admin' && (
          <>
            <li className="roleLi" onClick={closeSideBar}>
              <RiAuctionLine />
              <NavLink to={`/admin/coupons`}>Coupons</NavLink>
            </li>
            <li className="roleLi" onClick={closeSideBar}>
              <RiAuctionLine />
              <NavLink to={`/admin/sliders`}>Sliders</NavLink>
            </li>
            <li className="roleLi" onClick={closeSideBar}>
              <RiAuctionLine />
              <NavLink to={`/admin/claims`}>Claims</NavLink>
            </li>
          </>
        )}
        <li className="roleLi" onClick={closeSideBar}>
          <RiAuctionLine />
          <NavLink to={`/${role}/auctioned`}>Auctions</NavLink>
        </li>
        {/* <li className="roleLi">
          <AiOutlineSetting />
          <NavLink to="vendor/settings">Orders</NavLink>
        </li> */}
      </ul>
    </aside>
  );
};

export default SideBar;
