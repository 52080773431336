import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../../services/api";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { formatDate } from "../../../components/FormatPrice";

const CreateAuction = () => {
  const [success, setSuccess] = useState(false);
  const [minDate, setMinDate] = useState('');

  const navigate = useNavigate();
  useEffect(() => {
    const currentDate = formatDate(new Date());
    setMinDate(currentDate);
  }, []);

  const { id } = useParams();

  const [auction, setAuction] = useState({
    product_id: id,
    start_date: "",
    end_date: "",
    product_name: "",
    description: "",
    thumbnail: "",
    bidding_price: "",
  });
  console.log(formatDate(auction.start_date))
  console.log(auction)

  const handleChange = (e) => {
    setAuction({ ...auction, [e.target.name]: e.target.value });
  };

  const handlePhoto = (e) => {
    const selectedFile = e.target.files[0]
    setAuction({
      ...auction,
      thumbnail: selectedFile
    })
  }

  const isAuctionValid = () => {
    for (const key in auction) {
      if (auction.hasOwnProperty(key) && !auction[key]) {
        return false; // Found an empty property
      }
    }
    return true; // All properties are non-empty
  };

  const handleCreateAuction = () => {
    const currentDate = formatDate(new Date());
    
    if (auction.start_date  < currentDate || auction.end_date < auction.start_date) {
      alert("Your start date and end date cannot be days before today.");
      return; // Stop further processing
    }
    setSuccess(!success);

    const formData = new FormData();
    formData.append("product_id", auction.product_id);
    formData.append("start_time", formatDate(auction.start_date));
    formData.append("end_time", formatDate(auction.end_date));
    formData.append("auction_price", auction.bidding_price);
    formData.append("product_name", auction.product_name);
    formData.append("description", auction.description);
    formData.append("thumbnail", auction.thumbnail);

    if (isAuctionValid()) {
      api.vendor.auction
        .createAuction(formData)
        .then((res) => {
          console.log(res);
          setSuccess(!success);
          toast.success(res.data.message);
          navigate(`/vendor/auctioned`);
        })
        .catch((res) => {
          console.log(res);
          setSuccess(success);
        });
    } else {
      setSuccess(success);
      toast.error('Kindly fill all fields before submitting')
    }


  };

  return (
    <div>
      <h1 className="roleHeaders">Create Auction</h1>
      <section className="my-8 shadow-xl bg-white mx-auto w-full md:w-[80%] p-8">
        <form className="w-auto flex flex-col gap-4">
          <div className="addDiv">
            <label htmlFor="product_id">Product ID</label>
            <input
              type="text"
              name="product_id"
              id="product_id"
              className="productInput w-full"
              placeholder="Product Id"
              onChange={handleChange}
              value={auction.product_id}
              disabled
            />
          </div>
          <div className="addDiv">
            <label htmlFor="product_name">Product Name</label>
            <input
              type="text"
              name="product_name"
              id="product_name"
              className="productInput w-full"
              placeholder="Your Product name"
              onChange={handleChange}
              value={auction.product_name}
            />
          </div>
          <div className="addDiv">
            <label htmlFor="description">Brief Description</label>
            <textarea name="description" id="description" cols="30" rows="7" className="productInput w-full" onChange={handleChange} value={auction.description} />
          </div>
          <div className="addDiv">
            <label htmlFor="thumbnail">Image</label>
            <input type="file" name="thumbnail" id="thumbnail" onChange={handlePhoto} />
            {
              auction.thumbnail && <img src={URL.createObjectURL(auction.thumbnail)} alt="profile" className="w-[150px] h-[150px] mt-4" />
            }
          </div>
          <div className="addDiv">
            <label htmlFor="bidding_price">Start Price</label>
            <input
              type="text"
              name="bidding_price"
              id="bidding_price"
              className="productInput w-full"
              placeholder="Starting Price"
              onChange={handleChange}
              value={auction.bidding_price}
            />
          </div>
          <div className="addDiv">
            <label htmlFor="start_date">Start Date</label>
            <input type="datetime-local"  name="start_date"
              id="start_date"
              value={formatDate(auction.start_date)}
              onChange={handleChange}
              className="productInput w-full"
              min={minDate}/>
          </div>
          <div className="addDiv">
            <label htmlFor="end_date">End Date</label>
            <input type="datetime-local"  name="end_date"
              id="end_date"
              value={formatDate(auction.end_date)}
              onChange={handleChange}
              className="productInput w-full"
              min={minDate} />
          </div>
        </form>
        <button
          type="button"
          disabled={success}
          className={success ? "disabled mt-4" : "submitBtn mt-4"}
          onClick={handleCreateAuction}
        >
          Create Auction
        </button>
      </section>
    </div>
  );
};

export default CreateAuction;
