import React, { useEffect } from "react";
import cartImage from "../assets/cart.png";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import CartItem from "../components/CartItem";
import Shipping from "../components/Shipping";
import { fetchCart } from "../redux/Features/cartSlice"; // Import the fetchCart thunk

const Cart = () => {
  const { cartItems, cartQty, cartTotal, status } = useSelector(
    (store) => store.cart
  );
  const { user } = useSelector((store) => store.auth);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    // Dispatch fetchCart when the component mounts
    dispatch(fetchCart());
  }, [dispatch]);

  return (
    <main>
      {status === "loading" ? (
        <div>Loading...</div> // Show a loading state while fetching cart data
      ) : cartItems.length === 0 ? (
        <section className="flex flex-col items-center justify-center px-[30px] gap-4 mb-10">
          <img src={cartImage} alt="cart" className="h-3/4" />
          <div className="text-center flex flex-col gap-4">
            <h3 className="text-3xl font-extrabold">
              Your Cart Is Currently Empty!
            </h3>
            <div className="flex flex-col gap-1 text-gray-600 text-sm md:text-base lg:text-xl font-medium">
              <p>
                Before proceeding to checkout you must add some products to your
                shopping cart.
              </p>
              <p>
                You will find a lot of interesting products on our "Shop" page.
              </p>
            </div>
            <button
              type="button"
              onClick={() => navigate("/products")}
              className="bg-blue-700 return"
            >
              Return To Shop
            </button>
          </div>
        </section>
      ) : (
        <section className="mx-5">
          <section className="my-4">
            <h3 className="text-[45px] text-gray-800 font-bold">Your Cart</h3>
            <p className="text-base">
              There are <span className="text-green-500">{cartQty}</span>{" "}
              products in your cart
            </p>
          </section>
          <section>
            {/* Table Head */}
            <article className="carttablehead">
              {/* div1 */}
              <div className="">
                <h3 className="cartheader">Products</h3>
              </div>
              {/* div2 */}
              <div>
                <h3 className="cartheader">Price</h3>
              </div>
              {/* div3 */}
              <div className="">
                <p className="cartheader">Quantity</p>
              </div>
              {/* div4 */}
              <div>
                <h3 className="cartheader">SubTotal</h3>
              </div>
            </article>
            {/* cart Items */}
            <div>
              {cartItems?.map((cartItem) => {
                return(
                <CartItem
                  id={cartItem.id}
                  rowId={cartItem.rowId}
                  key={cartItem.rowId}
                  title={cartItem.name}
                  qty={cartItem.qty}
                  price={cartItem.price}
                  thumbnail={cartItem.options?.image}
                  subTotal={cartItem.subtotal}
                />
              )})}
            </div>

            <button
              type="submit"
              onClick={() => navigate("/products")}
              className="returntoshop"
            >
              Return To Shop
            </button>
          </section>
          {/* checkout section */}
          <section className="proceedtocheckout">
            <div className="cartcheckout">
              <h3 className="font-bold text-xl mb-3">Cart Total</h3>
              <Shipping totalPrice={cartTotal} />
              <div>
                {!user ? (
                  <div>
                    <p className="my-8 font-medium">
                      You can't proceed to checkout without signing in or
                      logging in.
                    </p>
                    <div className="flex gap-10 text-lg text-red-800">
                      <Link
                        to="/login"
                        className="border-2 py-2 px-6 rounded-md"
                      >
                        Login
                      </Link>
                      <Link
                        to="/register"
                        className="border-2 py-2 px-6 rounded-md"
                      >
                        SignUp
                      </Link>
                    </div>
                  </div>
                ) : (
                  <button
                    type="submit"
                    className="checkoutbutton"
                    onClick={() => navigate("/checkout")}
                  >
                    Proceed to checkout
                  </button>
                )}
              </div>
            </div>
          </section>
        </section>
      )}
    </main>
  );
};

export default Cart;
