import React, { useEffect, useState } from "react";
import api from "../services/api";

const ReportedIssues = () => {
  const [reports, setReports] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    api.customer
      .getClaim()
      .then((res) => {
        // const reports = Array(res.data.reports);
        // const reportArray = Array(reports[0]);
        // const flatArray = (reportArray.flatMap((report) => report));
        setReports([]);
      })
      .catch((res) => console.log(res));
  }, []);

  const handleClick = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div>
      <h1>Reported Issues</h1>
      <section>
        {reports.length < 1 ? (
          <div>No raised reports yet</div>
        ) : (
          <div>
            {reports.map((report, index) => {
              return (
                <div
                  key={report.id}
                  className="mb-4 border border-gray-300 rounded"
                >
                  <button
                    className="w-full p-4 text-left font-medium text-gray-700 bg-gray-100 hover:bg-gray-200 focus:outline-none"
                    onClick={() => handleClick(index)}
                  >
                    <div>
                      ID: {report.id} - {report.name}
                    </div>
                  </button>
                  <div
                    className={`accordion-content p-4 bg-white ${
                      activeIndex === index ? "block" : "hidden"
                    }`}
                  >
                    <p>
                      <strong>Status:</strong> {report.status}
                    </p>
                    <p>
                      <strong>Reference:</strong> {report.reference}
                    </p>
                    <p>
                      <strong>Email:</strong> {report.email}
                    </p>
                    <p>
                      <strong>Description:</strong> {report.description}
                    </p>
                    <p>
                      <strong>Created At:</strong>{" "}
                      {new Date(report.created_at).toLocaleString()}
                    </p>
                    <p>
                      <strong>Updated At:</strong>{" "}
                      {new Date(report.updated_at).toLocaleString()}
                    </p>
                    <p>
                      <strong>Images:</strong>
                    </p>
                    <img
                      src={report.img1}
                      alt=""
                      className="w-32 h-32 object-cover mb-2"
                    />
                    <img
                      src={report.img2}
                      alt=""
                      className="w-32 h-32 object-cover"
                    />
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </section>
    </div>
  );
};

export default ReportedIssues;
