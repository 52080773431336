import React, { useState } from "react";
import api from "../../../services/api";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const AddCategory = () => {
 const [category, setCategory] = useState({
  categoryName: "",
  categoryLogo: null,
 });
 const [success, setSuccess] = useState(false);
 const navigate = useNavigate();
 const { id } = useParams();

 const { user } = useSelector((store) => store.auth);
 const role = user.role;

 const handleChange = (e) => {
  setCategory((prev) => ({
   ...prev,
   categoryName: e.target.value,
  }));
 };

 const handleLogoChange = (e) => {
  const selectedFile = e.target.files[0];
  setCategory((prev) => ({
   ...prev,
   categoryLogo: selectedFile,
  }));
 };

 useEffect(() => {
  if (id) {
   if (role === "vendor") {
    api.vendor.category
     .getCategory(id)
     .then((res) => {
      console.log(res);
      if (res.status === 200) {
       const existingCategory = res.data?.Category;
       setCategory({
        categoryName: existingCategory.category_name,
        categoryLogo: existingCategory.category_image,
       });
      }
     })
     .catch((err) => console.error(err));
   } else {
    api.admin.category
     .getCategory(id)
     .then((res) => {
      console.log(res);
      if (res.status === 200) {
       const existingCategory = res.data?.Category;
       setCategory({
        categoryName: existingCategory.category_name,
        categoryLogo: existingCategory.category_image,
       });
      }
     })
     .catch((err) => console.error(err));
   }
  }
 }, [id, role]);

 const CreateCategory = (e) => {
  e.preventDefault();
  setSuccess(!success);

  const formData = new FormData();
  formData.append("category_name", category.categoryName);
  formData.append("category_image", category.categoryLogo);

  if (id) {
   if (role === "vendor") {
    api.vendor.category
     .updateCategory(id, formData)
     .then((res) => {
      if (res.status === 200) {
       toast.success(res.data.message, { autoClose: 2000 });
       setSuccess(!success);
       setTimeout(() => {
        setCategory({});
        navigate(`/${role}/allCategories`);
       }, 2000);
      }
     })
     .catch((res) => console.log(res));
   } else {
    api.admin.category
     .updateCategory(id, formData)
     .then((res) => {
      if (res.status === 200) {
       toast.success(res.data.message, { autoClose: 2000 });
       setSuccess(!success);
       setTimeout(() => {
        setCategory({});
        navigate(`/${role}/allCategories`);
       }, 2000);
      }
     })
     .catch((res) => console.log(res));
   }
  } else {
   if (role === "vendor") {
    api.vendor.category
     .createCategory(formData)
     .then((res) => {
      console.log(res);

      toast.success(res.data.message, { autoClose: 2000 });
      setSuccess(!success);
      setTimeout(() => {
       setCategory({});
       navigate(`/${role}/allCategories`);
      }, 2000);
     })
     .catch((res) => console.log(res));
   } else {
    api.admin.category
     .createCategory(formData)
     .then((res) => {
      toast.success(res.data.message, { autoClose: 2000 });
      setSuccess(!success);
      setTimeout(() => {
       setCategory({});
       navigate(`/${role}/allCategories`);
      }, 2000);
     })
     .catch((res) => console.log(res));
   }
  }
 };
 return (
  <main>
   <section className="topSection">
    <h2 className="roleHeaders">{id ? "Update Category" : "Add Category"}</h2>
   </section>
   <section className="bg-white px-4 py-8">
    <form className="formContainer">
     <div className="addDiv">
      <label htmlFor="categoryName">Category Name</label>
      <input
       type="text"
       name="categoryName"
       id="categoryName"
       placeholder="Enter Category Name Here"
       value={category.categoryName}
       onChange={handleChange}
      />
     </div>
     <div className="addDiv">
      <label htmlFor="categoryLogo">Category Logo</label>
      <input
       type="file"
       name=""
       id=""
       accept=".jpg, .jpeg, .png, .gif"
       onChange={handleLogoChange}
      />
     </div>
     <div>
      {category.categoryLogo && (
       <img
        src={
         category.categoryLogo instanceof File
          ? URL.createObjectURL(category.categoryLogo)
          : category.categoryLogo
          ? "https://ebn.ng/public/upload/category/" + category.categoryLogo
          : "https://default-remote-image-url.com" // Provide a default URL here
        }
        alt="category Logo"
        width="100"
        height="100"
       />
      )}
     </div>
     <button
      disabled={success}
      type="button"
      className={success ? "disabled" : "submitBtn"}
      onClick={CreateCategory}
     >
      {id ? "Update Category" : "Create Category"}
     </button>
    </form>
   </section>
  </main>
 );
};

export default AddCategory;
