import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import api from "../../../services/api";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const AddSubCategory = () => {
 const [subCategory, setSubCategory] = useState({
  categoryName: "",
  subCategoryName: "",
 });
 const [categories, setCategories] = useState([]);
 const [success, setSuccess] = useState(false);
 const { id } = useParams();

 const { user } = useSelector((store) => store.auth);
 const role = user.role;
 const navigate = useNavigate();

 const handleChange = (e) => {
  setSubCategory((prev) => ({
   ...prev,
   subCategoryName: e.target.value,
  }));
 };

 const handleCategoryChange = (e) => {
  setSubCategory((prev) => ({
   ...prev,
   categoryName: e.target.value,
  }));
 };

 useEffect(() => {
  if (role === "vendor") {
   api.vendor.category
    .getCategories()
    .then((res) => {
     setCategories(res.data?.categories);
    })
    .catch((res) => console.log(res));
  } else {
   api.admin.category
    .getCategories()
    .then((res) => {
     setCategories(res.data?.categories);
    })
    .catch((res) => console.log(res));
  }
  // edit mode
  if (id) {
   if (role === "vendor") {
    api.vendor.subcategory
     .getSubcategory(id)
     .then((res) => {
      console.log(res.data);
     })
     .catch((res) => console.log(res));
   } else {
    api.admin.subcategory
     .getSubcategory(id)
     .then((res) => {
      console.log(res.data);
     })
     .catch((res) => console.log(res));
   }
  }
 }, [id, role]);

 const CreateCategory = (e) => {
  e.preventDefault();
  setSuccess(!success);

  const formData = new FormData();
  formData.append("category_id", subCategory.categoryName);
  formData.append("subcategory_name", subCategory.subCategoryName);

  if (role === "vendor") {
   api.vendor.subcategory
    .createSubcategory(formData)
    .then((res) => {
     console.log(res);
     toast.success(res.data.message, { autoClose: 2000 });
     setSuccess(!success);
     setTimeout(() => {
      setSubCategory({});
      navigate(`/${role}/allSubCategories`);
     }, 2000);
    })
    .catch((res) => {
     console.log(res);
     toast.error(res.data.message, { autoClose: 1000 });
    });
  } else {
   api.admin.subcategory
    .createSubcategory(formData)
    .then((res) => {
     toast.success(res.data.message, { autoClose: 2000 });
     setSuccess(!success);
     setTimeout(() => {
      setSubCategory({});
      navigate(`/${role}/allSubCategories`);
     }, 2000);
    })
    .catch((res) => {
     console.log(res);
     toast.error(res.data.message, { autoClose: 1000 });
    });
  }
 };

 return (
  <main>
   <section className="topSection">
    <h2 className="roleHeaders">
     {id ? "Update SubCategory" : "Add SubCategory"}
    </h2>
   </section>
   <section className="bg-white px-4 py-8">
    <form className="formContainer">
     <div className="addDiv">
      <label htmlFor="categoryName">Category Name</label>
      <select
       name=""
       id=""
       className="productInput w-full"
       onChange={handleCategoryChange}
       value={subCategory.categoryName}
      >
       <option value="">select category</option>
       {categories &&
        categories.map((category) => {
         return (
          <option value={category.id} key={category.category_slug}>
           {category.category_name}
          </option>
         );
        })}
      </select>
     </div>
     <div className="addDiv">
      <label htmlFor="subCategory">SubCategory Name</label>
      <input
       type="text"
       name="subCategory"
       id="subCategory"
       className="productInput w-full"
       placeholder="Subcategory Name"
       onChange={handleChange}
       value={subCategory.subCategoryName}
      />
     </div>
     <button
      disabled={success}
      type="button"
      className={success ? "disabled" : "submitBtn"}
      onClick={CreateCategory}
     >
      {id ? "Update Subcategory" : "Create Subcategory"}
     </button>
    </form>
   </section>
  </main>
 );
};

export default AddSubCategory;
