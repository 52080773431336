import React, { useState } from "react";
import api from "../../../services/api";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useEffect } from "react";

const AddBrand = () => {
  const { user } = useSelector((store) => store.auth);
  const role = user.role;
  const { id } = useParams();

  const [brand, setBrand] = useState({
    brandName: "",
    brandLogo: null,
  });

  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setBrand((prev) => ({
      ...prev,
      brandName: e.target.value,
    }));
  };

  const handleLogoChange = (e) => {
    const selectedFile = e.target.files[0];
    setBrand((prev) => ({
      ...prev,
      brandLogo: selectedFile,
    }));
  };

  useEffect(() => {
    if (id) {
      if (role === "vendor") {
        api.vendor.brand
          .getBrand(id)
          .then((res) => {
            if (res.status === 200) {
              const existingBrand = res.data?.Brand;
              console.log(existingBrand)
              setBrand({
                brandName: existingBrand.brand_name,
                brandLogo: existingBrand.brand_image,
              });
            }
          })
          .catch((err) => console.error(err));
      } else {
        api.admin.brand
          .getBrand(id)
          .then((res) => {
            console.log(res)
            if (res.status === 200) {
              const existingBrand = res.data?.Brand;
              setBrand({
                brandName: existingBrand.brand_name,
                brandLogo: existingBrand.brand_image,
              });
            }
          })
          .catch((err) => console.error(err));
      }
    }
  }, [id, role]);

  const isBrandValid = () => {
    return brand.brandName.trim() !== '' && brand.brandLogo !== null;
  };

  const CreateBrand = (e) => {
    e.preventDefault();
    setSuccess(!success);

    const formData = new FormData();
    formData.append("brand_name", brand.brandName);
    formData.append("brand_image", brand.brandLogo);

    if (!isBrandValid()) {
    setSuccess(success);
      toast.error('Kindly fill the fields')
    } else {
      if (id) {
        setSuccess(!success);
        if (role === "vendor") {
          api.vendor.brand
            .updateBrand(id, formData)
            .then((res) => {
              if (res.status === 200) {
                toast.success(res.data.message, { autoClose: 2000 });
                setSuccess(!success);
                setTimeout(() => {
                  navigate(`/${role}/allBrands`);
                  setSuccess(success);
                }, 2000);
              }
            })
            .catch((err) => console.error(err));
        } else {
          api.admin.brand
            .updateBrand(id, formData)
            .then((res) => {
              console.log(res)
              if (res.status === 200) {
                toast.success(res.data.message, { autoClose: 2000 });
                setSuccess(!success);
                setTimeout(() => {
                  navigate(`/${role}/allBrands`);
                  setSuccess(success);
                }, 2000);
              }
            })
            .catch((err) => console.error(err));
        }
      } else {
        if (role === "vendor") {
          api.vendor.brand
            .createBrand(formData)
            .then((res) => {
              if (res.status === 200) {
                toast.success(res.data.message, { autoClose: 2000 });
                setSuccess(!success);
                setTimeout(() => {
                  setBrand({});
                  navigate(`/${role}/allBrands`);
                }, 2000);
              }
            })
            .catch((err) => console.error(err));
        } else {
          api.admin.brand
            .createBrand(formData)
            .then((res) => {
              if (res.status === 200) {
                toast.success(res.data.message, { autoClose: 2000 });
                setSuccess(!success);
                setTimeout(() => {
                  setBrand({});
                  navigate(`/${role}/allBrands`);
                }, 2000);
              }
            })
            .catch((err) => console.error(err));
        }
      }
    }



  };

  return (
    <main>
      <section className="topSection">
        <h2 className="roleHeaders">{id ? "Update Brand" : "Add Brand"}</h2>
      </section>
      <section className="bg-white px-4 py-8">
        <form className="formContainer">
          <div className="addDiv">
            <label htmlFor="brandName">Brand Name</label>
            <input
              type="text"
              name="brandName"
              id="brandName"
              placeholder="Enter Brand Name Here"
              value={brand.brandName}
              onChange={handleChange}
            />
          </div>
          <div className="addDiv">
            <label htmlFor="brandLogo">Brand Logo</label>
            <input
              type="file"
              name=""
              id=""
              accept=".jpg, .jpeg, .png, .gif"
              onChange={handleLogoChange}
            />
          </div>
          <div>
            {brand.brandLogo && (
              <img
                src={
                  brand.brandLogo instanceof File
                    ? URL.createObjectURL(brand.brandLogo)
                    : brand.brandLogo
                      ? "https://ebn.ng/public/upload/brand/" + brand.brandLogo
                      : "https://default-remote-image-url.com"
                }
                alt="Brand Logo"
                width="100"
                height="100"
              />
            )}
          </div>
          <button
            disabled={success}
            type="button"
            className={success ? "disabled" : "submitBtn"}
            onClick={CreateBrand}
          >
            {id ? "Update Brand" : "Create Brand"}
          </button>
        </form>
      </section>
    </main>
  );
};

export default AddBrand;
