import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ComposedChart,
  Bar,
} from "recharts";

// Dummy image imports (replace with actual paths)
import TotalOrdersImg from "../../assets/images/totalOrders.png";
import TotalSalesImg from "../../assets/images/totalSales.png";
import RevenueImg from "../../assets/images/totalRevenue.png";

const salesData = [
  { name: "Jan", sales: 4000, year: 2024 },
  { name: "Feb", sales: 3000, year: 2024 },
  { name: "Mar", sales: 5000, year: 2024 },
  { name: "Apr", sales: 4000, year: 2024 },
  { name: "May", sales: 6000, year: 2024 },
  { name: "Jun", sales: 7000, year: 2024 },
  // Add more years and months as needed
];

const yearlyData = [
  { year: 2022, totalSales: 50000 },
  { year: 2023, totalSales: 60000 },
  { year: 2024, totalSales: 70000 },
];

const recentOrders = [
  { id: 'OD001', name: 'John Doe', date: '2024-08-01', total: '$120.00' },
  { id: 'OD002', name: 'Jane Smith', date: '2024-08-02', total: '$80.00' },
  { id: 'OD003', name: 'Michael Brown', date: '2024-08-03', total: '$150.00' },
];

const topProducts = [
  {
    id: "P001",
    name: "Product A",
    price: "$10.00",
    totalSold: 200,
    totalMoney: "$2,000.00",
    image: TotalSalesImg,
  },
  {
    id: "P002",
    name: "Product B",
    price: "$15.00",
    totalSold: 150,
    totalMoney: "$2,250.00",
    image: TotalSalesImg,
  },
  {
    id: "P003",
    name: "Product C",
    price: "$20.00",
    totalSold: 100,
    totalMoney: "$2,000.00",
    image: TotalSalesImg,
  },
];

const metrics = [
  { title: "Total Orders", value: "1,234", img: TotalOrdersImg },
  { title: "Total Sales", value: "$12,345.67", img: TotalSalesImg },
  { title: "Revenue", value: "$8,910.11", img: RevenueImg },
];

export default function DashboardContent() {
  const [selectedYear, setSelectedYear] = useState(2024);

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const filteredSalesData = salesData.filter(
    (data) => data.year === selectedYear
  );

  return (
    <Box className="p-6 bg-gray-100">
      {/* Metrics Section */}
      <Grid container spacing={4} className="mb-6">
        {metrics.map((metric, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card className="shadow-lg bg-white flex items-center">
              <CardContent className="flex flex-col flex-1">
                <Typography variant="h6" sx={{fontSize:'13px'}} className="text-gray-700">
                  {metric.title}
                </Typography>
                <Typography
                  variant="h4"
                  sx={{fontSize:'22px'}}
                  className="text-gray-900 font-semibold mt-2"
                >
                  {metric.value}
                </Typography>
              </CardContent>
              <Box className="p-4">
                <img
                  src={metric.img}
                  alt={metric.title}
                  className="w-16 h-16 object-contain"
                />
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Grid container spacing={4}>
        {/* Sales Overview */}
        <Grid item xs={12}>
          <Card className="shadow-lg">
            <CardContent>
              <Typography variant="h6" className="mb-4">
                Sales Overview
              </Typography>

              {/* Date Range and Year Selector */}
              <Grid container spacing={2} className="mb-4 items-center">
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth>
                    <InputLabel id="select-year-label">Select Year</InputLabel>
                    <Select
                      labelId="select-year-label"
                      value={selectedYear}
                      onChange={handleYearChange}
                      label="Select Year"
                    >
                      {yearlyData.map((year) => (
                        <MenuItem key={year.year} value={year.year}>
                          {year.year}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>

              {/* Main Sales Chart */}
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={filteredSalesData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Line
                    type="monotone"
                    dataKey="sales"
                    stroke="#8884d8"
                    activeDot={{ r: 8 }}
                  />
                </LineChart>
              </ResponsiveContainer>

              {/* Yearly Sales Breakdown */}
              <Typography variant="h6" className="mt-6 mb-4">
                Yearly Sales Breakdown
              </Typography>
              <ResponsiveContainer width="100%" height={300}>
                <ComposedChart data={yearlyData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="year" />
                  <YAxis />
                  <Tooltip />
                  <Bar dataKey="totalSales" fill="#82ca9d" />
                  <Line type="monotone" dataKey="totalSales" stroke="#8884d8" />
                </ComposedChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>

        {/* Recent Orders */}
        <Grid item xs={12} md={4}>
          <Card className="shadow-lg">
            <CardContent>
              <Typography variant="h6" className="mb-4">
                Recent Orders
              </Typography>
              <Box>
                {recentOrders.map((order) => (
                  <Box
                    key={order.id}
                    className="flex items-center justify-between mb-4 p-4 border-b border-gray-300 rounded-xl" sx={{backgroundColor:'#E3E3E3'}}>
                    {/* First Div: Image and Name with Time */}
                    <Box className="flex items-center space-x-2" >
                      <img
                        src={TotalOrdersImg}
                        alt="Order"
                        className="w-[25px] h-[25px] object-cover"
                      />
                      <Box>
                        <Typography variant="body1" className="font-semibold">
                          {order.name}
                        </Typography>
                        <Typography variant="body2" className="text-gray-500">
                          {order.date}
                        </Typography>
                      </Box>
                    </Box>
                    {/* Second Div: Price */}
                    <Box>
                      <Typography variant="body1" className="text-gray-900">
                        {order.total}
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            </CardContent>
          </Card>
        </Grid>

        {/* Top Products */}
        <Grid item xs={12} md={8}>
          <Card className="shadow-lg">
            <CardContent sx={{ }}>
              <Typography variant="h6" className="mb-4">
                Top Products
              </Typography>
              <TableContainer
                component={Paper}
                sx={{
                  border: "none",
                  backgroundColor: "transparent",
                  color: "whitesmoke",
                }}
              >
                <Table
                  aria-label="top products table"
                  sx={{
                    borderCollapse: "collapse",
                    backgroundColor: "transparent",
                  }}
                >
                  <TableHead>
                    <TableRow sx={{ backgroundColor: "transparent" }}>
                      <TableCell
                        align="left"
                        sx={{ border: "none", color: "#555151" }}
                      >
                        Product Details
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ border: "none", color: "#555151" }}
                      >
                        Price
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ border: "none", color: "#555151" }}
                      >
                        Total Sold
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ border: "none", color: "#555151" }}
                      >
                        Total Money
                      </TableCell>
                      <TableCell
                        align="left"
                        sx={{ border: "none", color: "#555151" }}
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {topProducts.map((product, index) => (
                      <TableRow
                        key={product.id}
                        sx={{
                          color: "#555151",
                          backgroundColor: "#E3E3E3",
                          mb: 2,
                          borderRadius: "8px",
                          marginBottom: "16px",
                        }}
                      >
                        <TableCell
                          align="left"
                          sx={{
                            border: "none",
                            color: "#555151",
                          }}
                        >
                          <Box className="flex items-center justify-start space-x-2">
                            <img
                              src={product.image}
                              alt={product.name}
                              className="w-8 h-8 object-cover"
                            />
                            <Typography variant="body1">
                              {product.name}
                            </Typography>
                          </Box>
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            border: "none",
                            color: "#555151",
                          }}
                        >
                          {product.price}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            border: "none",
                            color: "#555151",
                          }}
                        >
                          {product.totalSold}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            border: "none",
                            color: "#555151",
                          }}
                        >
                          {product.totalMoney}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            border: "none",
                            color: "#555151",
                          }}
                        >
                          ...
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}
