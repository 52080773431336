/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import AuctionCard from "../components/Cards/AuctionCard";
import { TiArrowUnsorted } from "react-icons/ti";
import { GrClose } from "react-icons/gr";
import { IoIosArrowDown } from "react-icons/io";
import ReactPaginate from "react-paginate";
import api from "../services/api";
import States from "../states";
import Loading from "../components/Loading";

const Auctions = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [auctions, setAuctions] = useState([]);
  const [categories, setCategories] = useState([]);
  const [bidIs, setBidIs] = useState("open");
  const [filterVisibility, setFilterVisibility] = useState({
    state: false,
    categories: false,
    seller: true,
  });
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    setIsFetching(true);
    api.customer.bidding
      .getAllAuctions()
      .then((res) => {
        setAuctions(res.data?.auctions);
      })
      .catch((res) => console.log(res))
      .finally(() => setIsFetching(false));
    api.customer.category.getCategories().then((res) => {
      setCategories(res.data?.categories);
    });
  }, []);

  const toggleFilter = (filterName) => {
    setFilterVisibility((prevVisibility) => ({
      ...prevVisibility,
      [filterName]: !prevVisibility[filterName],
    }));
  };
  const [filterOptions, setFilterOptions] = useState({
    state: [],
    categories: [],
    seller: [],
  });

  function resetFilter() {
    setFilterOptions(filterOptions);
  }

  function openFilterModal() {
    setIsOpen(!isOpen);
  }

  function handleFilter() {
    const filteredAuctions = auctions.filter((auction) => {
      // Check if the auction matches the selected state(s)
      if (
        filterOptions.state.length > 0 &&
        !filterOptions.state.includes(auction.state)
      ) {
        return false;
      }

      if (
        filterOptions.categories.length > 0 &&
        !filterOptions.categories.includes(auction.category)
      ) {
        return false;
      }

      if (
        filterOptions.seller.length > 0 &&
        !filterOptions.seller.includes(auction.seller)
      ) {
        return false;
      }

      return true;
    });

    console.log("Filtered Auctions:", filteredAuctions);
    // Update state with the filtered auctions
    setAuctions(filteredAuctions);
  }

  function viewResults() {
    handleFilter();
    setIsOpen(false);
  }

  // pagination
  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const filteredAuctions = auctions.filter(
    (auction) =>
      (bidIs === "open" && auction.status === "open") ||
      (bidIs === "close" && auction.status === "close")
  );

  const pageCount = Math.ceil(filteredAuctions?.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = filteredAuctions?.slice(startIndex, endIndex);

  return (
    <main className="auctionPage">
      <div id="auctionPage_mobileFilter">
        <button type="button" onClick={openFilterModal}>
          <TiArrowUnsorted />
          <span>Sort & Filter</span>
        </button>
      </div>
      <aside id="auctionPage_desktopFilter">
        {/* state filter */}
        <article>
          <label htmlFor="state">State</label>
          <select
            name="state"
            id="state"
            value={filterOptions.state}
            onChange={(e) => setFilterOptions({ state: e.target.value })}
          >
            <option value="">Select State</option>
            {States.map((state) => {
              return (
                <option key={state.capital} value={state.name}>
                  {state.name}
                </option>
              );
            })}
          </select>
        </article>
        {/* Categories filter */}
        <article>
          <label htmlFor="categories">Categories</label>
          <select name="categories" id="categories">
            <option value="">Select Category</option>
            {categories.map((cat) => {
              return (
                <option key={cat.category_name} value={cat.category_name}>
                  {cat.category_name}
                </option>
              );
            })}
          </select>
        </article>
        {/* Seller filter */}
        <article>
          <label htmlFor="seller">Seller</label>
          <select name="seller" id="seller">
            <option value="">Select Seller</option>
          </select>
        </article>
        <div className="buttons">
          <button type="button" onClick={viewResults}>
            View Results
          </button>
          <button type="button" onClick={resetFilter}>
            Reset
          </button>
        </div>
      </aside>
      {isFetching ? (
        <div className="flex justify-center items-center w-full">
          <Loading className={"w-[40px]"} />
        </div>
      ) : (
        <section
          id="auctionPage_auctions"
          className="flex flex-col justify-center items-center w-full h-full"
        >
          <div className="flex gap-4 justify-center my-6 w-full ">
            <button
              type="button"
              onClick={() => setBidIs("open")}
              className={bidIs === "open" ? "currentVendor" : "vendorBtn"}
            >
              Active Bids
            </button>
            <button
              type="button"
              onClick={() => setBidIs("close")}
              className={bidIs === "close" ? "currentVendor" : "vendorBtn"}
            >
              Ended Bids
            </button>
          </div>
          <div className="w-[95%] mx-auto">
            {paginatedData
              ?.filter(
                (auction) =>
                  (bidIs === "open" && auction.status === "open") ||
                  (bidIs === "close" && auction.status === "close")
              )
              ?.map((x, y) => {
                return (
                  <AuctionCard
                    productCurrentBid={x.auction_price}
                    productDetail={x.description}
                    thumbnail={
                      "https://ebn.itl.ng/public/upload/auction/thumbnail/" +
                      x.thumbnail
                    }
                    productName={x.product_name}
                    id={x.id}
                    key={y}
                    productStatus={x.status}
                  />
                );
              })}
            {bidIs === "open" && paginatedData?.length === 0 && (
              <div>NO ONGOING BIDS</div>
            )}
            {bidIs === "close" && paginatedData?.length === 0 && (
              <div>NO ENDED BIDS</div>
            )}
          </div>
          {(
            <div className="my-8">
              <ReactPaginate
                previousLabel={"⇽"}
                nextLabel={"⇾"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName="paginateContainer"
                pageClassName="cursor-pointer py-1 px-2 rounded-full bg-gray-200 font-bold hover:bg-green-500 hover:text-white"
                previousClassName="nextPrev"
                nextClassName="nextPrev"
                activeClassName="bg-green-500 text-white"
              />
            </div>
          )}
        </section>
      )}
      {isOpen && (
        <section id="mobile_filter">
          <div className="top">
            <h2>Sort & Filter</h2>
            <button type="button" onClick={() => setIsOpen(false)}>
              <GrClose />
            </button>
          </div>
          <div className="bottom">
            {/* state filter */}
            <article>
              <label htmlFor="state" onClick={() => toggleFilter("state")}>
                <span>State</span> <IoIosArrowDown />
              </label>
              {filterVisibility.state && (
                <select
                  name="state"
                  id="state"
                  value={filterOptions.state}
                  onChange={(e) => setFilterOptions({ state: e.target.value })}
                >
                  <option value="">Select State</option>
                  {States.map((state) => {
                    return (
                      <option key={state.capital} value={state.name}>
                        {state.name}
                      </option>
                    );
                  })}
                </select>
              )}
            </article>
            {/* Categories filter */}
            <article>
              <label
                htmlFor="categories"
                onClick={() => toggleFilter("categories")}
              >
                <span>Categories</span> <IoIosArrowDown />
              </label>
              {filterVisibility.categories && (
                <select name="categories" id="categories">
                  <option value="">Select Category</option>
                </select>
              )}
            </article>
            {/* Seller filter */}
            <article>
              <label htmlFor="seller" onClick={() => toggleFilter("seller")}>
                <span>Seller</span> <IoIosArrowDown />
              </label>
              {filterVisibility.seller && (
                <select name="seller" id="seller">
                  <option value="">Select Seller</option>
                </select>
              )}
            </article>
          </div>
          <div className="buttons">
            <button type="button" onClick={viewResults}>
              View Results
            </button>
            <button type="button" onClick={resetFilter}>
              Reset
            </button>
          </div>
        </section>
      )}
    </main>
  );
};

export default Auctions;
