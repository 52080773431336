/* eslint-disable no-unused-vars */
import ReactStars from "react-stars";
import { LuEye } from "react-icons/lu";
import { MdOutlineShare } from "react-icons/md";
import { CiHeart } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import { useState, useEffect } from "react";
import {
  addToWishlist,
  removeFromWishlist,
} from "../../redux/Features/wishlistSlice";
import { addToCart, removeItem } from "../../redux/Features/cartSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { formatPrice } from "../FormatPrice";
import { toast } from "react-toastify";
import { AiFillHeart } from "react-icons/ai";
import { RiDeleteBinLine } from "react-icons/ri";
import api from "../../services/api";
import { CircularProgress } from "@mui/material";

const NewProductCard = ({
  onView,
  image,
  price,
  product_name,
  rating,
  id,
  tag,
  discount,
  slug,
  createdBy,
  ...rest
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [like, setLike] = useState(false);
  const [inCart, setInCart] = useState(false);
  const { pathname } = useLocation();
  const { wishListItems } = useSelector((store) => store.wishlist);
  const { cartItems } = useSelector((store) => store.cart);
  const [adding, setAdding] = useState(false);

  const [details, setDetails] = useState({
    size: "",
    quantity: 1,
    color: "",
  });

  const handleDetails = (e) => {
    const { name, value } = e.target;
    setDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Ensure `price` and `discount` are defined and valid
  const isDiscountValid = discount != null && discount > 0;
  const finalPrice = isDiscountValid ? discount : price;
  const discountPercentage = isDiscountValid && price > 0 ? ((price - discount) / price) * 100 : 0;


  useEffect(() => {
    const isItemInCart = cartItems.find((item) => item.id === id);
    const isItemInWishlist = wishListItems.find((item) => item.id === id);

    setLike(isItemInWishlist ? true : false);
    setInCart(isItemInCart ? true : false);
  }, [cartItems, wishListItems, id]);

  const isWishlist = ["/wishlist"].includes(pathname);

  const handleWish = (e) => {
    e.stopPropagation();
    if (!like) {
      dispatch(
        addToWishlist({
          image,
          discount,
          finalPrice,
          product_name,
          rating,
          id,
          tag,
          price,
        })
      );
      toast.success("Item added to wishlist");
    } else {
      dispatch(removeFromWishlist(id));
      toast.error("Item removed from wishlist");
    }
    setLike(!like);
  };

  const handleDeleteWish = (e) => {
    e.stopPropagation();
    if (like) {
      dispatch(removeFromWishlist(id));
      toast.info("Item removed from wishlist");
      setLike(false);
    }
    console.log(id);
    console.log(`deleted item with this ${id}`);
  };

  const viewProduct = (e) => {
    e.stopPropagation();
    navigate(`/products/${slug}/${id}`);
  };

  const handleShareClick = (e) => {
    e.stopPropagation();
    const url = `${window.location.origin}/products/${slug}/${id}`;

    navigator.clipboard
      .writeText(url)
      .then(() => {
        toast.success("URL copied!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
        toast.error("Failed to copy URL");
      });
  };

  const addingToCart = (e) => {
    setAdding(true)
    e.stopPropagation();
    if ((sizes.length > 0 && colors.length > 0) && (details.color === "" || details.size === "")) {
      toast.warn('Kindly add a color or a size');
      setAdding(false)
      return;
    }
    const formData = new FormData();
    formData.append("product_name", product_name);
    formData.append("quantity", details.quantity);
    formData.append("color", details.color);
    formData.append("size", details.size);
    formData.append("vendor", rest.vendor_id);

    // Dispatch the thunk action
    dispatch(addToCart({ id, formData }))
      .unwrap()
      .then((res) => {
        toast.success(`${product_name} added to cart`);
      })
      .catch((err) => {
        console.log(err);
        toast.error(`Failed to add ${product_name} to cart`);
      }).finally(() => setAdding(false))
  };

  let sizes = [];
  if (rest && rest.product_size) {
    try {
      sizes = JSON.parse(rest.product_size);
    } catch (error) {
      console.error("Error parsing product sizes:", error);
    }
  }
  let colors = [];
  if (rest && rest?.product_color) {
    try {
      colors = JSON.parse(rest?.product_color);
    } catch (error) {
      console.error("Error parsing product sizes:", error);
    }
  }

  return (
    <article
      className="w-full relative md:w-[350px] border p-4 rounded-lg shadow-lg overflow-hidden"
      onClick={viewProduct}
    >
      {/* Discount Badge */}
      {isDiscountValid && (
        <div className="absolute mb-3 top-0 -right-1 bg-red-500 text-white text-xs px-2 py-1 rounded-full">
          -{discountPercentage.toFixed(2)}%
        </div>
      )}

      {/* image side */}
      <figure className="relative p-4">
        <img
          src={image}
          alt={product_name}
          className="w-full h-48 object-cover rounded-lg"
        />
        <div className="absolute inset-0 flex items-center cursor-pointer justify-center gap-4 opacity-0 hover:opacity-100 bg-black bg-opacity-50 transition-opacity duration-300 rounded-lg">
          <LuEye
            className="text-white text-2xl"
            onClick={(e) => {
              e.stopPropagation();
              onView(id);
            }}
          />
          <MdOutlineShare
            className="text-white text-2xl"
            onClick={handleShareClick}
          />
          <div onClick={handleWish}>
            {!like ? (
              <CiHeart className="text-white text-2xl" />
            ) : (
              <AiFillHeart className="fill-red-700" />
            )}
          </div>
          {isWishlist && (
            <div onClick={handleDeleteWish}>
              <RiDeleteBinLine className="fill-black" />
            </div>
          )}
        </div>
      </figure>

      {/* details side */}
      <div className="mt-4">
        <h5 className="text-lg font-semibold mt-1">{product_name}</h5>
        <ReactStars
          count={5}
          value={3}
          size={25}
          color2="#ff8800"
          edit={true}
          className="mt-2"
        />
        <small className="block text-gray-500 mt-1">{createdBy}</small>
      </div>

      {/* price and addToCart */}
      <div className="mt-4 flex items-center justify-between">
        <div className="flex items-center gap-3 justify-between">
          <article className="text-[10px]" onClick={(e) => e.stopPropagation()}>
            <label htmlFor="color">Color</label>
            <select name="color" id="color" onChange={handleDetails} value={details.color}>
            <option value="">Select a color</option>
              {
                colors.map((color) => {
                  return (
                    <option value={color}>{color}</option>
                  )
                })
              }
            </select>
          </article>
          <article className="text-[10px]" onClick={(e) => e.stopPropagation()}>
            <label htmlFor="size">Size</label>
            <select name="size" id="" onChange={handleDetails} value={details.size}>
            <option value="">Select a size</option>
              {
                sizes.map(size => {
                  return (
                    <option value={size}>{size}</option>
                  )
                })
              }
              <option value="red">L</option>
            </select>
          </article>
          <article className="text-[10px]" onClick={(e) => e.stopPropagation()}>
            <label htmlFor="quantity">Quantity</label>
            <select name="quantity" id="" onChange={handleDetails} value={details.quantity}>
              <option value={1}>1</option>
              <option value={2}>2</option>
              <option value={3}>3</option>
            </select>
          </article>
        </div>
      </div>

      {/* price and addToCart */}
      <div className="mt-4 flex items-center justify-between">
        <div className="flex items-center gap-5 justify-between">
          <p className="text-base font-bold text-green-600">
            ₦{formatPrice(finalPrice)}
          </p>
          {discount > 0 && <p className="text-[12px] text-gray-500 line-through">
            ₦{formatPrice(price)}
          </p>}
        </div>
        <button
          type="button"
          disabled={adding === true}
          onClick={addingToCart}
          className="bg-blue-600 text-white text-sm p-2 rounded-lg"
        >
          {adding ? <CircularProgress size={20} sx={{color:'whitesmoke'}}/> : 'Add to Cart'}
        </button>
      </div>
    </article>
  );
};

export default NewProductCard;
