import React, { useEffect, useRef } from "react";
import api from "../../services/api";
import { useSelector } from "react-redux";
import { useState } from "react";
import { toast } from "react-toastify";
import { AiOutlineEdit } from "react-icons/ai";
import service from "../../services";
import Loading from "../../components/Loading";
import { auth } from "../../services/api/auth";

const Profile = () => {
  const { user } = useSelector((store) => store.auth);
  const role = user.role;

  const [loading, setLoading] = useState(false);

  const [profile, setProfile] = useState({
    name: "",
    email: "",
    phone: "",
    address: "",
    photo: "",
    username: "",
  });
  const [updatePassword, setUpdatePassword] = useState({
    current: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUpdatePassword((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    setLoading(true)
    e.preventDefault();
    if (
      !updatePassword.current ||
      !updatePassword.newPassword ||
      !updatePassword.confirmNewPassword
    ) {
      toast.info("Please fill in all fields");
      return;
    }
    // Check if new password and confirm new password match
    if (updatePassword.newPassword !== updatePassword.confirmNewPassword) {
      toast.error("New password and confirm new password do not match");
      return;
    }
    const formData = new FormData();
    formData.append("current_password", updatePassword.current);
    formData.append("new_password", updatePassword.newPassword);
    formData.append(
      "new_password_confirmation",
      updatePassword.confirmNewPassword
    );

    auth.changePassword('vendor',formData).then((res) => {
      if (res.status === 200) {
        toast.success(res.data.message)
        setUpdatePassword(
          {
            current: '',
            newPassword: '',
            confirmNewPassword: ''
          }
        )
      }
    }).catch((err) => {
      console.log(err);
    }).finally(()=>setLoading(false));
  };
  const [hasChanges, setHasChanges] = useState(false);

  const fileInputRef = useRef(null);

  const handleFileUpload = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setHasChanges(true);

    setProfile({
      ...profile,
      photo: selectedFile,
    });

    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile({ ...profile, [name]: value });
    setHasChanges(true);
  };

  // get profile
  useEffect(() => {
    if (role === "vendor") {
      api.vendor.profile
        .get()
        .then((res) => {
          setProfile(res.data?.user);
        })
        .catch((res) => console.log(res));
    } else {
      api.admin.profile
        .get()
        .then((res) => {
          setProfile(res.data?.user);
        })
        .catch((res) => console.log(res));
    }
  }, [role]);

  const handleUpdate = (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append("name", profile.name);
    formData.append("phone", profile.phone);
    formData.append("address", profile.address);
    formData.append("photo", profile.photo);

    if (role === "vendor") {
      api.vendor.profile
        .updateProfile(formData)
        .then((res) => {
          setHasChanges(false);
          toast.success(res.data?.message);
          setTimeout(() => {
            service.reloadPage();
          }, 1000);
        })
        .catch((res) => console.log(res));
    } else {
      api.admin.profile
        .updateProfile(formData)
        .then((res) => {
          setHasChanges(false);
          toast.success(res.data?.message);
          setTimeout(() => {
            service.reloadPage();
          }, 1000);
        })
        .catch((res) => console.log(res));
    }
  };

  return (
    <main>
      <h1 className="roleHeaders">Profile</h1>
      <section className="profile">
        <aside className="profile_picture">
          <figure className="relative">
            <img
              src={
                `https://ebn.itl.ng/public/upload/${
                  role === "admin" ? "admin_images/" : "vendor_images/"
                }` + profile?.photo
              }
              alt="profile"
            />
            <AiOutlineEdit
              onClick={handleFileUpload}
              className="absolute right-2 top-2"
            />
            <input
              type="file"
              name=""
              accept=".jpg"
              id=""
              ref={fileInputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </figure>
        </aside>
        <section className="profile_info">
          <div>
            <h2 className="text-xl my-2 font-bold">Personal Information</h2>
            <form>
              <article>
                <label htmlFor="name">
                  {role === "vendor" ? "Store Name" : "Username"}
                </label>
                <input
                  type="text"
                  id="username"
                  name="username"
                  value={profile.username}
                  onChange={handleInputChange}
                  disabled
                  className="w-full border p-2 rounded"
                />
              </article>
              <article>
                <label htmlFor="name">Full Name</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={profile.name}
                  onChange={handleInputChange}
                  className="w-full border p-2 rounded"
                />
              </article>
              <article>
                <label htmlFor="name">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={profile.email}
                  onChange={handleInputChange}
                  className="w-full border p-2 rounded"
                />
              </article>
              <article>
                <label htmlFor="name">Phone</label>
                <input
                  type="tel"
                  id="phone"
                  name="phone"
                  value={profile.phone}
                  onChange={handleInputChange}
                  className="w-full border p-2 rounded"
                />
              </article>
              <article>
                <label htmlFor="name">Address</label>
                <input
                  type="text"
                  id="address"
                  name="address"
                  value={profile.address}
                  onChange={handleInputChange}
                  className="w-full border p-2 rounded"
                />
              </article>
              <div className="flex gap-3 w-full">
                <button
                  type="button"
                  className={!hasChanges ? "disabled" : "submitBtn"}
                  disabled={!hasChanges}
                  onClick={handleUpdate}
                >
                  Update
                </button>
                <button
                  type="button"
                  className={!hasChanges ? "disabled" : "cancel"}
                  disabled={!hasChanges}
                  onClick={() => setHasChanges(false)}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
          <section className="mt-4">
            <h3 className="text-xl font-medium mb-2">Password Changes</h3>
            <div className="flex flex-col gap-3 w-full">
              <input
                type="password"
                name="current"
                id="current password"
                className="input"
                placeholder="Current Password"
                value={updatePassword.current}
                onChange={handleChange}
              />
              <input
                type="password"
                name="newPassword"
                id="new password"
                className="input"
                placeholder="New Password"
                value={updatePassword.newPassword}
                onChange={handleChange}
              />
              <input
                type="password"
                name="confirmNewPassword"
                id="confirm new password"
                className="input"
                placeholder="Confirm New Password"
                value={updatePassword.confirmNewPassword}
                onChange={handleChange}
              />
            </div>
          </section>
          <button
            type="button"
            onClick={handleSubmit}
            disabled={loading}
            className="bg-blue-600 py-3 px-6 ml-auto mt-3 text-white rounded-md flex"
          >
            {loading ? <Loading /> : "Change"}
          </button>
        </section>
      </section>
    </main>
  );
};

export default Profile;
