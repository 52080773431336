/* eslint-disable no-unused-vars */
import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../../../services/api";
import { v4 as uuidv4 } from "uuid";
import { Editor } from "@tinymce/tinymce-react";

const AddProduct = () => {
  const { user } = useSelector((store) => store.auth);
  const role = user.role;
  const { id } = useParams();
  const editorRef = useRef(null);

  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const [product, setProduct] = useState({
    product_name: "",
    product_tags: [],
    product_size: [],
    product_color: [],
    short_description: "",
    long_description: "",
    product_thumbnail: null,
    selling_price: 0,
    discount_price: null,
    product_code: "",
    product_qty: 0,
    brand_id: "",
    category_id: "",
    sub_category_id: "",
    vendor_id: "",
    hot_deals: 0,
    special_offer: 0,
    special_deals: 0,
    featured: 0,
    created_at: "",
    sku: "",
    multi_img: [],
  });
  const dealTypes = ["hot_deals", "special_offer", "special_deals", "featured"];

  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [subCategories, setSubcategories] = useState([]);

  useEffect(() => {
    if (role === "vendor") {
      // get brands
      api.vendor.brand
        .getBrands()
        .then((res) => {
          setBrands(res.data?.brands);
        })
        .catch((res) => console.log(res));
      // get categories
      api.vendor.category
        .getCategories()
        .then((res) => {
          setCategories(res.data?.categories);
        })
        .catch((res) => console.log(res));
      // get subcategories
      api.vendor.subcategory
        .getSubcategories()
        .then((res) => {
          setSubcategories(res.data?.subcategories);
        })
        .catch((res) => {
          toast.error(res.data.message, { autoClose: 1000 });
        });
    } else {
      // get brands
      api.admin.brand
        .getBrands()
        .then((res) => setBrands(res.data?.brands))
        .catch((res) => console.log(res));
      // get categories
      api.admin.category
        .getCategories()
        .then((res) => {
          setCategories(res.data?.categories);
        })
        .catch((res) => console.log(res));
      // get subcategories
      api.admin.subcategory
        .getSubcategories()
        .then((res) => {
          setSubcategories(res.data?.subcategories);
        })
        .catch((res) => {
          toast.error(res.data.message, { autoClose: 1000 });
        });
    }
  }, [role]);

  useEffect(() => {
    if (id) {
      if (role === "vendor") {
        // get product
        api.vendor.products
          .getProduct(id)
          .then((res) => {
            console.log(res);
            const productData = res.data?.Product;
            const parsedProductData = {
              ...productData,
              product_tags: JSON.parse(productData.product_tags),
              product_size: JSON.parse(productData.product_size),
              product_color: JSON.parse(productData.product_color),
            };
            setProduct(parsedProductData);
          })
          .catch((res) => console.log(res));
      } else {
        // get product
        api.admin.products
          .getProduct(id)
          .then((res) => {
            const productData = res.data?.Product;
            const parsedProductData = {
              ...productData,
              product_tags: JSON.parse(productData.product_tags),
              product_size: JSON.parse(productData.product_size),
              product_color: JSON.parse(productData.product_color),
            };
            setProduct(parsedProductData);
          })
          .catch((res) => console.log(res));
      }
    }
  }, [id, role]);

  useEffect(() => {
    if (!id) {
      const generateCode = () => {
        const uniqueProductCode = `PROD-${Date.now()
          .toString()
          .slice(-3)}-${uuidv4().slice(0, 11)}`;
        setProduct((prevProduct) => ({
          ...prevProduct,
          product_code: uniqueProductCode,
        }));
      };
      const generateSku = () => {
        const formatDate = (date) => {
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, "0");
          const day = String(date.getDate()).padStart(2, "0");
          return `${year}${month}${day}`;
        };

        // Function to format time as HH:MM:SS
        const formatTime = (date) => {
          const hours = String(date.getHours()).padStart(2, "0");
          const minutes = String(date.getMinutes()).padStart(2, "0");
          const seconds = String(date.getSeconds()).padStart(2, "0");
          return `${hours}${minutes}${seconds}`;
        };

        // Generate the date part of the SKU
        const currentDate = new Date();
        const formattedDate = formatDate(currentDate);
        const formattedTime = formatTime(currentDate);

        // Combine user's name, date, and time
        const userNameWithoutSpaces = user.name
          .replace(/\s/g, "")
          .substring(0, 8);
        const dateTimeString = `${userNameWithoutSpaces}${formattedDate}${formattedTime}`;

        // Truncate or pad to desired length
        const desiredLength = 40; // Change this to your desired length
        const product_sku = dateTimeString
          .substring(0, desiredLength)
          .padEnd(desiredLength, "0");
        setProduct((prevProduct) => ({
          ...prevProduct,
          sku: product_sku,
        }));
      };
      generateCode();
      generateSku();
    }
  }, [id, user.name]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (name === "discount_price" && parseFloat(value) > product.selling_price) {
      alert("Discount price must be less than selling price");
    }

    setProduct((prevProduct) => ({
      ...prevProduct,
      [name]: value,
    }));
  };

  // handle add tag
  const handleTagChange = (e) => {
    const { value } = e.target;

    setProduct((prevProduct) => {
      return {
        ...prevProduct,
        product_tags: value
          .split(",")
          .map((item) => item.trim())
          .filter(Boolean),
      };
    });
  };
  const addTag = (newTag) => {
    setProduct((prevProduct) => {
      return {
        ...prevProduct,
        product_tags: [...prevProduct.product_tags, newTag.trim()],
      };
    });
  };

  // handle size change
  const handleSizeChange = (e) => {
    const { value } = e.target;

    setProduct((prevProduct) => {
      return {
        ...prevProduct,
        product_size: value
          .split(",")
          .map((item) => item.trim())
          .filter(Boolean),
      };
    });
  };
  const addSize = (newSize) => {
    setProduct((prevProduct) => {
      return {
        ...prevProduct,
        product_size: [...prevProduct.product_size, newSize.trim()],
      };
    });
  };

  // handle size change
  const handleColorChange = (e) => {
    const { value } = e.target;

    setProduct((prevProduct) => {
      return {
        ...prevProduct,
        product_color: value
          .split(",")
          .map((item) => item.trim())
          .filter(Boolean),
      };
    });
  };
  const addColor = (newColor) => {
    setProduct((prevProduct) => {
      return {
        ...prevProduct,
        product_color: [...prevProduct.product_color, newColor.trim()],
      };
    });
  };

  const handleImageChange = (e, imageInputName) => {
    const image = e.target.files[0];
    setProduct((prev) => ({
      ...prev,
      [imageInputName]: image,
    }));
  };

  const handleMultiImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProduct((prev) => ({
        ...prev,
        multi_img: [...prev.multi_img, file],
      }));
    }
  };

  const handleDealsCheck = (selectedDealType) => {
    setProduct((prevProduct) => {
      const updatedProduct = { ...prevProduct };

      // Set all deal types to 0
      dealTypes.forEach((dealType) => {
        updatedProduct[dealType] = 0;
      });

      // Set the selected deal type to 1
      updatedProduct[selectedDealType] = 1;

      return updatedProduct;
    });
  };

  const isProductValid = () => {
    const excludedFields = [
      "vendor_id",
      "hot_deals",
      "special_offer",
      "special_deals",
      "featured",
      "created_at",
      "sku",
      "discount_price"
    ];
    const emptyFields = [];

    for (const key in product) {
      if (product.hasOwnProperty(key) && !excludedFields.includes(key)) {
        const value = product[key];

        // Exclude 0 from the empty check
        if (
          value === null ||
          value === "" ||
          (typeof value === "number" && isNaN(value))
        ) {
          emptyFields.push(key);
        }
      }
    }

    return emptyFields;
  };

  const validateDescriptions = () => {
    const shortWords = product.short_description.trim().split(/\s+/).length;
    const longWords = product.long_description.trim().split(/\s+/).length;

    if (shortWords > 20) {
      return "Short description must not exceed 20 words.";
    }

    if (longWords > 50) {
      return "Long description must not exceed 50 words.";
    }

    return null;
  };

  const CreateProduct = (e) => {
    e.preventDefault();
    const emptyFields = isProductValid();
    if (emptyFields.length > 0) {
      toast.error(
        `Please fill in the following fields: ${emptyFields.join(", ")}`
      );
      return;
    }
    if (Number(product.discount_price) > product.selling_price) {
      alert("Discount price must be less than selling price");
      return;
    }
    const validationError = validateDescriptions();
    if (validationError) {
      toast.error(validationError);
      return;
    }
    setSuccess(!success);

    const currentTime = new Date().toLocaleString();

    const formData = new FormData();
    formData.append("brand_id", product.brand_id);
    formData.append("category_id", product.category_id);
    formData.append("subcategory_id", product.sub_category_id);
    formData.append("product_name", product.product_name);
    formData.append("product_thumbnail", product.product_thumbnail);
    formData.append("product_code", product.product_code);
    formData.append("product_qty", product.product_qty);

    formData.append("selling_price", product.selling_price);
    formData.append("discount_price", product.discount_price);
    formData.append("short_description", product.short_description);
    formData.append("long_description", product.long_description);

    formData.append("hot_deals", product.hot_deals);
    formData.append("special_offer", product.special_offer);
    formData.append("special_deals", product.special_deals);
    formData.append("featured", product.featured);

    formData.append("created_at", currentTime);
    formData.append("sku", product.sku);

    formData.append("product_tags", JSON.stringify(product.product_tags));
    formData.append("product_size", JSON.stringify(product.product_size));
    formData.append("product_color", JSON.stringify(product.product_color));

    product.multi_img.forEach((image, index) => {
      formData.append(`multi_img[${index}]`, image);
    });

    // for (let [key, value] of formData.entries()) {
    //   console.log(`Field: ${key}, Value: ${value}`);
    // }

    if (id) {
      if (role === "vendor") {
        api.vendor.products
          .editProduct(id, formData)
          .then((res) => {
            toast.success(res.data?.message);
            navigate(`/${role}/allProducts`);
          })
          .catch((res) => console.log(res))
          .finally(() => setSuccess(!success));
      } else {
        api.admin.products
          .editProduct(id, formData)
          .then((res) => {
            toast.success(res.data?.message);
            navigate(`/${role}/allProducts`);
          })
          .catch((res) => {
            console.log(res);
          })
          .finally(() => setSuccess(!success));
      }
    } else {
      if (role === "vendor") {
        api.vendor.products
          .createProduct(formData)
          .then((res) => {
            toast.success(res.data?.message);
            navigate(`/${role}/allProducts`);
          })
          .catch((res) => {
            console.log(res);
          })
          .finally(() => setSuccess(!success));
      } else {
        api.admin.products
          .createProduct(formData)
          .then((res) => {
            toast.success(res.data?.message);
            if (res.status === 200) {
              navigate(`/${role}/allProducts`);
            }
          })
          .catch((res) => {
            console.log(res);
          })
          .finally(() => setSuccess(!success));
      }
    }
  };

  const handleEditorChange = (content) => {
    setProduct((prev)=>({
      ...prev,
      long_description: content
    }));
  };
  const handleEditorShortChange = (content) => {
    setProduct((prev)=>({
      ...prev,
      short_description: content
    }));
  };

  return (
    <main>
      <section className="topSection">
        <h1 className="roleHeaders">{id ? "Edit Product" : "Add Product"}</h1>
        <br></br>
      </section>

      <form className="productForm">
        <div className="w-full md:w-[65%] productFormDiv">
          <article>
            <label htmlFor="product_name">Product Name</label>
            <input
              type="text"
              name="product_name"
              id="product_name"
              className="productInput w-full"
              value={product.product_name}
              onChange={handleInputChange}
            />
          </article>
          <article>
            <label htmlFor="product_tags">Product Tags</label>
            <div className="">
              <button
                type="button"
                className="bg-green-500 mb-2 rounded-md text-sm text-white p-1"
                onClick={() => {
                  const newTag = prompt("Enter a new tag:");
                  if (newTag) {
                    addTag(newTag);
                  }
                }}
              >
                Add Tag
              </button>
              <input
                type="text"
                name="product_tags"
                id="product_tags"
                data-role="tagsinput"
                value={product.product_tags.join(",")}
                onChange={handleTagChange}
                className="productInput w-full"
              />
            </div>
          </article>
          <article>
            <label htmlFor="product_size">Product Size</label>
            <div>
              <button
                type="button"
                className="bg-green-500 mb-2 rounded-md text-sm text-white p-1"
                onClick={() => {
                  const newSize = prompt("Enter a new size:");
                  if (newSize) {
                    addSize(newSize);
                  }
                }}
              >
                Add Size
              </button>
              <input
                type="text"
                name="product_size"
                id="product_size"
                data-role="sizeinput"
                value={product.product_size.join(",")}
                className="productInput w-full"
                onChange={handleSizeChange}
              />
            </div>
          </article>
          <article>
            <label htmlFor="product_color">Product Color</label>
            <div>
              <button
                type="button"
                className="bg-green-500 mb-2 rounded-md text-sm text-white p-1"
                onClick={() => {
                  const newColor = prompt("Enter a new color:");
                  if (newColor) {
                    addColor(newColor);
                  }
                }}
              >
                Add Color
              </button>
              <input
                type="text"
                name="product_color"
                id="product_color"
                data-role="colorinput"
                value={product.product_color.join(",")}
                className="productInput w-full"
                onChange={handleColorChange}
              />
            </div>
          </article>
          <article>
            <label htmlFor="short_description">Short Description</label>
            {/* <textarea
              name="short_description"
              id="short_description"
              cols="30"
              rows="10"
              className="productInput w-full"
              value={product.short_description}
              onChange={handleInputChange}
            /> */}
            <Editor
              value={product.short_description}
              onEditorChange={handleEditorShortChange}
              onInit={(_evt, editor) => (editorRef.current = editor)}
              apiKey={'qnjby2dxdumnsbuveq8h82nqcoss841ppwgnep0bhsf32sj0'}
              init={{
                plugins:
                  "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker",
                toolbar:
                  "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
                tinycomments_mode: "embedded",
                tinycomments_author: "Author name",
                ai_request: (request, respondWith) =>
                  respondWith.string(() =>
                    Promise.reject("See docs to implement AI Assistant")
                  ),
                forced_root_block: false,
              }}
              initialValue="Type here"
            />
          </article>
          <article>
            <label htmlFor="long_description">Long Description</label>
            {/* <textarea
              name="long_description"
              id="long_description"
              cols="30"
              rows="10"
              className="productInput w-full"
              value={product.long_description}
              onChange={handleInputChange}
            /> */}
            <Editor
              value={product.long_description}
              onEditorChange={handleEditorChange}
              onInit={(_evt, editor) => (editorRef.current = editor)}
              apiKey={'qnjby2dxdumnsbuveq8h82nqcoss841ppwgnep0bhsf32sj0'}
              init={{
                plugins:
                  "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount linkchecker",
                toolbar:
                  "undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | addcomment showcomments | spellcheckdialog a11ycheck typography | align lineheight | checklist numlist bullist indent outdent | emoticons charmap | removeformat",
                tinycomments_mode: "embedded",
                tinycomments_author: "Author name",
                ai_request: (request, respondWith) =>
                  respondWith.string(() =>
                    Promise.reject("See docs to implement AI Assistant")
                  ),
                forced_root_block: false,
              }}
              initialValue="Type here"
            />
          </article>
          <article>
            <label htmlFor="mainImage">Main Thumbnail</label>
            <input
              type="file"
              name="product_thumbnail"
              id="product_thumbnail"
              accept=".jpg, .jpeg, .png"
              className="productInput w-full"
              onChange={(e) => handleImageChange(e, "product_thumbnail")}
            />
          </article>
          <div>
            {product.product_thumbnail && (
              <img
                // src={URL.createObjectURL(product.product_thumbnail)}
                src={
                  product.product_thumbnail instanceof File
                    ? URL.createObjectURL(product.product_thumbnail)
                    : product.product_thumbnail
                    ? "https://ebn.ng/upload/products/thumbnail/" +
                      product.product_thumbnail
                    : "https://default-remote-image-url.com" // Provide a default URL here
                }
                alt="Brand Logo"
                width="100"
                height="100"
              />
            )}
          </div>
          {/* new section for images */}
          <section className="grid grid-cols-2 gap-4">
            {/* product 1 */}
            <div>
              <article>
                <label htmlFor="mainImage">Product 1</label>
                <input
                  type="file"
                  name="product_image1"
                  id="product_image1"
                  accept=".jpg, .jpeg, .png"
                  className="productInput w-full"
                  onChange={handleMultiImageChange}
                />
              </article>
              <div>
                {product.multi_img && product.multi_img[0] && (
                  <img
                    // src={URL.createObjectURL(product.product_image1)}
                    src={
                      product.multi_img[0] instanceof File
                        ? URL.createObjectURL(product.multi_img[0])
                        : product.multi_img[0]
                        ? "https://ebn.ng/upload/products/multi-image/" +
                          product.multi_img[0]
                        : "https://default-remote-image-url.com" // Provide a default URL here
                    }
                    alt="Brand Logo"
                    width="100"
                    height="100"
                  />
                )}
              </div>
            </div>
            {/* product 2 */}
            <div>
              <article>
                <label htmlFor="product_2">Product 2</label>
                <input
                  type="file"
                  name="product_image2"
                  id="product_image2"
                  accept=".jpg, .jpeg, .png"
                  className="productInput w-full"
                  onChange={handleMultiImageChange}
                />
              </article>
              <div>
                {product.multi_img && product.multi_img[1] && (
                  <img
                    src={
                      product.multi_img[1] instanceof File
                        ? URL.createObjectURL(product.multi_img[1])
                        : product.multi_img[1]
                        ? "https://ebn.ng/upload/products/multi-image/" +
                          product.multi_img[1]
                        : "https://default-remote-image-url.com" // Provide a default URL here
                    }
                    alt="Brand Logo"
                    width="100"
                    height="100"
                  />
                )}
              </div>
            </div>
            {/* product 3 */}
            <div>
              <article>
                <label htmlFor="mainImage">Product 3</label>
                <input
                  type="file"
                  name="product_image3"
                  id="product_image3"
                  accept=".jpg, .jpeg, .png"
                  className="productInput w-full"
                  onChange={handleMultiImageChange}
                />
              </article>
              <div>
                {product.multi_img && product.multi_img[2] && (
                  <img
                    src={
                      product.multi_img[2] instanceof File
                        ? URL.createObjectURL(product.multi_img[2])
                        : product.multi_img[2]
                        ? "https://ebn.ng/upload/products/multi-image/" +
                          product.multi_img[2]
                        : "https://default-remote-image-url.com" // Provide a default URL here
                    }
                    alt="Brand Logo"
                    width="100"
                    height="100"
                  />
                )}
              </div>
            </div>
            {/* product 4 */}
            <div>
              <article>
                <label htmlFor="mainImage">Product 4</label>
                <input
                  type="file"
                  name="product_image4"
                  id="product_image4"
                  accept=".jpg, .jpeg, .png"
                  className="productInput w-full"
                  onChange={handleMultiImageChange}
                />
              </article>
              <div>
                {product.multi_img && product.multi_img[3] && (
                  <img
                    src={
                      product.multi_img[3] instanceof File
                        ? URL.createObjectURL(product.multi_img[3])
                        : product.multi_img[3]
                        ? "https://ebn.ng/upload/products/multi-image/" +
                          product.multi_img[3]
                        : "https://default-remote-image-url.com" // Provide a default URL here
                    }
                    alt="Brand Logo"
                    width="100"
                    height="100"
                  />
                )}
              </div>
            </div>
          </section>
        </div>

        <div className="w-full md:w-[35%] productFormDiv">
          <article>
            <label htmlFor="productPrice">Product Price</label>
            <input
              type="text"
              name="selling_price"
              id="productPrice"
              className="productInput w-full"
              value={product.selling_price}
              onChange={handleInputChange}
              placeholder="eg. 40000 , 500000"
            />
          </article>
          <article>
            <label htmlFor="discount">Discounted Price</label>
            <input
              type="text"
              name="discount_price"
              id="discount"
              className="productInput w-full"
              value={product.discount_price}
              onChange={handleInputChange}
              max={product.selling_price}
              placeholder="just put a value, eg: 10000 or 5000 or 700"
            />
          </article>
          <article>
            <label htmlFor="code">Product Code</label>
            <input
              type="text"
              name="product_code"
              id="code"
              className="productInput w-full"
              value={product.product_code}
              disabled
            />
          </article>
          <article>
            <label htmlFor="quantity">Product Quantity</label>
            <input
              type="text"
              name="product_qty"
              id="quantity"
              className="productInput w-full"
              value={product.product_qty}
              onChange={handleInputChange}
            />
          </article>

          <article>
            <label htmlFor="brand">Product Brand</label>
            <select
              name="brand_id"
              id="brand"
              className="productInput w-full"
              value={product.brand_id}
              onChange={handleInputChange}
            >
              <option value=""></option>
              {brands &&
                brands.map((brand, idx) => {
                  return (
                    <option key={idx} value={brand.id}>
                      {brand.brand_name}
                    </option>
                  );
                })}
            </select>
          </article>

          <article>
            <label htmlFor="category">Product Category</label>
            <select
              name="category_id"
              id="category"
              className="productInput w-full"
              value={product.category_id}
              onChange={handleInputChange}
            >
              <option value=""></option>
              {categories &&
                categories.map((cat, idx) => {
                  return (
                    <option key={idx} value={cat.id}>
                      {cat.category_name}
                    </option>
                  );
                })}
            </select>
          </article>
          <article>
            <label htmlFor="subcategory">Product SubCategory</label>
            <select
              name="sub_category_id"
              id="subcategory"
              className="productInput w-full"
              value={product.sub_category_id}
              onChange={handleInputChange}
            >
              <option value=""></option>
              {subCategories &&
                subCategories.map((sub, idx) => {
                  return (
                    <option key={idx} value={sub.id}>
                      {sub.subcategory_name}
                    </option>
                  );
                })}
            </select>
          </article>

          {dealTypes.map((dealType) => (
            <div
              key={dealType}
              className="flex items-center gap-2 font-medium text-lg capitalize"
            >
              <input
                type="checkbox"
                id={dealType}
                name="dealType"
                checked={product[dealType] === 1}
                onChange={() => handleDealsCheck(dealType)}
              />
              <label htmlFor={dealType}>
                {dealType === "hot_deals"
                  ? "Hot deals"
                  : dealType === "special_offer"
                  ? "Special Offer"
                  : dealType === "special_deals"
                  ? "Special Deals"
                  : dealType}
              </label>
            </div>
          ))}

          <button
            disabled={success}
            type="button"
            className={success ? "disabled" : "submitBtn"}
            onClick={CreateProduct}
          >
            {id ? "Update Product" : "Create Product"}
          </button>
        </div>
      </form>
    </main>
  );
};

export default AddProduct;
