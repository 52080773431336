import React from "react";
import { formatPrice } from "../../components/FormatPrice";

const ModalSingleProduct = ({ data, closeModal }) => {
 let tagElements = null;
 let colorElements = null;
 let sizeElements = null;
 if (data && data.product_tags) {
  try {
   const Tags = JSON.parse(data.product_tags);
   tagElements = Tags.map((tag, index) => (
    <div className="" key={index}>{index + 1}. {tag}</div>
   ));
   const color = JSON.parse(data?.product_color)
   colorElements = color.map((color, index) => (
    <div className="" key={index}>{index + 1}. {color}</div>
   ));
   const size = JSON.parse(data?.product_size)
   sizeElements = size.map((size, index) => (
    <div className="" key={index}>{index + 1}. {size}</div>
   ));
  } catch (error) {
   console.error("Error parsing product tags:", error);
  }
 } else {
  console.warn("Product tags are not available.");
 }

 return (
  <section className="modalProduct">
   <div className="modalClose">
    <button type="button" onClick={closeModal} className="modalButton">
     Close
    </button>
   </div>
   <table className="min-w-full divide-y divide-gray-200 my-6 w-full">
    <thead>
     <tr className="tableHeadRow">
      <th className="tableHead">Created By</th>
      <th className="tableHead">Product Image</th>
      <th className="tableHead">Product Name</th>
      <th className="tableHead">Product Price</th>
      <th className="tableHead">Product Quantity</th>
      <th className="tableHead">Discount %</th>
      <th className="tableHead">Product Status</th>
      <th className="tableHead">Product Code</th>
     </tr>
    </thead>
    <tbody>
     <tr key={data?.product_name}>
      <td>{data?.created_by}</td>
      <td>
       <img
        src={"https://ebn.ng/public/upload/products/thumbnail/" + data?.product_thumbnail}
        alt="product 1"
        className="w-[70px] h-[70px] rounded-full mx-auto"
       />
      </td>
      <td>{data?.product_name}</td>
      <td>₦{formatPrice(data?.selling_price)}</td>
      <td>{data?.product_qty}</td>
      <td>{data?.discount_price}%</td>
      <td className="px-6 py-4 whitespace-nowrap">
       {data?.status === 'inactive' ? 'Activate' : 'Activated'}
      </td>
      <td className="px-6 py-4 whitespace-break-spaces">{data?.product_code}</td>
     </tr>
    </tbody>
   </table>
   <section>
    <ul>
     <li>Vendor iD: {data?.vendor_id}</li>
     <li>Deal: {data?.hot_deals === 1 ? 'Hot deal' : data?.featured === 1 ? 'Featured Deal' : data?.special_offer === 1 ? 'Special offer' : data?.specail_deals === 1 ? 'Special deal' : 'No deal'}</li>
     <li>Status: {data?.status}</li>
    </ul>
    <article className="my-2">
     <h2>Tags</h2>
     {
      tagElements
     }
    </article>
    <article className="my-2">
     <h2>Size</h2>
     {
      sizeElements
     }
    </article>
    <article className="my-2">
     <h2>Color</h2>
     {
      colorElements
     }
    </article>

   </section>
  </section>
 );
};

export default ModalSingleProduct;
