import React, { useState } from "react";
import Header from "../assets/contactus.jpg";
import { FaFacebookF } from "react-icons/fa";
// import { BsTwitter } from "react-icons/bs";
import { FiInstagram } from "react-icons/fi";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import api from "../services/api";

const ContactUs = () => {
  const [contact, setContact] = useState({
    name: "",
    subject: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setContact({
      ...contact,
      [name]: value,
    });
  };

  const isAnyFieldEmpty = () => {
    for (const key in contact) {
      if (contact[key] === "") {
        return true; // At least one field is empty
      }
    }
    return false; // No field is empty
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isAnyFieldEmpty()) {
      toast.info("Please fill in all fields before submitting.");
      return;
    }
    const formData = new FormData()
    formData.append('name', contact.name)
    formData.append('email', contact.email)
    formData.append('subject', contact.subject)
    formData.append('message', contact.message)

    api.contactUs(formData)
      .then(() => {
        toast.success("Message sent Successfully");
        setContact({
          name: "",
          subject: "",
          email: "",
          message: "",
        });
      })
      .catch((res) => console.log(res));
  };
  return (
    <main className="md:px-[30px] h-auto lg:h-screen lg:mb-[17rem]">
      {/* top */}
      <section className="w-[100%] h-2/4 relative">
        <div className="relative z-0">
          <img src={Header} alt="header" className="w-[100%] " />
          <div class="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-black opacity-40"></div>
          <h2 className="text-lg lg:text-4xl text-white font-bold text-center absolute top-[50%] left-[50%] transform -translate-x-1/2 -translate-y-1/2">
            Let's have a conversation
          </h2>
        </div>
      </section>
      {/* bottom */}
      <section className="lg:absolute lg:top-1/2 lg:left-[50%] lg:transform lg:-translate-x-1/2 lg:-translate-y-[-8%] bg-white shadow-lg xs:w-full lg:w-[65%] p-[40px] mx-auto z-50">
        <h3 className="text-center text-2xl xl:text-4xl pb-3">Contact Us</h3>
        <section className="flex flex-col lg:flex-row gap-10 justify-between items-start mt-10 mb-5">
          {/* left */}
          <div className="w-full lg:w-[40%]">
            <p className="text-xl font-bold">
              We are always ready to cooperate, Write to us and we will reach
              out.
            </p>
            <section className="mt-5">
              <div className="contact">
                <h5 className="text-[14px] font-semibold">EMAIL</h5>
                <small className="text-[10px] text-gray-500 font-medium">
                  info@itl.ng
                </small>
              </div>
              <div className="contact">
                <h5 className="text-[14px] font-semibold">PHONE</h5>
                <small className="text-[10px] text-gray-500 font-medium">
                  +2349130003337
                </small>
              </div>
              <div className="contact">
                <h5 className="text-[14px] font-semibold">ADDRESS</h5>
                <small className="text-[10px] text-gray-500 font-medium">
                  Suite 6 Block B, Alausa Shopping Mall, <br />
                  131, Obafemi Awolowo way, Ikeja, Lagos.
                </small>
              </div>
              <div className="contact">
                <h5 className="text-[14px] font-semibold">SOCIAL</h5>
                <div className="flex flex-row gap-3 items-center text-gray-900 text-xl">
                  <Link to={"https://www.facebook.com/ebn.nig"} target="_blank">
                    <FaFacebookF />
                  </Link>
                  {/* <Link to="">
                    <BsTwitter />
                  </Link> */}
                  <Link
                    to={"https://www.instagram.com/ebn.nig/"}
                    target="_blank"
                  >
                    <FiInstagram />
                  </Link>
                </div>
              </div>
            </section>
          </div>
          {/* right */}
          <div className="w-full lg:w-[50%]">
            <form onSubmit={handleSubmit}>
              <div className="form-input">
                <label htmlFor="name" className="font-medium text-base">
                  NAME
                </label>
                <input
                  type="text"
                  name="name"
                  id=""
                  value={contact.name}
                  onChange={handleChange}
                  className="border-2 border-neutral-950 p-2 outline-lime-400 rounded-lg"
                />
              </div>
              <div className="form-input">
                <label htmlFor="email" className="font-medium text-base">
                  EMAIL ADDRESS
                </label>
                <input
                  type="text"
                  name="email"
                  id=""
                  value={contact.email}
                  onChange={handleChange}
                  className=" border-2 border-neutral-950 p-2 outline-lime-400 rounded-lg"
                />
              </div>
              <div className="form-input">
                <label htmlFor="subject" className="font-medium text-base">
                  SUBJECT
                </label>
                <input
                  type="text"
                  name="subject"
                  id=""
                  value={contact.subject}
                  onChange={handleChange}
                  className=" border-2 border-neutral-950 p-2 outline-lime-400 rounded-lg"
                />
              </div>
              <div className="form-input">
                <label htmlFor="message" className="font-medium text-base">
                  MESSAGE
                </label>
                <textarea
                  name="message"
                  id=""
                  cols="30"
                  rows="10"
                  value={contact.message}
                  onChange={handleChange}
                  className="border-2 border-neutral-950 p-2 outline-lime-400 rounded-lg"
                />
              </div>
              <input
                type="submit"
                value="SEND"
                className="bg-blue-500 w-full px-8 py-2 border-neutral-950 hover:bg-lime-700 hover:border-0 hover:text-white rounded-md"
              />
            </form>
          </div>
        </section>
      </section>
    </main>
  );
};

export default ContactUs;
