import React from "react";
import { Link } from "react-router-dom";
import { MdPersonOutline } from "react-icons/md";
import { AiOutlineLogout } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../../redux/Features/authSlice";
import service from "../../../services";

const MenuDropdown = () => {
 const { user } = useSelector((store) => store.auth);

 const dispatch = useDispatch();

 function Logout (){
  dispatch(logout())
  service.reloadPage()
  window.location.replace('/')
 }

 return (
  <nav className="menudropdown">
   <ul className="menudropdownUl">
    <li className="menudropdownLi">
     <MdPersonOutline className="menudropdownIcon" />
     <Link to={`/${user.role}/profile`}>Profile</Link>
    </li>
    <li className="menudropdownLi">
     <AiOutlineLogout className="menudropdownIcon" />
     <button type="button" onClick={Logout}>Logout</button>
    </li>
   </ul>
  </nav>
 );
};

export default MenuDropdown;
