import axios from "axios";
import service from "../index";
import routes from "../routes";
import { auth } from "./auth";
import { toast } from "react-toastify";

// Request Interceptor
axios.interceptors.request.use(
  async (config) => {
    const token = service.getTokensLocalStorage("tokens");
    if (token) {
      config.headers["Authorization"] = `Bearer ${token?.access}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response Interceptor
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    // first error
    if (error.response.status === 401) {
      //  window.location.replace("/register");
      const token = service.getTokensLocalStorage("tokens");

      auth
        .refreshToken(token)
        .then((res) => {
          service.setTokensLocalStorage({ access: res.data.access_token });
        })
        .catch((res) => console.log("refreshToken", res));
    } else if (error.response.status === 422) {
      toast.error(error.response.data.error);
      toast.error(error.response.data.message);
    } else if (error.response.status === 500) {
      console.error(error.response.data.message);
    } else {
      toast.error(error.response.data.message);
    }
    // second error, error 422, email already used
    // if (error.response.status === 422) {
    //   toast.error(error.response.data?.error)
    // } else {
    //   console.log("error", error);
    // }
    return error;
  }
);

const api = {
  contactUs: async (postBody) => {
    return await axios.post(routes["contact-us"](), postBody);
  },
  customer: {
    profile: {
      get: async () => {
        return await axios.get(routes.customer.profile["get-details"]());
      },
      updateProfile: async (postBody) => {
        return await axios.post(
          routes.customer.profile["update-profile"](),
          postBody
        );
      },
      getTransactions: async () => {
        return await axios.get(routes.customer.profile["get-transactions"]());
      },
    },
    products: {
      getProducts: async () => {
        return await axios.get(routes.customer.products["get-allProducts"]());
      },
      getFilteredProducts: async (queryParams) => {
        return await axios.get(
          routes.customer.products["get-allFilteredProducts"](),
          { params: { ...queryParams } }
        );
      },
      getProduct: async (id) => {
        return await axios.get(routes.customer.products["get-Product"](id));
      },
      getAllApprovedProducts: async () => {
        return await axios.get(
          routes.customer.products["get-allApprovedProducts"]()
        );
      },
      productsByCategory: async (id) => {
        return await axios.get(
          routes.customer.products["product-by-category"](id)
        );
      },
    },
    vendor: {
      getVendors: async () => {
        return await axios.get(routes.customer.vendors["get-allVendors"]());
      },
      getVendorProducts: async (id) => {
        return await axios.get(
          routes.customer.vendors["get-Vendor-products"](id)
        );
      },
      getVendor: async (id) => {
        return await axios.get(routes.customer.vendors["get-Vendor"](id));
      },
      getActiveVendors: async () => {
        return await axios.get(
          routes.customer.vendors["get-allActiveVendors"]()
        );
      },
      getInactiveVendors: async () => {
        return await axios.get(
          routes.customer.vendors["get-allInactiveVendors"]()
        );
      },
      vendorProductCount: async (id) => {
        return await axios.get(
          routes.customer.vendors["get-vendorProductCount"](id)
        );
      },
    },
    brand: {
      getBrands: async () => {
        return await axios.get(routes.customer.brand["get-brands"]());
      },
      getBrand: async (id) => {
        return await axios.get(routes.customer.brand["get-brand"](id));
      },
    },
    category: {
      getCategories: async () => {
        return await axios.get(routes.customer.category["get-categories"]());
      },
      getCategory: async (id) => {
        return await axios.get(routes.customer.category["get-category"](id));
      },
    },
    subcategory: {
      getSubcategories: async () => {
        return await axios.get(
          routes.customer.subcategory["get-subcategories"]()
        );
      },
      getCategory: async (id) => {
        return await axios.get(
          routes.customer.subcategory["get-subcategory"](id)
        );
      },
    },
    cart: {
      addToCart: async (id, postBody) => {
        return await axios.post(
          routes.customer.cart["add-to-cart"](id),
          postBody,
          {
            withCredentials: true,
          }
        );
      },
      getCart: async () => {
        return await axios.get(routes.customer.cart["get-cart"](), {
          withCredentials: true,
        });
      },
      getCount: async () => {
        return await axios.get(routes.customer.cart["get-count"](), {
          withCredentials: true,
        });
      },
      deleteCartItem: async (id) => {
        return await axios.post(
          routes.customer.cart["delete-cart-item"](id),
          {},
          {
            withCredentials: true,
          }
        );
      },
      cartIncrement: async (id) => {
        return await axios.post(
          routes.customer.cart["cart-increment"](id),
          {},
          {
            withCredentials: true,
          }
        );
      },
      cartDecrement: async (id) => {
        return await axios.post(
          routes.customer.cart["cart-decrement"](id),
          {},
          {
            withCredentials: true,
          }
        );
      },
    },
    wishlist: {
      addToWishlist: async (postBody, id) => {
        return await axios.post(
          routes.customer.wishlist["add-to-wishlist"](id),
          postBody
        );
      },
      getWishlist: async () => {
        return await axios.get(routes.customer.wishlist["get-wishlist"]());
      },
      deleteWishlistItem: async (id) => {
        return await axios.delete(
          routes.customer.wishlist["delete-wishlist-item"](id)
        );
      },
    },
    watchlist: {
      addToWatchlist: async (id, postBody) => {
        return await axios.post(
          routes.customer.watchlist["add-to-watchlist"](id),
          postBody
        );
      },
      getWatchlist: async () => {
        return await axios.get(routes.customer.watchlist["get-watchlist"]());
      },
      deleteWatchlistItem: async (id) => {
        return await axios.delete(
          routes.customer.watchlist["delete-watchlist-item"](id)
        );
      },
    },
    resetPassword: async (postBody) => {
      return await axios.post(routes.customer["reset-password"](), postBody);
    },
    activateResetPassword: async (postBody) => {
      return await axios.post(
        routes.customer["activate-resetPassword"](),
        postBody
      );
    },
    getVendors: async () => {
      return await axios.get(routes.customer["get-vendors"]());
    },
    raiseClaim: async (postBody) => {
      return await axios.post(routes.customer.claim["raise-claim"](), postBody);
    },
    getClaim: async () => {
      return await axios.get(routes.customer.claim["view-claim"]());
    },
    checkout: {
      checkout: async (postBody) => {
        return await axios.post(routes.customer.checkout.checkout(), postBody, {
          withCredentials: true,
        });
      },
      addCoupon: async (postBody) => {
        return await axios.post(
          routes.customer.checkout["add-coupon"](),
          postBody
        );
      },
      calcCoupon: async () => {
        return await axios.get(routes.customer.checkout["get-coupon-calc"]());
      },
      removeCoupon: async () => {
        return await axios.get(routes.customer.checkout["remove-coupon"]());
      },
      pickupPayment: async (postBody) => {
        return await axios.post(
          routes.customer.checkout["pickup-payment"](),
          postBody,
          {
            withCredentials: true,
          }
        );
      },
      confirmPickupPayment: async (postBody) => {
        return await axios.post(
          routes.customer.checkout["confirm-pickup-payment"](),
          postBody,
          {
            withCredentials: true,
          }
        );
      },
    },
    bidding: {
      makeABid: async (postBody) => {
        return await axios.post(
          routes.customer.bidding["make-bid"](),
          postBody
        );
      },
      getAllAuctions: async () => {
        return await axios.get(routes.customer.bidding["get-all-auctions"]());
      },
      getAllBiddings: async () => {
        return await axios.get(routes.customer.bidding["get-all-biddings"]());
      },
      getSingleAuction: async (id) => {
        return await axios.get(routes.customer.bidding["get-auction"](id));
      },
      getCurrentBid: async (id) => {
        return await axios.get(routes.customer.bidding["get-current-bid"](id));
      },
    },
  },
  vendor: {
    profile: {
      get: async () => {
        return await axios.get(routes.vendor.profile["get-details"]());
      },
      updateProfile: async (postBody) => {
        return await axios.post(
          routes.vendor.profile["update-profile"](),
          postBody
        );
      },
    },
    brand: {
      createBrand: async (postBody) => {
        return await axios.post(
          routes.vendor.brand["create-brand"](),
          postBody
        );
      },
      getBrand: async (id) => {
        return await axios.get(routes.vendor.brand["get-brand"](id));
      },
      getBrands: async () => {
        return await axios.get(routes.vendor.brand["get-all-brands"]());
      },
      updateBrand: async (id, postBody) => {
        return await axios.post(
          routes.vendor.brand["update-brand"](id),
          postBody
        );
      },
      deleteBrand: async (id) => {
        return await axios.delete(routes.vendor.brand["delete-brand"](id));
      },
    },
    category: {
      createCategory: async (postBody) => {
        return await axios.post(
          routes.vendor.category["create-category"](),
          postBody
        );
      },
      getCategories: async () => {
        return await axios.get(routes.vendor.category["get-categories"]());
      },
      getCategory: async (id) => {
        return await axios.get(routes.vendor.category["get-category"](id));
      },
      updateCategory: async (id, postBody) => {
        return await axios.post(
          routes.vendor.category["update-category"](id),
          postBody
        );
      },
      deleteCategory: async (id) => {
        return await axios.delete(
          routes.vendor.category["delete-category"](id)
        );
      },
    },
    subcategory: {
      createSubcategory: async (postBody) => {
        return await axios.post(
          routes.vendor.subcategory["create-subcategory"](),
          postBody
        );
      },
      getSubcategories: async () => {
        return await axios.get(
          routes.vendor.subcategory["get-subcategories"]()
        );
      },
      getSubcategory: async (id) => {
        return await axios.get(
          routes.vendor.subcategory["get-subcategory"](id)
        );
      },
      updateSubcategory: async (id, postBody) => {
        return await axios.post(
          routes.vendor.subcategory["update-subcategory"](id),
          postBody
        );
      },
      deleteSubcategory: async (id) => {
        return await axios.delete(
          routes.vendor.subcategory["delete-subcategory"](id)
        );
      },
    },
    products: {
      createProduct: async (postBody) => {
        return await axios.post(
          routes.vendor.product["create-product"](),
          postBody
        );
      },
      getProducts: async () => {
        return await axios.get(routes.vendor.product["get-products"]());
      },
      getProduct: async (id) => {
        return await axios.get(routes.vendor.product["get-product"](id));
      },
      editProduct: async (id, postBody) => {
        return await axios.post(
          routes.vendor.product["edit-product"](id),
          postBody
        );
      },
      deleteProduct: async (id) => {
        return await axios.delete(routes.vendor.product["delete-product"](id));
      },
      getApprovedProducts: async () => {
        return await axios.get(
          routes.vendor.product["get-allApprovedProducts"]()
        );
      },
      getUnapprovedProducts: async () => {
        return await axios.get(
          routes.vendor.product["get-allUnapprovedProducts"]()
        );
      },
    },
    resetPassword: async (postBody) => {
      return await axios.post(routes.vendor["reset-password"](), postBody);
    },
    activateResetPassword: async (postBody) => {
      return await axios.post(
        routes.vendor["activate-resetPassword"](),
        postBody
      );
    },
    auction: {
      createAuction: async (postBody) => {
        return await axios.post(
          routes.vendor.auction["create-auction"](),
          postBody
        );
      },
      getAllAuctions: async () => {
        return await axios.get(routes.vendor.auction["get-all-auctions"]());
      },
    },
  },
  admin: {
    brand: {
      createBrand: async (postBody) => {
        return await axios.post(routes.admin.brand["create-brand"](), postBody);
      },
      getBrand: async (id) => {
        return await axios.get(routes.admin.brand["get-brand"](id));
      },
      getBrands: async () => {
        return await axios.get(routes.admin.brand["get-all-brands"]());
      },
      updateBrand: async (id, postBody) => {
        return await axios.post(
          routes.admin.brand["update-brand"](id),
          postBody
        );
      },
      deleteBrand: async (id) => {
        return await axios.delete(routes.admin.brand["delete-brand"](id));
      },
    },
    category: {
      createCategory: async (postBody) => {
        return await axios.post(
          routes.admin.category["create-category"](),
          postBody
        );
      },
      getCategories: async () => {
        return await axios.get(routes.admin.category["get-categories"]());
      },
      getCategory: async (id) => {
        return await axios.get(routes.admin.category["get-category"](id));
      },
      updateCategory: async (id, postBody) => {
        return await axios.post(
          routes.admin.category["update-category"](id),
          postBody
        );
      },
      deleteCategory: async (id) => {
        return await axios.delete(routes.admin.category["delete-category"](id));
      },
    },
    subcategory: {
      createSubcategory: async (postBody) => {
        return await axios.post(
          routes.admin.subcategory["create-subcategory"](),
          postBody
        );
      },
      getSubcategories: async () => {
        return await axios.get(routes.admin.subcategory["get-subcategories"]());
      },
      getSubcategory: async (id) => {
        return await axios.get(routes.admin.subcategory["get-subcategory"](id));
      },
      updateSubcategory: async (id, postBody) => {
        return await axios.post(
          routes.admin.subcategory["update-subcategory"](id),
          postBody
        );
      },
      deleteSubcategory: async (id) => {
        return await axios.delete(
          routes.admin.subcategory["delete-subcategory"](id)
        );
      },
    },
    profile: {
      get: async () => {
        return await axios.get(routes.admin.profile["get-details"]());
      },
      updateProfile: async (postBody) => {
        return await axios.post(
          routes.admin.profile["update-profile"](),
          postBody
        );
      },
    },
    vendor: {
      getActive: async () => {
        return await axios.get(routes.admin.vendor["get-active-vendors"]());
      },
      getInActive: async () => {
        return await axios.get(routes.admin.vendor["get-inactive-vendors"]());
      },
      activateVendor: async (vendorId) => {
        return await axios.post(
          routes.admin.vendor["activate-vendor"](vendorId)
        );
      },
      deactivateVendor: async (vendorId) => {
        return await axios.post(
          routes.admin.vendor["deactivate-vendor"](vendorId)
        );
      },
    },
    products: {
      createProduct: async (postBody) => {
        return await axios.post(
          routes.admin.product["create-product"](),
          postBody
        );
      },
      getProducts: async () => {
        return await axios.get(routes.admin.product["get-products"]());
      },
      getProduct: async (id) => {
        return await axios.get(routes.admin.product["get-product"](id));
      },
      editProduct: async (id, postBody) => {
        return await axios.post(
          routes.admin.product["edit-product"](id),
          postBody
        );
      },
      deleteProduct: async (id) => {
        return await axios.delete(routes.admin.product["delete-product"](id));
      },
      getApprovedProducts: async () => {
        return await axios.get(
          routes.admin.product["get-allApprovedProducts"]()
        );
      },
      getUnapprovedProducts: async () => {
        return await axios.get(
          routes.admin.product["get-allUnapprovedProducts"]()
        );
      },
      activateProduct: async (id) => {
        return await axios.post(routes.admin.product["activate-product"](id));
      },
    },
    coupon: {
      createCoupon: async (postBody) => {
        return await axios.post(
          routes.admin.coupon["create-coupon"](),
          postBody
        );
      },
      deleteCoupon: async (id) => {
        return await axios.delete(routes.admin.coupon["delete-coupon"](id));
      },
      getAllCoupons: async () => {
        return await axios.get(routes.admin.coupon["get-all-coupons"]());
      },
      updateCoupon: async (id, postBody) => {
        return await axios.post(
          routes.admin.coupon["update-coupon"](id),
          postBody
        );
      },
    },
    sliders: {
      createSlider: async (postBody) => {
        return await axios.post(
          routes.admin.sliders["create-slider"](),
          postBody
        );
      },
      deleteSlider: async (id) => {
        return await axios.delete(routes.admin.sliders["delete-slider"](id));
      },
      getAllSliders: async () => {
        return await axios.get(routes.admin.sliders["get-all-sliders"]());
      },
      updateSlider: async (id, postBody) => {
        return await axios.post(
          routes.admin.sliders["update-slider"](id),
          postBody
        );
      },
    },
    claims: {
      viewClaim: async (id) => {
        return await axios.get(routes.admin.claims["view-claim"](id));
      },
      openClaim: async (id) => {
        return await axios.get(routes.admin.claims["open-claim"](id));
      },
      closeClaim: async (id) => {
        return await axios.get(routes.admin.claims["close-claim"](id));
      },
    },
    transactions: {
      getAllTransactions: async () => {
        return await axios.get(
          routes.admin.transactions["get-all-transactions"]()
        );
      },
    },
    bidding: {
      getAllBiddingHistory: async () => {
        return await axios.get(
          routes.admin.bidding["get-all-bidding-history"]()
        );
      },
      getAllAuctions: async () => {
        return await axios.get(routes.admin.bidding["get-all-auctions"]());
      },
    },
  },
};

export default api;
