import React from "react";
import { FaDownload } from "react-icons/fa";
import { formatPrice } from "./FormatPrice";
import EBN from "../assets/editedebn.png";

const Receipt = ({ transactionDetails, runDownload, targetRef }) => {
 console.log(transactionDetails)

 const containerStyle = {
  backgroundColor: "#fff",
  boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
  padding: "20px",
  maxWidth: "90%",
  margin: "0 auto",
  borderRadius: "8px",
  position: "absolute",
  width: "100%",
 };

 const headingStyle = {
  fontSize: "2.5rem",
  fontWeight: "bold",
  marginBottom: "1rem",
  textAlign: "center",
 };

 const paragraphStyle = {
  fontSize: "1.2rem",
  color: "#4B5563",
  marginBottom: "0.5rem",
 };

 const itemHeadingStyle = {
  fontSize: "1.4rem",
  fontWeight: "bold",
  marginBottom: "0.75rem",
 };

 const listItemStyle = {
  display: "flex",
  flexDirection: "row",
  gap: "20px",
  justifyContent: "space-between",
  borderBottom: "2px solid gray",
  marginBottom: "20px",
  paddingBottom: "10px",
  width: "400px",
 };

 const productsStyle = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  flexWrap: "wrap",
  gap: "20px",
  margin: "50px 0 auto auto",
 };

 const totalStyle = {
  fontSize: "1.25rem",
  fontWeight: "bold",
  color: "#10B981",
 };

 return (
  <div style={containerStyle} ref={targetRef}>
   <img
    src={EBN}
    alt="logo"
    style={{ margin: "10px auto", display: "flex", width: "250px" }}
   />
   <h2 style={headingStyle}>Receipt</h2>
   <div style={{ marginBottom: "1rem" }}>
    <p style={paragraphStyle}>
     Date:{" "}
     <span style={{ fontWeight: "bold" }}>
      {transactionDetails?.transactionDate}
     </span>
    </p>
    <p style={paragraphStyle}>
     Transaction ID:{" "}
     <span style={{ fontWeight: "bold" }}>
      {transactionDetails.transactionId}
     </span>
    </p>
    <p style={paragraphStyle}>
     Product Quantity:{" "}
     <span style={{ fontWeight: "bold" }}>
      {/* {transactionDetails.cart.quantity} */}
     </span>
    </p>
    <p style={paragraphStyle}>
     Delivery price:{" "}
     <span style={{ fontWeight: "bold" }}>
      {transactionDetails.cart.deliveryPrice}
     </span>
    </p>
    {/* <p style={paragraphStyle}>
     Coupon applied:{" "}
     <span style={{ fontWeight: "bold" }}>
      {transactionDetails.cart.appliedCoupon === null
       ? "no coupon"
       : `${transactionDetails.cart.appliedCoupon.percentage}% discount`}
     </span>
    </p> */}
   </div>
   <div>
    <h3 style={itemHeadingStyle}>Products:</h3>
    <ul style={productsStyle}>
     {transactionDetails.cart.cartItems.map((item, index) => (
      <li key={index} style={listItemStyle}>
       <div style={{ position: "relative", width: "20%" }}>
        <img
         src={item.image}
         alt={item.product_name}
         className="w-[80px] h-[80px]"
        />
        <small
         style={{
          position: "absolute",
          top: "0",
          backgroundColor: "gray",
          padding: "2px 3px",
          borderRadius: "1000px",
         }}
        >
         {item.quantity}
        </small>
       </div>
       <div
        style={{
         display: "flex",
         flexDirection: "column",
         gap: "10px",
         width: "80%",
         marginRight: "0px",
        }}
       >
        <span style={{ width: "95%", fontSize: "17px" }}>
         {item.product_name}
        </span>
        <span style={{ fontWeight: "bolder" }}>₦{formatPrice(item.price)}</span>
       </div>
      </li>
     ))}
    </ul>
   </div>
   <div style={{ marginTop: "1rem" }}>
    <p style={totalStyle}>
     Total:{" "}
     <span style={{ color: "#059669" }}>
      ₦{formatPrice(transactionDetails.cart.totalPrice)}
     </span>
    </p>
   </div>
   <button onClick={runDownload}>
    <FaDownload />
   </button>
  </div>
 );
};

export default Receipt;
