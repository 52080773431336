/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import api from "../../../services/api";
import { toast } from "react-toastify";
import Loading from '../../../components/Loading'
const Sliders = () => {
  const [showForm, setShowForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [isUpdating, setIsUpdating] = useState(false)
  const [sliders, setSliders] = useState([]);
  const [slider, setSlider] = useState({
    slider_title: '',
    short_title: "",
    slider_image: null,
  });

  const showCouponForm = () => {
    setShowForm(!showForm);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSlider({
      ...slider,
      [name]: value,
    });
  };

  const createSlider = () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("slider_title", slider.slider_title);
    formData.append("short_title", slider.short_title);
    formData.append("slider_image", slider.slider_image);

    if (isUpdating) {
      const sliderToUpdate = sliders.find((s) => s.slider_title === slider.slider_title && s.short_title === slider.short_title);
      if (sliderToUpdate) {
        api.admin.sliders.updateSlider(sliderToUpdate.id, formData).then((res) => {
          toast.success(res.data.message);
          setSlider({
            slider_title: '',
            short_title: "",
            slider_image: null,
          });
        })
        .catch((res) => console.log(res))
        .finally(() => setIsLoading(false));
          
      }
    } else {
      api.admin.sliders.createSlider(formData)
        .then((res) => {
          toast.success(res.data.message);
          setSlider({
            slider_title: '',
            short_title: "",
            slider_image: null,
          });
        })
        .catch((res) => console.log(res))
        .finally(() => setIsLoading(false));
    }
  };

  const deleteSlider = (id) => {
    api.admin.sliders.deleteSlider(id)
      .then((res) => console.log(res))
      .catch((res) => console.log(res)).finally(()=>setIsLoading(false));
  };

  const editSlider = (slider) => {
    setSlider({
      slider_title: slider.slider_title,
      short_title: slider.short_title,
      slider_image: slider.slider_image,
    });
    setIsUpdating(true);
    setShowForm(true);
  };

  useEffect(()=>{
    api.admin.sliders.getAllSliders().then(res=>{console.log(res); setSliders([])}).catch(res=>console.log(res)).finally(()=>setIsLoading(false))
  },[isLoading])

  const today = new Date().toISOString().split('T')[0]
  // Get tomorrow's date in YYYY-MM-DD format
  // const today = new Date();
  // const tomorrow = new Date(today);
  // tomorrow.setDate(tomorrow.getDate() + 1);
  // const minDate = tomorrow.toISOString().split('T')[0];

  return (
    <section>
      <h1 className="roleHeaders">Sliders</h1>
      <div className="my-4 flex flex-col gap-4">
        <button type="button" className="roleBtn ml-auto" onClick={showCouponForm}>
          Add Slider
        </button>
        {showForm && (
          <div className="py-2 px-3 my-4 bg-white  ">
            <form className="grid grid-cols-2 gap-3 justify-start items-center my-4">
              <article className="couponArticle">
                <label htmlFor="slider_title">Slider Title</label>
                <input
                  type="text"
                  name="slider_title"
                  id="slider_title"
                  className="couponInput"
                  onChange={handleChange}
                  value={slider.slider_title}
                />
              </article>
              <article className="couponArticle">
                <label htmlFor="short_title">Slider short description</label>
                <textarea
                  type="text"
                  name="short_title"
                  id="short_title"
                  className="couponInput"
                  onChange={handleChange}
                  value={slider.short_title}
                />
              </article>
              <article className="couponArticle">
                <label htmlFor="slider_image">Slider Image</label>
                <input
                  type="file"
                  name="slider_image"
                  id="slider_image"
                  className="couponInput"
                  onChange={handleChange}
                  value={slider.slider_image}
                />
                {
                  slider.slider_image && <img src={slider.slider_image} alt="" className="w-[100px] h-[100px]"/>
                }
              </article>
            </form>
            <button type="button" onClick={createSlider} className="submitBtn" disabled={isLoading}>
            {isLoading ? <Loading className={'w-[30px]'} /> : isUpdating ? 'Update Slider' : 'Create Slider'}
            </button>
          </div>
        )}
      </div>
      <div className="bg-white p-4">
        <h2 className="roleHeaders">All Sliders</h2>
        <section>
          <div className="flex justify-end m-3">
            <input
              type="text"
              name="couponSearch"
              id="couponSearch"
              placeholder="Search for Sliders here"
              className="roleSearch"
            />
          </div>
          <table className="min-w-full divide-y divide-gray-200 border">
            <thead>
              <tr className="tableHeadRow">
                <th className="tableHead">S/N</th>
                <th className="tableHead">Slider Title</th>
                <th className="tableHead">Short description</th>
                <th className="tableHead">Slider image</th>
                <th className="tableHead">Action</th>
              </tr>
            </thead>
            <tbody>
              {sliders.length > 0 ? (
                sliders.map((slider, index) => {
                  return (
                    <tr className="py-[10px] tableRow" key={slider.id}>
                      <td>{index + 1}</td>
                      <td className="px-6 py-4 whitespace-nowrap">{slider.slider_title}</td>
                      <td>{slider.short_title}%</td>
                      <td>{slider.slider_image}</td>
                      <td>
                        <div className="flex gap-2 justify-center">
                          <button type="button" className="p-2 text-white text-sm rounded-sm bg-red-700" onClick={() => deleteSlider(slider.id)}>Delete</button>
                          <button type="button" className="p-2 text-white text-sm rounded-sm bg-green-600" onClick={() => editSlider(slider)}>Update</button>
                          </div>
                        </td>
                    </tr>
                  );
                })
              ) : (
                <div className="flex justify-center align-center">
                  <Loading />
                </div>
              )}
            </tbody>
          </table>
        </section>
      </div>
    </section>
  );
};

export default Sliders;
