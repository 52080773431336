/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { Routes, Route, Navigate, Outlet } from "react-router-dom";
import ContactUs from "./Pages/ContactUs";
import Products from "./Pages/Products";
import { Navbar } from "./components/Navbar";
import { Footer } from "./components/Footer";
import LandingPage from "./Pages/LandingPage";
import Login from "./Pages/LoginPage";
import ErrorPage from "./Pages/ErrorPage";
import Cart from "./Pages/Cart";
import Wishlist from "./Pages/Wishlist";
import SignUp from "./Pages/SignUp";
import SingleProduct from "./Pages/SingleProduct";
import MyAccount from "./Pages/Account";
import { useSelector } from "react-redux";
import VendorView from "./Pages/Role/VendorD";
import SideBar from "./components/SideBar";
import CheckoutPage from "./Pages/CheckoutPage";
import NewNavbar from "./components/Role/Navbar";
import AdminDashboard from "./Pages/Role/AdminD";
import AllBrands from "./Pages/Role/Admin/AllBrands";
import AddBrand from "./Pages/Role/Admin/AddBrand";
import AllProducts from "./Pages/Role/Admin/AllProducts";
import AddProduct from "./Pages/Role/Admin/AddProduct";
import AllCategories from "./Pages/Role/Admin/AllCategories";
import AddCategory from "./Pages/Role/Admin/AddCategory";
import AllVendors from "./Pages/Role/Admin/AllVendors";
import SubCategories from "./Pages/Role/Admin/SubCategories";
import AddSubCategory from "./Pages/Role/Admin/AddSubCategory";
import Vendors from "./Pages/Vendors";
import SingleVendor from "./Pages/SingleVendor";
import Profile from "./Pages/Role/Profile";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Auctions from "./Pages/Auctions";
import AuctionedProducts from "./Pages/Role/Admin/AuctionedProducts";
import TransactionHistory from "./Pages/TransactionHistory";
import AccountProfile from "./Pages/AccountProfile";
import BiddingHistory from "./Pages/BiddingHistory";
import Coupon from "./Pages/Role/Admin/Coupon";
import RaiseClaim from "./Pages/RaiseClaim";
import OrderTracking from "./Pages/OrderTracking";
import SingleAuction from "./Pages/SingleAuction";
import CreateAuction from "./Pages/Role/Admin/CreateAuction";
import Claims from "./Pages/Role/Admin/Claims";
import ForgotPassword from "./Pages/ForgotPassword";
import ResetPassword from "./Pages/ResetPassword";
import ReportedIssues from "./Pages/ReportedIssues";
import ScrollToTop from "./components/ScrollToTop";
import ProductsByCategory from "./Pages/ProductsByCategory";
import Sliders from "./Pages/Role/Admin/Sliders";
import Watchlist from "./Pages/Watchlist";
import DashboardContent from "./Pages/Role/NewDashboard";

const App = () => {
  const [userRole, setUserRole] = useState("unauth");
  const targetRef = useRef(null)

  const { user } = useSelector((store) => store.auth);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    user ? setUserRole(user.role) : setUserRole("unauth");
  }, [user]);

  useEffect(() => {
    targetRef.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  const renderLayout = () => {
    if (userRole === "unauth") {
      return (
        <>
          <Navbar />
          <div>
            <Routes>
              <Route path="*" element={<ErrorPage />} />
              <Route path="/" element={<LandingPage />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<SignUp />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route exact path="/products" element={<Products />} />
              <Route exact path="/products/:search" element={<Products />} />
              <Route path='/products/categories/:categoryName/:categoryId' element={<ProductsByCategory />} />
              <Route exact path="/products/:slug/:productId" element={<SingleProduct />} />
              <Route exact path="/vendors" element={<Vendors />} />
              <Route exact path="/vendor/:vendorName/:vendorId" element={<SingleVendor />} />
              <Route path="/cart" element={<Cart />} />
              <Route path="/wishlist" element={<Wishlist />} />
              <Route path="/auctions" element={<Auctions />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/reset-password/:token/:email" element={<ResetPassword />} />
              {/* <Route element={user ? <Outlet /> : <Navigate to={'/login'}/>}>
                   <Route path='/auctions/auction-:id' element={<SingleAuction />} />
                   </Route> */}
            </Routes>
          </div>
          <Footer />
        </>
      );
    } else if (userRole === "user") {
      return (
        <>
          <Navbar />
          <div>
            <Routes>
              <Route path="*" element={<ErrorPage />} />
              <Route path="/" element={<LandingPage />} />
              <Route path="/login" element={<Login />} />
              <Route path="/register" element={<SignUp />} />
              <Route path="/contact-us" element={<ContactUs />} />
              <Route exact path="/products" element={<Products />} />
              <Route exact path="/products/:search" element={<Products />} />
              <Route path='/products/categories/:categoryName/:categoryId' element={<ProductsByCategory />} />
              <Route exact path="/products/:slug/:productId" element={<SingleProduct />} />
              <Route exact path="/vendors" element={<Vendors />} />
              <Route exact path="/vendor/:vendorName/:vendorId" element={<SingleVendor />} />
              <Route path="/cart" element={<Cart />} />
              <Route path="/wishlist" element={<Wishlist />} />
              <Route path="/watchlist" element={<Watchlist />} />
              <Route path="/auctions" element={<Auctions />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route element={user ? <Outlet /> : <Navigate to={"/login"} />}>
                <Route path="/order-tracking" element={<OrderTracking />} />
                <Route path="/auctions/auction/:id" element={<SingleAuction />} />
                <Route path="/myaccount/profile" element={<MyAccount />}>
                  <Route index element={<AccountProfile />} />
                  <Route path="transaction-history" element={<TransactionHistory />} />
                  <Route path="bidding-history" element={<BiddingHistory />} />
                  <Route path="raise-claim" element={<RaiseClaim />} />
                  <Route path="issues" element={<ReportedIssues />} />
                </Route>
                <Route path="/checkout" element={<CheckoutPage />} />
              </Route>
            </Routes>
          </div>
          <Footer />
        </>
      );
    } else if (userRole === "vendor" || "admin") {
      return (
        <>
          <NewNavbar toggleSidebar={toggleSidebar} />
          <main className="flex flex-row w-full h-full bg-gray-200">
            <SideBar isOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen}/>
            <section
              className={`w-${isSidebarOpen ? "4/5" : "full"
                } p-4 w-full transition-all duration-300 h-full`}
            >
              <Routes>
                {userRole === "vendor" && (
                  // <Route path={`${userRole}/dashboard`} element={<VendorView />} />
                  <Route path={`${userRole}/dashboard`} element={<DashboardContent />} />
                )}
                {userRole === "admin" && (
                  <Route path={`${userRole}/dashboard`} element={<AdminDashboard />} />
                )}
                <Route path={`admin/allBrands`} element={<AllBrands />} />
                <Route path={`admin/addBrand`} element={<AddBrand />} />
                <Route path={`admin/updateBrand/:id`} element={<AddBrand />} />
                <Route path={`${userRole}/allProducts`} element={<AllProducts />} />
                <Route path={`${userRole}/addProduct`} element={<AddProduct />} />
                <Route
                  path={`${userRole}/updateProduct/:id`}
                  element={<AddProduct />}
                />
                <Route path={`admin/allCategories`} element={<AllCategories />} />
                <Route path={`admin/addCategory`} element={<AddCategory />} />
                <Route
                  path={`admin/updateCategory/:id`}
                  element={<AddCategory />}
                />
                <Route
                  path={`admin/allSubCategories`}
                  element={<SubCategories />}
                />
                <Route
                  path={`admin/addSubCategory`}
                  element={<AddSubCategory />}
                />
                <Route
                  path={`admin/updateSubCategory/:id`}
                  element={<AddSubCategory />}
                />
                <Route path={`${userRole}/profile`} element={<Profile />} />
                <Route path={`${userRole}/auctioned`} element={<AuctionedProducts />} />
                <Route path={`vendor/create-auction/:id`} element={<CreateAuction />} />
                <Route path="admin/coupons" element={<Coupon />} />
                <Route path="admin/sliders" element={<Sliders />} />
                <Route path="admin/claims" element={<Claims />} />
                <Route path="admin/all-vendors" element={<AllVendors />} />
              </Routes>
            </section>
          </main>
        </>
      );
    }
  };
  return (
    <div className="max-w-[1700px] mx-auto" ref={targetRef}>
      <ScrollToTop />
      {renderLayout()}
      <ToastContainer autoClose={2000} />
    </div>
  );
};

export default App;
