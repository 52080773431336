import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import api from "../../../services/api";
import { useState } from "react";
import { toast } from "react-toastify";
import Loading from "../../../components/Loading";
import ReactPaginate from "react-paginate";
import Modal from "../../../components/Cards/Modal";
import Danger from '../../../assets/danger-sign.png'


const AllCategories = () => {
  const [categories, setCategories] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);


  // pagination
  const itemsPerPage = 7;
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };
  const pageCount = Math.ceil(categories.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = categories.slice(startIndex, endIndex);

  const { user } = useSelector((store) => store.auth);
  const role = user.role;
  const navigate = useNavigate();

  const openModal = (id) => {
    setDeleteId(id)
    setIsModalOpen(true);
  };

  useEffect(() => {
    setLoading(true);
    if (role === "vendor") {
      api.vendor.category
        .getCategories()
        .then((res) => {
          console.log(res);
          setCategories(res.data?.categories);
        })
        .catch((res) => console.log(res))
        .finally(() => setLoading(false));
    } else {
      api.admin.category
        .getCategories()
        .then((res) => {
          setCategories(res.data?.categories);
        })
        .catch((res) => console.log(res))
        .finally(() => setLoading(false));
    }
  }, [role]);

  const deleteCategory = (id) => {
    setLoading(true);
    if (role === "vendor") {
      api.vendor.category
        .deleteCategory(id)
        .then((res) => {
          toast.success(res.data.message, { autoClose: 1000 });
          setCategories((prevCategories) =>
            prevCategories.filter((category) => category.id !== id)
          );
        })
        .catch((res) => console.log(res))
        .finally(() => setLoading(false));
    } else {
      api.admin.category
        .deleteCategory(id)
        .then((res) => {
          toast.success(res.data.message, { autoClose: 1000 });
          setCategories((prevCategories) =>
            prevCategories.filter((category) => category.id !== id)
          );
        })
        .catch((res) => console.log(res))
        .finally(() => setLoading(false));
    }
  };

  const filteredCategories = paginatedData.filter((category) => {
    return category.category_name
      .toLowerCase()
      .includes(searchInput.toLowerCase());
  });

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  return (
    <main>
      <section className="topSection">
        <h2 className="roleHeaders">All Categories</h2>
        <Link to={`/${role}/addCategory`} className="roleBtn">
          Add Category
        </Link>
      </section>

      <section className="bg-white flex flex-col gap-3 py-4">
        <div className="flex justify-end m-3">
          <input
            type="text"
            name="brandSearch"
            id="brandSearch"
            placeholder="Search for Categories here"
            className="roleSearch"
            value={searchInput}
            onChange={handleSearchInputChange}
          />
        </div>

        <div className="px-3 overflow-x-auto">
          {loading ? (
            <div className="flex justify-center">
              <Loading className={"w-[40px]"} />
            </div>
          ) : (
            <div>
              <table className="min-w-full divide-y divide-gray-200 py-[30px">
                <thead>
                  <tr className="tableHeadRow">
                    <th className="tableHead">S/N</th>
                    <th className="tableHead">Category Name</th>
                    <th className="tableHead">Category Image</th>
                    <th className="tableHead">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {categories ? (
                    filteredCategories.map((category, idx) => {
                      return (
                        <tr key={category.category_slug} className="tableRow">
                          <td>{idx + 1}</td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {category.category_name}
                          </td>
                          <td>
                            <img
                              src={
                                "https://ebn.itl.ng/upload/category/" +
                                category.category_image
                              }
                              alt={category.category_slug}
                              className="w-[50px] rounded-full object-fill mx-auto"
                            />
                          </td>
                          <td>
                            <div className="flex gap-4 items-center justify-center">
                              <button
                                type="button"
                                className="actionBtn bg-green-600"
                                onClick={() =>
                                  navigate(
                                    `/${role}/updateCategory/${category.id}`
                                  )
                                }
                              >
                                Edit
                              </button>
                              <button
                                type="button"
                                className="actionBtn bg-red-600"
                                onClick={() => openModal(category.id)}
                              >
                                Delete
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <h1>Loading....</h1>
                  )}
                </tbody>
              </table>
              <div className="my-8">
                <ReactPaginate
                  previousLabel={"⇽"}
                  nextLabel={"⇾"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageChange}
                  containerClassName="paginateContainer"
                  pageClassName="cursor-pointer py-1 px-2 rounded-full bg-gray-200 font-bold hover:bg-green-500 hover:text-white"
                  previousClassName="nextPrev"
                  nextClassName="nextPrev"
                  activeClassName="bg-green-500 text-white"
                />
              </div>
            </div>
          )}
        </div>
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <div className='flex flex-col items-center gap-4'>
            <img src={Danger} alt="" className='w-[100px]' />
            <h2 className='text-2xl'>Are you sure you want to delete?</h2>
            <div className='flex  gap-4 items-center'>
              <button type="button" className='bg-green-600 px-4 rounded-md py-1 text-white font-medium' onClick={() => { deleteCategory(deleteId)}}>Yes</button>
              <button type="button" className='bg-red-600 px-4 rounded-md py-1 text-white font-medium' onClick={() => setIsModalOpen(false)}>No</button>
            </div>
          </div>
        </Modal>
      </section>
    </main>
  );
};

export default AllCategories;
