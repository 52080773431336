import React from "react";
import styled from "styled-components";
// import offerpics from "../../assets/offerpics.JPG";
import Offers from "../Offers";

const Deals = ({ products }) => {
  const hotDealsExist = products.some((pro) => pro.hot_deals === "1");
  const specialDealsExist = products.some((pro) => pro.special_offer === "1");
  const featuredDealsExist = products.some((pro) => pro.featured === "1" === "1");
 return (
  <Container>
  { hotDealsExist &&<div className="hot">
    <h3>hot deals</h3>
    <hr />

    <div className="deals">
     {products
      .filter((pro) => pro.hot_deals === "1")
      .map((val, idx) => {
       return (
        <Offers
         key={idx}
         id={val.id}
         image={
          "https://ebn.ng/public/upload/products/thumbnail/" +
          val.product_thumbnail
         }
         product_name={val.product_name}
         price={val.selling_price}
         rating={"3"}
         discount_price={val.discount_price}
        />
       );
      })}
    </div>
   </div>}

   {specialDealsExist &&<div className="special">
    <h3>special offers</h3>
    <hr />

    <div className="deals">
     {products
      .filter((pro) => pro.special_offer === "1")
      .map((val, idx) => {
       return (
        <Offers
         key={idx}
         id={val.id}
         image={
          "https://ebn.ng/public/upload/products/thumbnail/" +
          val.product_thumbnail
         }
         product_name={val.product_name}
         price={val.selling_price}
         rating={"3"}
         discount_price={val.discount_price}
        />
       );
      })}
    </div>
   </div>}

   {featuredDealsExist &&<div className="recent">
    <h3>Featured</h3>
    <hr />

    <div className="deals">
     {products
      .filter((pro) => pro.featured === "1")
      .map((val, idx) => {
       return (
        <Offers
         key={idx}
         id={val.id}
         image={
          "https://ebn.ng/public/upload/products/thumbnail/" +
          val.product_thumbnail
         }
         product_name={val.product_name}
         price={val.selling_price}
         rating={"3"}
         discount_price={val.discount_price}
        />
       );
      })}
    </div>
   </div>}
  </Container>
 );
};
export default Deals;

const Container = styled.div`
 margin: 30px;
 display: flex;
 align-items: center;
 justify-content: space-around;
 gap: 2rem;

 .hot,
 .special,
 .recent {
  /* width: 25%; */

  h3 {
   text-transform: capitalize;
   font-size: 20px;
  }

  .deals {
   margin-top: 25px;
  }
 }
 @media (max-width: 430px) {
  .hot,
  .special,
  .recent {
   .deals {
    overflow-x: scroll;
    &::-webkit-scrollbar {
     display: none;
    }
   }
  }
 }
 @media (max-width: 1000px) {
  flex-direction: column;
  /* width: 100%; */
  margin: 0;

  .hot,
  .special,
  .recent {
   width: 90%;
   margin: 0 auto;

   .deals {
    display: flex;
    /* justify-content: space-between; */
    gap: 2rem;
   }
  }
 }
`;
