// const baseUrl = "https://ebn.ng";
const baseUrl = "https://api.ebn.ng";

const routes = {
 register: (userType) => `${baseUrl}/${userType}/register`,
 login: (userType) => `${baseUrl}/${userType}/login`,
 'refresh-token':()=> `${baseUrl}/vendor/refresh`,
 "change-password":(userType)=>`${baseUrl}/${userType}/api/update/password`,
 'contact-us':()=> `${baseUrl}/user/contact-us`,
 vendor: {
  url: () => `${baseUrl}/vendor/api`,
  // "reset-password": () => `${routes.customer.url()}/resetPassword`,
  // "activate-resetPassword": () =>
  //  `${routes.customer.url()}/activateResetPassword`,
  brand:{
   'create-brand': ()=> `${routes.vendor.url()}/store/brand`,
  "get-brand": (id)=> `${routes.vendor.url()}/brand/${id}`,
  'get-all-brands': ()=>`${routes.vendor.url()}/all/brand`,
  "update-brand": (id)=>`${routes.vendor.url()}/update/brand/${id}`,
  "delete-brand": (id)=>`${routes.vendor.url()}/delete/brand/${id}`,
  },
  category:{
   "create-category": ()=>`${routes.vendor.url()}/store/category`,
  "get-categories":()=> `${routes.vendor.url()}/all/category`,
  'get-category':(id)=>`${routes.vendor.url()}/category/${id}`,
  "update-category": (id)=>`${routes.vendor.url()}/update/category/${id}`,
  "delete-category": (id)=>`${routes.vendor.url()}/delete/category/${id}`,
  },
  subcategory:{
   "create-subcategory":()=>`${routes.vendor.url()}/store/subcategory`,
  "get-subcategory":(id)=>`${routes.vendor.url()}/subcategory/${id}`,
  "get-subcategories":()=>`${routes.vendor.url()}/all/subcategory`,
  "update-subcategory": (id)=>`${routes.vendor.url()}/update/subcategory/${id}`,
  "delete-subcategory": (id)=>`${routes.vendor.url()}/delete/subcategory/${id}`,
  },
  product:{
   "create-product":()=>`${routes.vendor.url()}/create/product`,
   "get-products":()=>`${routes.vendor.url()}/all/products`,
   "edit-product":(id)=>`${routes.vendor.url()}/edit/product/${id}`,
   "delete-product":(id)=>`${routes.vendor.url()}/delete/product/${id}`,
   "get-product":(id)=>`${routes.vendor.url()}/product/${id}`,
   "get-allApprovedProducts":()=>`${routes.vendor.url()}/all/approved/products`,
   "get-allUnapprovedProducts":()=>`${routes.vendor.url()}/all/unapproved/products`,
  },
  profile: {
   "get-details": () => `${routes.vendor.url()}/show`,
   "update-profile": () => `${routes.vendor.url()}/update/profile`,
  },
  auction:{
   'create-auction': ()=>`${routes.vendor.url()}/create/auction`,
   'get-all-auctions': ()=>`${routes.vendor.url()}/get/all/auctions`,
  }
 },
 customer: {
  url: () => `${baseUrl}/user`,
  "reset-password": () => `${routes.customer.url()}/resetPassword`,
  "activate-resetPassword": () =>
   `${routes.customer.url()}/activateResetPassword`,
   "get-vendors": ()=>`${routes.customer.url()}/get/all/vendors`,
  profile: {
   "get-details": () => `${routes.customer.url()}/api/show`,
   "update-profile": () => `${routes.customer.url()}/api/update/profile`,
   'get-transactions': () => `${routes.customer.url()}/api/get/transactions/report`
  },
  products:{
   'get-allProducts': ()=> `${routes.customer.url()}/get/all/products`,
   'get-allFilteredProducts': ()=> `${routes.customer.url()}/filter/products`,
   'get-Product': (id)=> `${routes.customer.url()}/get/single/product/${id}`,
   'get-allApprovedProducts': ()=> `${routes.customer.url()}/all/approved/products`,
   'product-by-category': (id)=> `${routes.customer.url()}/get/product/categories/${id}`
  },
  vendors:{
   'get-allVendors': ()=>`${routes.customer.url()}/get/all/vendors`,
   'get-Vendor': (id)=>`${routes.customer.url()}/get/vendor/${id}`,
   'get-Vendor-products':(id)=>`${routes.customer.url()}/vendor/product/${id}`,
   'get-allActiveVendors': ()=>`${routes.customer.url()}/get/active/vendors`,
   'get-allInactiveVendors': ()=>`${routes.customer.url()}/get/inactive/vendors`,
   'get-vendorProductCount': (id)=>`${routes.customer.url()}/vendor/product/${id}`,
  },
  brand:{
   "get-brands":()=>`${routes.customer.url()}/all/brands`,
   "get-brand":(id)=>`${routes.customer.url()}/get/brand/${id}`
  },
  category:{
   "get-categories":()=>`${routes.customer.url()}/all/categories`,
   "get-category":(id)=>`${routes.customer.url()}/get/category/${id}`
  },
  subcategory:{
   "get-subcategories":()=>`${routes.customer.url()}/all/subcategories`,
   "get-subcategory":(id)=>`${routes.customer.url()}/get/subcategory/${id}`
  },
  cart:{
   'add-to-cart':(id)=>`${routes.customer.url()}/add/to/cart/${id}`,
   'get-cart':()=>`${routes.customer.url()}/get/cart`,
   'get-count':()=>`${routes.customer.url()}/count/cart`,
   'delete-cart-item':(id)=>`${routes.customer.url()}/remove/cart/${id}`,
   'cart-increment' :(id)=>`${routes.customer.url()}/cart/increment/${id}`,
   'cart-decrement' :(id)=>`${routes.customer.url()}/cart/decrement/${id}`
  },
  wishlist:{
   'add-to-wishlist':(id)=>`${routes.customer.url()}/api/add/to/wishlist/${id}`,
   'get-wishlist': ()=>`${routes.customer.url()}/api/get/wishlist`,
   'delete-wishlist-item':(id)=>`${routes.customer.url()}/api/delete/wishlist/${id}`
  },
  watchlist:{
   'add-to-watchlist':(id)=>`${routes.customer.url()}/api/add/to/watchlist/${id}`,
   'get-watchlist': ()=>`${routes.customer.url()}/api/get/watchlist`,
   'delete-watchlist-item':(id)=>`${routes.customer.url()}/api/delete/watchlist/${id}`
  },
  claim:{
   'raise-claim': ()=>`${routes.customer.url()}/api/add/claim/report`,
   'view-claim': ()=>`${routes.customer.url()}/api/view/claim/report`
  },
  checkout:{
   'checkout':()=>`${routes.customer.url()}/api/cart/checkout`,
   'add-coupon': ()=>`${routes.customer.url()}/apply/coupon`,
   'get-coupon-calc' : ()=>`${routes.customer.url()}/api/calculate/coupon`,
   'remove-coupon' : ()=>`${routes.customer.url()}/api/remove/coupon`,
   'pickup-payment': ()=>`${routes.customer.url()}/api/pickup/payment`,
   'confirm-pickup-payment': ()=>`${routes.customer.url()}/api/pickup/confirm/payment`
  },
  bidding:{
   'make-bid': ()=>`${routes.customer.url()}/api/create/bid`,
   'get-all-auctions': ()=>`${routes.customer.url()}/get/all/auctions`,
   'get-all-biddings': ()=>`${routes.customer.url()}/api/get/all/biddings`,
   'get-auction': (id)=>`${routes.customer.url()}/api/get/auction/${id}`,
   'get-current-bid': (id)=>`${routes.customer.url()}/api/get/single/bidding/${id}`,
  }
 },
 admin:{
  url:()=> `${baseUrl}/admin/api`,
  brand:{
   'create-brand': ()=> `${routes.admin.url()}/store/brand`,
  "get-brand": (id)=> `${routes.admin.url()}/brand/${id}`,
  'get-all-brands': ()=>`${routes.admin.url()}/all/brand`,
  "update-brand": (id)=>`${routes.admin.url()}/update/brand/${id}`,
  "delete-brand": (id)=>`${routes.admin.url()}/delete/brand/${id}`,
  },
  category:{
   "create-category": ()=>`${routes.admin.url()}/store/category`,
  "get-categories":()=> `${routes.admin.url()}/all/category`,
  'get-category':(id)=>`${routes.admin.url()}/category/${id}`,
  "update-category": (id)=>`${routes.admin.url()}/update/category/${id}`,
  "delete-category": (id)=>`${routes.admin.url()}/delete/category/${id}`,
  },
  subcategory:{
   "create-subcategory":()=>`${routes.admin.url()}/store/subcategory`,
  "get-subcategory":(id)=>`${routes.admin.url()}/subcategory/${id}`,
  "get-subcategories":()=>`${routes.admin.url()}/all/subcategory`,
  "update-subcategory": (id)=>`${routes.admin.url()}/update/subcategory/${id}`,
  "delete-subcategory": (id)=>`${routes.admin.url()}/delete/subcategory/${id}`,
  },
  vendor:{
   "get-active-vendors": ()=>`${routes.admin.url()}/active/vendors`,
   "get-inactive-vendors": ()=>`${routes.admin.url()}/inactive/vendors`,
   "activate-vendor": (id)=>`${routes.admin.url()}/vendor/${id}/activate`,
   "deactivate-vendor": (id)=>`${routes.admin.url()}/vendor/${id}/deactivate`,
  },
  product:{
   "create-product":()=>`${routes.admin.url()}/create/product`,
   "get-products":()=>`${routes.admin.url()}/all/products`,
   "get-product":(id)=>`${routes.admin.url()}/product/${id}`,
   "edit-product":(id)=>`${routes.admin.url()}/edit/product/${id}`,
   "delete-product":(id)=>`${routes.admin.url()}/delete/product/${id}`,
   'activate-product':(id)=>`${routes.admin.url()}/product/${id}/activate`,
   'deactivate-product':(id)=>`${routes.admin.url()}/product/${id}/deactivate`,
   "get-allApprovedProducts":()=>`${routes.admin.url()}/all/approved/products`,
   "get-allUnapprovedProducts":()=>`${routes.admin.url()}/all/unapproved/products`,
  },
  profile:{
   'get-details': ()=>`${routes.admin.url()}/show`,
   "update-profile": () => `${routes.admin.url()}/update/profile`,
  },
  coupon:{
   'create-coupon': ()=>`${routes.admin.url()}/create/coupon`,
   'delete-coupon': (id)=>`${routes.admin.url()}/delete/coupon/${id}`,
   'get-all-coupons':()=>`${routes.admin.url()}/get/all/coupon`,
   'update-coupon': (id)=>`${routes.admin.url()}/edit/coupon/${id}`
  },
  sliders:{
   'create-slider': ()=>`${routes.admin.url()}/create/slider`,
   'delete-slider': (id)=>`${routes.admin.url()}/delete/slider/${id}`,
   'get-all-sliders':()=>`${routes.admin.url()}/get/all/slider`,
   'update-slider': (id)=>`${routes.admin.url()}/edit/slider/${id}`
  },
  claims:{
   'view-claim': (id)=>`${routes.admin.url()}/view/claim/${id}`,
   'open-claim': (id)=>`${routes.admin.url()}/open/report/${id}`,
   'close-claim': (id)=>`${routes.admin.url()}/close/report/${id}`,
  },
  transactions:{
   'get-all-transactions':()=>`${routes.admin.url()}/get/all/transactions`
  },
  bidding:{
   'get-all-auctions':()=>`${routes.admin.url()}/get/all/auctions`,
   'get-all-bidding-history':()=>`${routes.admin.url()}/get/all/biddings`,
  }
 }
};

export default routes;
