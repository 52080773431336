import React, { useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
// import required modules
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import ReactStars from "react-stars";
import api from "../services/api";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../redux/Features/cartSlice";
import { formatPrice } from "../components/FormatPrice";
import { toast } from "react-toastify";
import { TiTick } from "react-icons/ti";
import NewProductCard from "../components/Cards/NewProductCard";
import { FaStar } from "react-icons/fa";
import { CircularProgress } from "@mui/material";

const SingleProduct = () => {
  const [adding, setAdding] = useState(false);
  const [product, setProduct] = useState({});
  const [multiImg, setMultiImg] = useState([]);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedColor, setSelectedColor] = useState(null);
  const { productId } = useParams();
  const [brand, setBrand] = useState("");
  const [related, setRelated] = useState([]);
  const targetRef = useRef(null);
  const [review, setReview] = useState({
    name: "",
    rating: 0,
    comment: "",
  });

  const { user } = useSelector((store) => store.auth);

  useEffect(() => {
    targetRef.current.scrollIntoView({ behavior: "smooth" });
  }, []);

  useEffect(() => {
    // get product by ID
    api.customer.products
      .getProduct(productId)
      .then((res) => {
        setProduct(res.data?.Product);
        setMultiImg(res.data?.MultiImage);
      })
      .catch((res) => {
        console.log(res);
      });
    // get related product
    api.customer.products
      .getProducts()
      .then((res) => {
        if (res.status === 200) {
          setRelated(res.data.products);
        }
      })
      .catch((res) => console.log(res));
  }, [productId]);

  const dispatch = useDispatch();

  useEffect(() => {
    // get brand
    api.customer.brand
      .getBrand(product?.brand_id)
      .then((res) => {
        setBrand(res.data.Brand.brand_name);
      })
      .catch((err) => console.log(err));
  }, [product]);

  const handleSizeSelection = (size) => {
    setSelectedSize(size);
  };
  const handleColorSelection = (color) => {
    setSelectedColor(color);
  };

  const finalPrice =
    product.discount_price > 0 ? product.discount_price : product.selling_price;

  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  // let tagElements = null
  let colorElements = null;
  let sizeElements = null;
  if (product && product.product_tags) {
    try {
      const color = JSON.parse(product?.product_color);
      colorElements = color.map((color, index) => (
        <div className="" key={index}>
          <ul className="flex gap-2 uppercase">
            <li
              className="flex gap-1"
              onClick={() => handleColorSelection(color)}
            >
              {color} {selectedColor === color && <TiTick />}
            </li>
          </ul>
        </div>
      ));
      const size = JSON.parse(product?.product_size);
      sizeElements = size.map((size, index) => (
        <div className="" key={index}>
          <ul className="flex gap-2 uppercase">
            <li
              className="flex gap-1"
              onClick={() => handleSizeSelection(size)}
            >
              {size} {selectedSize === size && <TiTick />}
            </li>
          </ul>
        </div>
      ));
    } catch (error) {
      console.error("Error parsing product tags:", error);
    }
  } else {
    console.warn("Product tags are not available.");
  }

  const addingToCart = () => {
    setAdding(true);

    const { id } = product;

    if (
      colorElements &&
      sizeElements > 0 &&
      (selectedColor === "" || selectedSize === "")
    ) {
      toast.warn("Kindly add a color or a size");
      setAdding(false);
      return;
    }
    const formData = new FormData();
    formData.append("product_name", product.product_name);
    formData.append("quantity", 1);
    formData.append("color", selectedColor);
    formData.append("size", selectedSize);
    formData.append("vendor", product.vendor_id);

    // Dispatch the thunk action
    dispatch(addToCart({ id, formData }))
      .unwrap()
      .then((res) => {
        toast.success(`${product.product_name} added to cart`);
      })
      .catch((err) => {
        console.log(err);
        toast.error(`Failed to add ${product.product_name} to cart`);
      })
      .finally(() => setAdding(false));
  };

  const handleReviewChange = (e) => {
    const { name, value } = e.target;
    setReview((prevReview) => ({
      ...prevReview,
      [name]: value,
    }));
  };

  const handleReviewRating = (rating) => {
    setReview((prevReview) => ({
      ...prevReview,
      rating,
    }));
  };

  const handleReview = (e) => {
    e.preventDefault();
    if (!user) {
      toast.error("Please kindly login");
      return;
    }

    if (review.name && review.rating && review.comment) {
      setReview({ name: "", rating: 0, comment: "" });
    }
  };

  return (
    <main className="m-4 md:mx-[3%] flex flex-col gap-10" ref={targetRef}>
      <section className="flex flex-col lg:flex-row gap-8 mt-[50px] w-[80%] mx-auto">
        {/* Product slider */}
        <div className="w-full lg:w-[50%]">
          <Swiper
            style={{
              "--swiper-navigation-color": "#ffffff",
              "--swiper-pagination-color": "#fff",
              marginBottom: "15px",
            }}
            spaceBetween={10}
            navigation={true}
            thumbs={{ swiper: thumbsSwiper }}
            modules={[FreeMode, Navigation, Thumbs]}
            className="mySwiper2"
          >
            {multiImg?.map((img, index) => {
              return (
                <SwiperSlide key={img.id} className="w-full ">
                  <img
                    src={"https://api.ebn.ng/public" + img.photo_name}
                    alt={index}
                    className="w-full  object-contain"
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
          <Swiper
            onSwiper={setThumbsSwiper}
            spaceBetween={5}
            slidesPerView={4}
            freeMode={true}
            watchSlidesProgress={true}
            modules={[FreeMode, Navigation, Thumbs]}
            className="mySwiper"
          >
            {multiImg?.map((img, index) => {
              return (
                <SwiperSlide
                  key={img.id}
                  className="w-[40px] lg:w-[70px] h-[40px]"
                >
                  <img
                    src={"https://api.ebn.ng/public" + img.photo_name}
                    alt={index}
                    className="object-contain"
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
        {/* Product Details */}
        <aside className="w-full lg:w-[45%]">
          <h3 className="text-2xl lg:text-4xl w-full md:w-[80%]">
            {product?.product_name}
          </h3>
          {/* the ratings and reviews with price section */}
          <div className="mt-[20px]">
            {/* stars reviews orders */}
            <div className="flex items-center gap-4 mb-3 text-lg">
              <ReactStars count={5} value={3.5} size={25} color2="#ff8800" />
              {/* <small className="text-gray-300 font-bold">
        {product?.productReview} reviews
       </small> */}
              {/* <div className="flex items-center gap-1">
        <input type="checkbox" name="" checked={true} id="" />
        <label htmlFor="order">154 orders</label>
       </div> */}
            </div>
            {/* details */}
            <div className="flex gap-3 items-center">
              <h3 className="text-xl lg:text-2xl my-2 font-bold">
                ₦{formatPrice(finalPrice)}
              </h3>
              {product.discount_price > 0 && (
                <h3 className="text-xl lg:text-2xl my-2 font-bold text-red-900">
                  ₦<strike>{formatPrice(product.selling_price)}</strike>
                </h3>
              )}
            </div>
            <p
              className="text-lg lg:text-xl text-justify my-4"
              dangerouslySetInnerHTML={{ __html: product?.short_description }}
            ></p>
            {/* extra details */}
            <div className="mt-[50px]">
              <ul className="">
                <li className="flex gap-6 items-center bg-gray-100 p-3 my-2 rounded-sm">
                  <h4>Brand :</h4>
                  <span>{brand}</span>
                </li>
                <li className="flex gap-6 items-center p-3">
                  <h4>Color :</h4>
                  {colorElements}
                </li>
                <li className="flex gap-6 items-center bg-gray-100 p-3 my-2 rounded-sm">
                  <h4>Size :</h4>
                  {sizeElements}
                </li>
              </ul>
              {/* buttons redux */}
              <div className="mt-[20px]">
                <div className="mt-4">
                  <button
                    type="button"
                    className="py-3 px-5 bg-green-700 text-white font-medium rounded-md"
                    onClick={addingToCart}
                  >
                    {adding ? (
                      <CircularProgress
                        size={20}
                        sx={{ color: "whitesmoke" }}
                      />
                    ) : (
                      "Add to Cart"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </aside>
      </section>

      <section className="w-full mx-auto my-8 p-8 rounded shadow-md">
        <h2 className="text-xl lg:text-2xl font-bold mb-4">Add a Review</h2>
        <form
          onSubmit={handleReview}
          className="flex flex-col gap-4 justify-start"
        >
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Name
            </label>
            <input
              type="text"
              name="name"
              value={review.name}
              onChange={handleReviewChange}
              className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm"
              placeholder="Your name"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Rating
            </label>
            <div className="flex">
              {[1, 2, 3, 4, 5].map((star) => (
                <FaStar
                  key={star}
                  className={`cursor-pointer ${
                    star <= review.rating ? "text-yellow-500" : "text-gray-300"
                  }`}
                  onClick={() => handleReviewRating(star)}
                />
              ))}
            </div>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">
              Comment
            </label>
            <textarea
              name="comment"
              value={review.comment}
              onChange={handleReviewChange}
              className="mt-1 p-2 block w-full border border-gray-300 rounded-md shadow-sm"
              placeholder="Your review"
              rows={"10"}
            />
          </div>
          <div className="w-full md:w-[10%]">
            <button
              type="submit"
              className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700"
            >
              Submit Review
            </button>
          </div>
        </form>
      </section>

      {/* related products area */}
      <section className="mt-[30px]">
        <h2 className="text-2xl ">
          <span className="border-l-[15px] rounded-md mr-2 border-l-green-700"></span>
          Related Products
        </h2>
        <div className="related">
          {related
            .filter(
              (relate) =>
                relate.category_id === product.category_id &&
                relate.id !== product.id
            )
            .map((product) => {
              return (
                <NewProductCard
                  onView={""}
                  key={product.id}
                  price={product.selling_price}
                  product_name={product.product_name}
                  rating={product.productRating}
                  image={
                    "https://api.ebn.ng/public" + product.product_thumbnail
                  }
                  id={product.id}
                  tag={product.productTag}
                  discount={product.discount_price}
                  createdBy={product.created_by}
                  slug={product.product_slug}
                />
              );
            })}
        </div>
      </section>
    </main>
  );
};

export default SingleProduct;
