import React, { useState, useEffect, useRef } from "react";
import CheckoutItem from "../components/CheckoutItem";
import { useDispatch, useSelector } from "react-redux";
import PaystackPop from "@paystack/inline-js";
import { PaystackConsumer } from "react-paystack";
import api from "../services/api";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import {
  // calculateTotals,
  resetCart,
  // setDeliveryPrice,
  //  applyCoupon,
} from "../redux/Features/cartSlice";
import Spinner from "../assets/VAyR.gif";
import { formatPrice } from "../components/FormatPrice";
// import AddCoupon from "../components/AddCoupon";
import Receipt from "../components/Receipt";
import { v4 as uuidv4 } from "uuid";
import generatePDF from "react-to-pdf";

const CheckoutPage = () => {
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [checkingOut, setCheckingOut] = useState({
    deliveryPrice: "",
    total_price: "",
    cartItems: [],
  });

  const [cart, setCart] = useState('');

  const [showReceipt, setShowReceipt] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [transactionDate, setTransactionDate] = useState("");
  const targetRef = useRef();

  const [deliveryMethod, setDeliveryMethod] = useState("");
  const [courier, setCourier] = useState("");

  const { user } = useSelector((store) => store.auth);

  const [billing, setBilling] = useState({
    fullName: user?.name,
    email: user?.email,
    address: user?.address,
    phone: user?.phone,
  });
  const [deliveryNote, setDeliveryNote] = useState("");

  //  const handleCouponChange = (e) => {
  //   setCoupon(e.target.value);
  //  };

  //  const addCoupon = () => {
  //   if (appliedCoupon === null) {
  //    setCoupon(50);
  //    dispatch(applyCoupon(50));
  //    sessionStorage.setItem("appliedCoupon", 50);
  //   }
  //  };

  const navigate = useNavigate();

  // everyting about picup
  useEffect(() => {
    if (deliveryMethod === "pickup") {
      handlePickup();
    }
    // eslint-disable-next-line
  }, [deliveryMethod]);

  const handlePickup = () => {
    setLoading(true);

    const formData = new FormData();

    formData.append("fullname", billing.fullName);
    formData.append("phone", billing.phone);
    formData.append("email", billing.email);
    formData.append("address", billing.address);
    formData.append("delivery_note", deliveryNote);

    api.customer.checkout
      .checkout(formData)
      .then((res) => {
        console.log(res);
        const cartItemsArray = Object.values(res.data.cart_items);
        setCheckingOut({
          deliveryPrice: res.data.delivery_price,
          total_price: res.data?.total_price,
          cartItems: cartItemsArray,
        });

        sessionStorage.setItem("cart", checkingOut);
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  };

  const handlePickupPayment = () => {
    api.customer.checkout
      .pickupPayment({})
      .then((res) => {
        console.log(res);

        if (res.status) {
          // Redirect the user to the Paystack authorization URL
          window.location.href = res.data.authorization_url;
        } else {
          console.error("Payment initiation failed");
        }
      })
      .catch(console.error);
  };

  const handleBillingChange = (e) => {
    const { name, value } = e.target;
    setBilling({
      ...billing,
      [name]: value,
    });
  };

  useEffect(() => {
    // Parse query parameters
    const queryParams = new URLSearchParams(location.search);
    const trxref = queryParams.get("trxref");
    const reference = queryParams.get("reference");

    if (trxref && reference) {
      const formData = new FormData();
      formData.append("reference", reference);
      formData.append("fullname", billing.fullName);
      formData.append("phone", billing.phone);
      formData.append("email", billing.email);
      formData.append("address", billing.address);
      formData.append("delivery_note", deliveryNote);

      setCart(JSON.parse(sessionStorage.getItem("cart")));

      api.customer.checkout
        .confirmPickupPayment(formData)
        .then((res) => {
          setShowReceipt(true);
          console.log(res);
          downloadReceipt();
        })
        .catch(console.error);
    }
  }, [location, deliveryNote, billing]);

  // Handle form submission
  const generateTransactionId = () => {
    const uniqueTransactionId = `ebn${Date.now()
      .toString()
      .slice(-3)}-${uuidv4().slice(0, 11)}`;
    return uniqueTransactionId;
  };

  const getCurrentDate = () => {
    const currentDate = new Date();

    return currentDate.toLocaleDateString();
  };

  async function downloadReceipt() {
    toast.info("Preparing download");

    try {
      await generatePDF(targetRef, {
        filename: "Receipt.pdf",
      });
      setTimeout(() => {
        toast.success("Receipt Downloaded");
        // sessionStorage.removeItem("appliedCoupon");
        sessionStorage.removeItem("cart");
        navigate("/");
      }, 3000);
    } catch (error) {
      toast.error("Failed to generate or download receipt");
    }
  }

  const handleSubmit = async () => {
    if (deliveryMethod !== "" && deliveryNote.trim() !== "") {
      try {
        setLoading(true);

        const newTransactionId = generateTransactionId();
        const newTransactionDate = getCurrentDate();
        setTransactionId(newTransactionId);
        setTransactionDate(newTransactionDate);

        toast.info("Generating your Receipt");

        // Simulating asynchronous receipt generation
        await new Promise((resolve) => setTimeout(resolve, 3000));

        toast.success("Receipt Generated");

        // Generate PDF and trigger download
        //  await downloadReceipt();

        setShowReceipt(true);
      } catch (error) {
        console.error("Error generating receipt:", error);
        toast.error("Failed to generate receipt");
      } finally {
        setLoading(false);
      }
    } else {
      toast.error("Please provide a valid delivery method and note.");
    }
  };

  const handleDelivery = (e) => {
    const selectedLocation = e.target.value;
    setDeliveryMethod(selectedLocation);
  };

  return (
    <main className="min-h-screen  bg-gray-100 py-10 relative">
      {loading && (
        <div className="h-full flex items-center justify-center w-full loadingDiv">
          <img src={Spinner} alt="spinner" className="w-[100px]" />
        </div>
      )}
      {showReceipt && (
        <div className="h-full flex items-center justify-center w-full loadingDiv">
          <Receipt
            transactionDetails={cart}
            runDownload={() => downloadReceipt()}
            targetRef={targetRef}
          />
        </div>
      )}
      <h1 className="text-[40px] font-extrabold py-8 text-center">
        Secure Checkout
      </h1>

      <section className="flex flex-col lg:flex-row gap-[30px] justify-center mx-3">
        <div className="lg:w-[50%]">
          <h2 className="text-xl font-medium mb-6">Billing details</h2>
          <form onSubmit={handleSubmit} className="billing">
            <div className="flex gap-3">
              <article>
                <label htmlFor="firstName">
                  Full name <span>*</span>
                </label>
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  onChange={handleBillingChange}
                  value={billing.fullName}
                />
              </article>
            </div>
            <article>
              <label htmlFor="address">
                Address <span>*</span>
              </label>
              <div className="flex flex-col gap-2">
                <input
                  type="text"
                  name="address"
                  id="address"
                  placeholder="House number and street name"
                  onChange={handleBillingChange}
                  value={billing.address}
                />
              </div>
            </article>
            <article>
              <label htmlFor="phone">
                Phone <span>*</span>
              </label>
              <input
                type="tel"
                name="phone"
                id="phone"
                onChange={handleBillingChange}
                value={billing.phone}
              />
            </article>
            <article>
              <label htmlFor="email">
                Email <span>*</span>
              </label>
              <input
                type="email"
                name="email"
                id="email"
                onChange={handleBillingChange}
                value={billing.email}
              />
            </article>
            <article>
              <label htmlFor="deliverynote">
                Delivery Note<span>*</span>
              </label>
              <textarea
                name="deliverynote"
                id="deliverynote"
                cols="30"
                rows="3"
                className="border-blue-600 border-2 outline-none p-2"
                onChange={(e) => setDeliveryNote(e.target.value)}
              />
            </article>
            <article>
              <label htmlFor="deliverylocation">
                Delivery Methods<span>*</span>
              </label>
              <select
                name="delivery"
                id="delivery"
                value={deliveryMethod}
                onChange={handleDelivery}
              >
                <option value="">select location</option>
                <option value={"pickup"}>Pickup</option>
                <option value={"home_delivery"}>Home Delivery</option>
              </select>
            </article>
            {deliveryMethod === "home_delivery" && (
              <article>
                <label htmlFor="courier">
                  Courier Services<span>*</span>
                </label>
                <select
                  name="courier"
                  id="courier"
                  value={courier}
                  onChange={(e) => setCourier(e.target.value)}
                >
                  <option value="">select Courier</option>
                  <option value={"gig"}>God Is Good (GIG)</option>
                  {/* <option value={"kwik"}>Kwik</option> */}
                </select>
              </article>
            )}
          </form>
          {/* <div className="my-4">
      <AddCoupon
       couponCode={coupon}
       handleChange={handleCouponChange}
       applyCoupon={addCoupon}
      />
     </div> */}
        </div>

        <div className="lg:w-[35%]">
          <h2 className="text-xl font-medium mb-6">Your order</h2>
          <article>
            <section className="mb-4">
              <div className="max-h-[700px] overflow-y-scroll">
                {deliveryMethod === "" ? (
                  <p>Please select a delivery method.</p>
                ) : deliveryMethod === "pickup" && loading ? (
                  <p>Loading delivery options...</p>
                ) : checkingOut?.cartItems.length > 0 ? (
                  checkingOut?.cartItems.map((item) => (
                    <CheckoutItem
                      key={item.id}
                      img={item.options.image}
                      productName={item.name}
                      productPrice={item.price}
                      productQuantity={item.qty}
                      deliveryPrice={
                        deliveryMethod === "pickup" ? 0.0 : item.delivery_price
                      }
                      id={item.rowId}
                    />
                  ))
                ) : (
                  <p>Your cart is empty.</p>
                )}
              </div>

              <div>
                {/* <article className="total">
                  <h3 className="font-bold">Sub Total</h3>
                  <p>
                    <strong>{formatPrice(checkingOut.total_price)}</strong>
                  </p>
                </article> */}
                {deliveryMethod !== "" && (
                  <article className="total">
                    <h3 className="font-bold">Total Delivery Price</h3>
                    <p>
                      <strong>{formatPrice(checkingOut.deliveryPrice)}</strong>
                    </p>
                  </article>
                )}
                {/* {appliedCoupon !== null && (
                  <article className="total">
                    <h3 className="font-bold">Coupon</h3>
                    <p>
                      <strong>-{coupon}%</strong>
                    </p>
                  </article>
                )} */}
                <article className="total">
                  <h3 className="font-bold">Total</h3>
                  <p>
                    <strong>{formatPrice(checkingOut.total_price || 0)}</strong>
                  </p>
                </article>
              </div>
            </section>

            <div className="mt-6 payment">
              <h1 className="text-xl mb-3 font-bold">Secure Online payment</h1>
            </div>
          </article>
          <button type="button" onClick={handlePickupPayment}>
            Make Payment
          </button>
        </div>
      </section>
    </main>
  );
};

export default CheckoutPage;
