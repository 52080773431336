import React from "react";
import { formatPrice } from "./FormatPrice";
import { IoCloseSharp } from "react-icons/io5";


const CheckoutItem = ({
 img,
 productName,
 productPrice,
 deliveryPrice,
 productQuantity,
 id,
}) => {
 return (
  <article className="checkoutItem" id={id}>
    <IoCloseSharp className="absolute right-1 top-0 text-red-800 text-xl cursor-pointer"/>
   <div className="checkoutItemDetails">
    <figure className="relative">
     <img src={"https://api.ebn.ng/public" + img} alt="product" className="w-[150px]" />
     <small className="checkoutQuantity">{productQuantity}</small>
    </figure>

    <h3 className="checkoutItemName">{productName || 'No Product name'}</h3>
   <h4 className="productPrice font-bold">₦{formatPrice(productPrice)}</h4>
   </div>

   <div className="flex justify-between">
    <p>Delivery Price</p>
    <p>₦{formatPrice(deliveryPrice)}</p>
   </div>
  </article>
 );
};

export default CheckoutItem;
