/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import api from "../../../services/api";
import { toast } from "react-toastify";
import Loading from "../../../components/Loading";
const Coupon = () => {
  const [showForm, setShowForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [coupons, setCoupons] = useState([]);
  const [coupon, setCoupon] = useState({
    coupon_validity: 0,
    coupon_name: "",
    coupon_discount: 0,
  });

  const showCouponForm = () => {
    setShowForm(!showForm);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCoupon({
      ...coupon,
      [name]: value,
    });
  };

  const createCoupon = () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("coupon_validity", coupon.coupon_validity);
    formData.append("coupon_name", coupon.coupon_name);
    formData.append("coupon_discount", coupon.coupon_discount);

    if (isUpdating) {
      const couponToUpdate = coupons.find(
        (c) =>
          c.coupon_name === coupon.coupon_name &&
          c.coupon_validity === coupon.coupon_validity
      );
      if (couponToUpdate) {
        api.admin.coupon
          .updateCoupon(couponToUpdate.id, formData)
          .then((res) => {
            toast.success(res.data.message);
            setCoupon({
              coupon_validity: 0,
              coupon_name: "",
              coupon_discount: 0,
            });
            setIsUpdating(false);
            setShowForm(false);
          })
          .catch((res) => console.log(res))
          .finally(() => setIsLoading(false));
      }
    } else {
      api.admin.coupon
        .createCoupon(formData)
        .then((res) => {
          toast.success(res.data.message);
          setCoupon({
            coupon_validity: 0,
            coupon_name: "",
            coupon_discount: 0,
          });
        })
        .catch((res) => console.log(res))
        .finally(() => setIsLoading(false));
    }
  };

  const deleteCoupon = (id) => {
    api.admin.coupon
      .deleteCoupon(id)
      .then((res) => {
        toast.success('Coupon deleted successfully')
        setCoupons((prevCoupons) =>
          prevCoupons.filter((coupon) => coupon.id !== id)
        );
      })
      .catch((res) => console.log(res))
      .finally(() => setIsLoading(false));
  };

  const editCoupon = (coupon) => {
    setCoupon({
      coupon_validity: coupon.coupon_validity,
      coupon_name: coupon.coupon_name,
      coupon_discount: coupon.coupon_discount,
    });
    setIsUpdating(true);
    setShowForm(true);
  };

  useEffect(() => {
    api.admin.coupon
      .getAllCoupons()
      .then((res) => {
        setCoupons(res.data?.coupon);
      })
      .catch((res) => console.log(res))
      .finally(() => setIsLoading(false));
  }, [isLoading]);

  const today = new Date().toISOString().split("T")[0];
  // Get tomorrow's date in YYYY-MM-DD format
  // const today = new Date();
  // const tomorrow = new Date(today);
  // tomorrow.setDate(tomorrow.getDate() + 1);
  // const minDate = tomorrow.toISOString().split('T')[0];

  return (
    <section>
      <h1 className="roleHeaders">Coupon</h1>
      <div className="my-4 flex flex-col gap-4">
        <button
          type="button"
          className="roleBtn ml-auto"
          onClick={showCouponForm}
        >
          Add Coupon
        </button>
        {showForm && (
          <div className="py-2 px-3 my-4 bg-white  ">
            <form className="grid grid-cols-2 gap-3 justify-start items-center my-4">
              <article className="couponArticle">
                <label htmlFor="coupon_name">Name</label>
                <input
                  type="text"
                  name="coupon_name"
                  id="coupon_name"
                  className="couponInput"
                  onChange={handleChange}
                  value={coupon.coupon_name}
                />
              </article>
              <article className="couponArticle">
                <label htmlFor="coupon_discount">Discount</label>
                <input
                  type="text"
                  name="coupon_discount"
                  id="coupon_discount"
                  className="couponInput"
                  onChange={handleChange}
                  value={coupon.coupon_discount}
                />
              </article>
              <article className="couponArticle">
                <label htmlFor="coupon_validity">Validity</label>
                <input
                  type="date"
                  name="coupon_validity"
                  id="coupon_validity"
                  className="couponInput"
                  onChange={handleChange}
                  value={coupon.coupon_validity}
                  min={today}
                />
              </article>
            </form>
            <button
              type="button"
              onClick={createCoupon}
              className="submitBtn"
              disabled={isLoading}
            >
              {isLoading ? (
                <Loading className={"w-[30px]"} />
              ) : isUpdating ? (
                "Update Coupon"
              ) : (
                "Create Coupon"
              )}
            </button>
          </div>
        )}
      </div>
      <div className="bg-white p-4">
        <h2 className="roleHeaders">All Coupons</h2>
        <section>
          <div className="flex justify-end m-3">
            <input
              type="text"
              name="couponSearch"
              id="couponSearch"
              placeholder="Search for Coupons here"
              className="roleSearch"
            />
          </div>
          <table className="min-w-full divide-y divide-gray-200 border">
            <thead>
              <tr className="tableHeadRow">
                <th className="tableHead">S/N</th>
                <th className="tableHead">Coupon Name</th>
                <th className="tableHead">Coupon Discount</th>
                <th className="tableHead">Coupon Validity</th>
                <th className="tableHead">Action</th>
              </tr>
            </thead>
            <tbody>
              {coupons.length > 0 ? (
                coupons.map((coupon, index) => {
                  return (
                    <tr className="py-[10px] tableRow" key={coupon.id}>
                      <td>{index + 1}</td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        {coupon.coupon_name}
                      </td>
                      <td>{coupon.coupon_discount}%</td>
                      <td>{coupon.coupon_validity}</td>
                      <td>
                        <div className="flex gap-2 justify-center">
                          <button
                            type="button"
                            className="p-2 text-white text-sm rounded-sm bg-red-700"
                            onClick={() => deleteCoupon(coupon.id)}
                          >
                            Delete
                          </button>
                          <button
                            type="button"
                            className="p-2 text-white text-sm rounded-sm bg-green-600"
                            onClick={() => editCoupon(coupon)}
                          >
                            Update
                          </button>
                        </div>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <div className="flex justify-center align-center">
                  <Loading />
                </div>
              )}
            </tbody>
          </table>
        </section>
      </div>
    </section>
  );
};

export default Coupon;
