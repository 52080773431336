import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import api from "../../../services/api";
import { useState } from "react";
import { toast } from "react-toastify";
import Loading from "../../../components/Loading";
import ReactPaginate from "react-paginate";
import Modal from "../../../components/Cards/Modal";
import Danger from "../../../assets/danger-sign.png";

const SubCategories = () => {
  const { user } = useSelector((store) => store.auth);
  const role = user.role;
  const navigate = useNavigate();

  const [subCategories, setSubcategories] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  // pagination
  const itemsPerPage = 7;
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };
  const pageCount = Math.ceil(subCategories.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = subCategories.slice(startIndex, endIndex);

  const openModal = (id) => {
    setDeleteId(id)
    setIsModalOpen(true);
  };

  useEffect(() => {
    setLoading(true)
    if (role === "vendor") {
      api.vendor.subcategory
        .getSubcategories()
        .then((res) => {
          setSubcategories(res.data?.subcategories);
        })
        .catch((res) => {
          console.log(res);
          toast.error(res.data.message, { autoClose: 1000 });
        }).finally(()=>setLoading(false));
    } else {
      api.admin.subcategory
        .getSubcategories()
        .then((res) => {
          setSubcategories(res.data?.subcategories);
        })
        .catch((res) => {
          toast.error(res.data.message, { autoClose: 1000 });
        }).finally(()=>setLoading(false));
    }
  }, [role]);

  const deleteSubcategory = (id) => {
    if (role === "vendor") {
      api.vendor.subcategory
        .deleteSubcategory(id)
        .then((res) => {
          toast.success(res.data.message, { autoClose: 1000 });
          setSubcategories((prevSub) => prevSub.filter((sub) => sub.id !== id));
        })
        .catch((res) => console.log(res));
    } else {
      api.admin.subcategory
        .deleteSubcategory(id)
        .then((res) => {
          toast.success(res.data.message, { autoClose: 1000 });
          setSubcategories((prevSub) => prevSub.filter((sub) => sub.id !== id));
        })
        .catch((res) => console.log(res));
    }
  };

  const filteredSubs = paginatedData.filter((sub) => {
    return sub.subcategory_name
      .toLowerCase()
      .includes(searchInput.toLowerCase());
  });

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  return (
    <main>
      <section className="topSection">
        <h2 className="roleHeaders">All SubCategories</h2>
        <Link to={`/${role}/addSubCategory`} className="roleBtn">
          Add SubCategory
        </Link>
      </section>

      <section className="bg-white flex flex-col gap-3 py-4">
        <div className="flex justify-end m-3">
          <input
            type="text"
            name="brandSearch"
            id="brandSearch"
            placeholder="Search for Subcategories here"
            className="roleSearch"
            value={searchInput}
            onChange={handleSearchInputChange}
          />
        </div>

        <div className="px-3 overflow-x-auto">
          {loading ? (
            <div className="flex justify-center">
              <Loading className={"w-[40px]"} />
            </div>
          ) : (
            <div>
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr className="tableHeadRow">
                    <th className="tableHead">S/N</th>
                    <th className="tableHead">Category Id</th>
                    <th className="tableHead">SubCategory Name</th>
                    <th className="tableHead">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {subCategories
                    ? filteredSubs.map((sub, idx) => {
                        return (
                          <tr key={sub.id} className="py-[10px] tableRow">
                            <td>{idx + 1}</td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              {sub.category_id}
                            </td>
                            <td>{sub.subcategory_name}</td>
                            <td>
                              <div className="flex gap-4 items-center justify-center">
                                <button
                                  type="button"
                                  className="actionBtn bg-green-600"
                                  onClick={() =>
                                    navigate(
                                      `/${role}/updateSubCategory/${sub.id}`
                                    )
                                  }
                                >
                                  Edit
                                </button>
                                <button
                                  type="button"
                                  className="actionBtn bg-red-600"
                                  onClick={() => openModal(sub.id)}
                                >
                                  Delete
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    : "Loading..."}
                </tbody>
              </table>
              <div className="my-8">
                <ReactPaginate
                  previousLabel={"⇽"}
                  nextLabel={"⇾"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageChange}
                  containerClassName="paginateContainer"
                  pageClassName="cursor-pointer py-1 px-2 rounded-full bg-gray-200 font-bold hover:bg-green-500 hover:text-white"
                  previousClassName="nextPrev"
                  nextClassName="nextPrev"
                  activeClassName="bg-green-500 text-white"
                />
              </div>
            </div>
          )}
        </div>
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <div className="flex flex-col items-center gap-4">
            <img src={Danger} alt="" className="w-[100px]" />
            <h2 className="text-2xl">Are you sure you want to delete?</h2>
            <div className="flex  gap-4 items-center">
              <button
                type="button"
                className="bg-green-600 px-4 rounded-md py-1 text-white font-medium"
                onClick={() => {
                  deleteSubcategory(deleteId);
                }}
              >
                Yes
              </button>
              <button
                type="button"
                className="bg-red-600 px-4 rounded-md py-1 text-white font-medium"
                onClick={() => setIsModalOpen(false)}
              >
                No
              </button>
            </div>
          </div>
        </Modal>
      </section>
    </main>
  );
};

export default SubCategories;
