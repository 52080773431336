import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import api from "../../../services/api";
import { useSelector } from "react-redux";
import { useState } from "react";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import Loading from "../../../components/Loading";
import Modal from "../../../components/Cards/Modal";
import Danger from '../../../assets/danger-sign.png'



const AllBrands = () => {
  const { user } = useSelector((store) => store.auth);

  const [brands, setBrands] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);

  const openModal = (id) => {
    setDeleteId(id);
    setIsModalOpen(true);
  };

  // pagination
  const itemsPerPage = 7;
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };
  const pageCount = Math.ceil(brands.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = brands.slice(startIndex, endIndex);

  const role = user.role;
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    if (role === "vendor") {
      api.vendor.brand
        .getBrands()
        .then((res) => {
          setBrands(res.data?.brands);
        })
        .catch((res) => console.log(res))
        .finally(() => setLoading(false));
    } else {
      api.admin.brand
        .getBrands()
        .then((res) => {
          setBrands(res.data?.brands);
        })
        .catch((res) => console.log(res))
        .finally(() => setLoading(false));
    }
  }, [role]);

  const deleteBrand = (id) => {
    if (role === "vendor") {
      api.vendor.brand
        .deleteBrand(id)
        .then((res) => {
          toast.success(res.data.message, { autoClose: 1000 });
          setBrands((prevBrands) =>
            prevBrands.filter((brand) => brand.id !== id)
          );
        })
        .catch((res) => console.log(res))
        .finally(() => setLoading(false));
    } else {
      api.admin.brand
        .deleteBrand(id)
        .then((res) => {
          toast.success(res.data.message, { autoClose: 1000 });
          setBrands((prevBrands) =>
            prevBrands.filter((brand) => brand.id !== id)
          );
        })
        .catch((res) => console.log(res))
        .finally(() => setLoading(false));
    }
  };

  const filteredBrands = paginatedData.filter((brand) => {
    return brand.brand_name.toLowerCase().includes(searchInput.toLowerCase());
  });

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  return (
    <main>
      <section className="topSection">
        <h2 className="roleHeaders">All Brands</h2>
        <Link to={`/${role}/addBrand`} className="roleBtn">
          Add Brand
        </Link>
      </section>

      <section className="bg-white flex flex-col gap-3 py-4">
        <div className="flex justify-end m-3">
          <input
            type="text"
            name="brandSearch"
            id="brandSearch"
            placeholder="Search for Brands here"
            className="roleSearch"
            value={searchInput}
            onChange={handleSearchInputChange}
          />
        </div>

        <div className="px-3 overflow-x-auto">
          {loading ? (
            <div className="flex justify-center">
              <Loading className={"w-[40px]"} />
            </div>
          ) : (
            <div>
              <table className="min-w-full divide-y divide-gray-200 border">
                <thead>
                  <tr className="tableHeadRow">
                    <th className="tableHead">S/N</th>
                    <th className="tableHead">Brand Id</th>
                    <th className="tableHead">Brand Name</th>
                    <th className="tableHead">Brand Image</th>
                    <th className="tableHead">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {brands &&
                    filteredBrands.map((brand, index) => {
                      return (
                        <tr className="py-[10px] tableRow" key={brand.id}>
                          <td>{index + 1}</td>
                          <td>#{brand.id}</td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            {brand.brand_name}
                          </td>
                          <td>
                            <img
                              src={
                                "https://ebn.itl.ng/upload/brand/" +
                                brand.brand_image
                              }
                              alt="product 1"
                              className="w-[50px] rounded-full object-fill mx-auto"
                            />
                          </td>
                          <td>
                            <div className="flex gap-4 items-center justify-center">
                              <button
                                type="button"
                                className="actionBtn bg-green-600"
                                onClick={() =>
                                  navigate(`/${role}/updateBrand/${brand.id}`)
                                }
                              >
                                Edit
                              </button>
                              <button
                                type="button"
                                className="actionBtn bg-red-600"
                                onClick={() => openModal(brand.id)}
                              >
                                Delete
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
              <div className="my-8">
                <ReactPaginate
                  previousLabel={"⇽"}
                  nextLabel={"⇾"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageChange}
                  containerClassName="paginateContainer"
                  pageClassName="cursor-pointer py-1 px-2 rounded-full bg-gray-200 font-bold hover:bg-green-500 hover:text-white"
                  previousClassName="nextPrev"
                  nextClassName="nextPrev"
                  activeClassName="bg-green-500 text-white"
                />
              </div>
            </div>
          )}
        </div>
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <div className='flex flex-col items-center gap-4'>
            <img src={Danger} alt="" className='w-[100px]' />
            <h2 className='text-2xl'>Are you sure you want to delete?</h2>
            <div className='flex  gap-4 items-center'>
              <button type="button" className='bg-green-600 px-4 rounded-md py-1 text-white font-medium' onClick={() => { deleteBrand(deleteId)}}>Yes</button>
              <button type="button" className='bg-red-600 px-4 rounded-md py-1 text-white font-medium' onClick={() => setIsModalOpen(false)}>No</button>
            </div>
          </div>
        </Modal>
      </section>
    </main>
  );
};

export default AllBrands;
