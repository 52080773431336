import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineEye, AiOutlineEdit, AiOutlineDelete } from "react-icons/ai";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import api from "../../../services/api";
import { useState } from "react";
import ReactPaginate from "react-paginate";
import ModalSingleProduct from "../ModalSingleProduct";
import { toast } from "react-toastify";
import { formatPrice } from '../../../components/FormatPrice'
import Modal from '../../../components/Cards/Modal'
import Danger from '../../../assets/danger-sign.png'
import Loading from "../../../components/Loading";



const AllProducts = () => {
  const { user } = useSelector((store) => store.auth);
  const role = user.role;
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate();

  // pagination
  const itemsPerPage = 7;
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };
  const pageCount = Math.ceil(products.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = products.slice(startIndex, endIndex);

  useEffect(() => {
    setLoading(true)
    if (role === "vendor") {
      api.vendor.products
        .getProducts()
        .then((res) => {
          setProducts(res.data?.products);
        })
        .catch((res) => console.log(res)).finally(()=>setLoading(false));
    } else {
      api.admin.products
        .getProducts()
        .then((res) => {
          setProducts(res.data?.products);
        })
        .catch((res) => console.log(res)).finally(()=>setLoading(false));
    }
  }, [role]);

  const viewProduct = (id) => {
    setModalOpen(true);
    if (role === "vendor") {
      api.vendor.products
        .getProduct(id)
        .then((res) => {
          setProduct(res.data?.Product);
        })
        .catch((res) => console.log(res));
    } else {
      api.admin.products
        .getProduct(id)
        .then((res) => {
          setProduct(res.data?.Product);
        })
        .catch((res) => console.log(res));
    }
  };

  const openModal = (id) => {
    setDeleteId(id)
    setIsModalOpen(true);
  };

  const deleteProduct = (deleteId) => {
    setLoading(true);
    if (role === "vendor") {
      api.vendor.products
        .deleteProduct(deleteId)
        .then((res) => {
          setProducts((prevProducts) =>
            prevProducts.filter((product) => product.id !== deleteId)
          );
          setIsModalOpen(false)
          toast.success(res.data?.message)
        })
        .catch((res) => console.log(res)).finally(()=>setLoading(false));
    } else {
      api.admin.products
        .deleteProduct(deleteId)
        .then((res) => {
          setProducts((prevProducts) =>
            prevProducts.filter((product) => product.id !== deleteId)
          );
          setIsModalOpen(false)
          toast.success(res.data?.message)
        })
        .catch((res) => console.log(res)).finally(()=>setLoading(false));
    }
  };

  const activateProduct = (id) => {
    setLoading(true);
    if (role === "vendor") {
      // api.vendor.products.deleteProduct(id).then((res)=>{console.log(res); setProducts((prevProducts) => prevProducts.filter(product => product.id !== id))}).catch((res)=>console.log(res))
    } else {
      api.admin.products
        .activateProduct(id)
        .then((res) => {
          if(res.status === 200){
            window.location.reload()
          }
        })
        .catch((res) => console.log(res)).finally(()=>setLoading(false));
    }
  };

  const auctionProduct = (id) => {
    navigate(`/vendor/create-auction/${id}`)
  }

  const filteredProducts = paginatedData.filter((product) => {
    return product.product_name.toLowerCase().includes(searchInput.toLowerCase());
  });

  const handleSearchInputChange = (e) => {
    setSearchInput(e.target.value);
  };

  return (
    <main className="relative">
      <section className="topSection">
        <h2 className="roleHeaders">All Products</h2>
        <Link to={`/${role}/addProduct`} className="roleBtn">
          Add Product
        </Link>
      </section>

      <section className="bg-white flex flex-col gap-3 py-4">
        <div className="flex justify-end m-3">
          <input
            type="text"
            name="productSearch"
            id="productSearch"
            placeholder="Search for Products here"
            className="roleSearch"
            value={searchInput}
            onChange={handleSearchInputChange}
          />
        </div>

        <div className="px-3 overflow-scroll">
          {loading ? 
          (<div className="flex justify-center">
            <Loading className={'w-[40px]'}/>
          </div>) 
          : (
          <div>
            <table className="min-w-full divide-y divide-gray-200 w-full table-auto">
              <thead>
                <tr className="tableHeadRow">
                  <th className="tableHead">S/N</th>
                  <th className="tableHead">Product Image</th>
                  <th className="tableHead">Product Name</th>
                  <th className="tableHead">Product Price</th>
                  <th className="tableHead">Product Quantity</th>
                  <th className="tableHead">Discounted Price</th>
                  <th className="tableHead">Product Status</th>
                  <th className="tableHead">Action</th>
                </tr>
              </thead>
              <tbody>
                {products ? (
                  filteredProducts.map((product, idx) => {
                    return (
                      <tr key={product.id} className='tableRow'>
                        <td>{idx + 1}</td>
                        <td className="w-[8%]">
                          <img
                            src={"https://api.ebn.ng/public"+ product.product_thumbnail}
                            alt="product 1"
                            className="w-[40px] h-[40px] rounded-full mx-auto"
                          />
                        </td>
                        <td className="w-[30%]">{product.product_name}</td>
                        <td>₦{formatPrice(product.selling_price)}</td>
                        <td className="w-[5%]">{product.product_qty}</td>
                        <td className="w-[5%]">₦{product.discount_price}</td>
                        <td className="w-[5%]">
                          <p className={`inline-block py-1 px-2 text-[12px] text-white rounded-full ${product.status === 'active' ? 'bg-green-400' : 'bg-red-500'}`}>{product.status}</p>
                        </td>
                        <td className="w-[15%]">
                          <div className="flex items-center">
                            <AiOutlineEye
                              className="productIcon"
                              onClick={() => viewProduct(product.id)}
                            />
                            <AiOutlineEdit
                              className="productIcon"
                              onClick={() => navigate(`/${role}/updateProduct/${product.id}`)}
                            />
                            <AiOutlineDelete
                              className="productIcon"
                              onClick={() => openModal(product.id)}
                            />
                            {role === 'admin' ? <button
                              type="button"
                              className="p-2 text-white text-sm rounded-sm bg-green-600"
                              disabled={product.status === 'active'}
                              onClick={() => activateProduct(product.id)}
                            >
                              {product.status === 'inactive' ? 'Activate' : 'Activated'}
                            </button> : <button
                              type="button"
                              className="p-2 text-white text-sm rounded-sm bg-green-600"
                              onClick={() =>
                                { 
                                  if(product.status === 'active'){
                                    auctionProduct(product.id)
                                  }else{
                                    toast.error('Product is inactive')
                                  }
                                }}
                            >
                              Auction
                            </button>}
                          </div>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <div>
                    <h2>Loading...</h2>
                  </div>
                )}
              </tbody>
            </table>
            <div className="my-8">
              <ReactPaginate
                previousLabel={"⇽"}
                nextLabel={"⇾"}
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={handlePageChange}
                containerClassName="paginateContainer"
                pageClassName="cursor-pointer py-1 px-2 rounded-full bg-gray-200 font-bold hover:bg-green-500 hover:text-white"
                previousClassName="nextPrev"
                nextClassName="nextPrev"
                activeClassName="bg-green-500 text-white"
              />
            </div>
          </div>)}
        </div>
        {modalOpen && (
          <div className="w-auto">
            <ModalSingleProduct
              data={product}
              closeModal={() => setModalOpen(false)}
            />
          </div>
        )}
        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
          <div className='flex flex-col items-center gap-4'>
            <img src={Danger} alt="" className='w-[100px]' />
            <h2 className='text-2xl'>Are you sure you want to delete?</h2>
            <div className='flex  gap-4 items-center'>
              <button type="button" className='bg-green-600 px-4 rounded-md py-1 text-white font-medium' onClick={() => { deleteProduct(deleteId)}}>Yes</button>
              <button type="button" className='bg-red-600 px-4 rounded-md py-1 text-white font-medium' onClick={() => setIsModalOpen(false)}>No</button>
            </div>
          </div>
        </Modal>
      </section>
    </main>
  );
};

export default AllProducts;
