import React from "react";
import { BiUpArrow, BiDownArrow } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { cartDecrement, cartIncrement, fetchCart } from "../redux/Features/cartSlice";
import { formatPrice } from "./FormatPrice";
import { MdDelete } from "react-icons/md";
import api from "../services/api";

const CartItem = ({ id, thumbnail, price, title, qty, subTotal, rowId }) => {
  const dispatch = useDispatch();


  const handleIncrement = async () => {
    try {
      await dispatch(cartIncrement(rowId)).unwrap()
      await dispatch(fetchCart()).unwrap();
    } catch (error) {
      console.error('Failed to update cart:', error);
    }

  };

  const handleDecrement = async () => {
    try{
      await dispatch(cartDecrement(rowId)).unwrap()
      await dispatch(fetchCart()).unwrap();
    }catch(error){
      console.error('Failed to update cart:', error); 
    }
  };

  const handleDelete = () => {
    console.log(`deleting ${rowId}`)
    api.customer.cart.deleteCartItem(rowId).then(res => console.log(res)).catch(console.error)
  }

  return (
    <article className="cartItem relative">
      {/* div1 */}
      <div className="cartItemImageText">
        <img
          src={"https://api.ebn.ng/public" + thumbnail}
          alt={id}
          className="w-[48px] h-[48px] object-contain"
        />
        <h3 className="cartItemTitle">{title}</h3>
      </div>
      {/* div2 */}
      <div>
        <h3 className="font-medium text-sm lg:text-lg">₦{formatPrice(price)}</h3>
      </div>
      {/* div3 */}
      <div className="cartItemQuantity">
        <p className="font-medium text-sm lg:text-lg">{qty}</p>
        <div className="cartItemControls">
          <button type="button" onClick={handleIncrement}>
            <BiUpArrow className="text-green-500" />
          </button>

          <button
            type="button"
            onClick={handleDecrement}
          >
            <BiDownArrow className="text-red-500" />
          </button>
        </div>
      </div>
      <MdDelete className='absolute right-1 top-1 cursor-pointer text-red-500' size={24} onClick={handleDelete} />
      {/* div4 */}
      <div>
        <h3 className="font-medium text-sm lg:text-lg">₦{formatPrice(subTotal)}</h3>
      </div>
    </article>
  );
};

export default CartItem;
