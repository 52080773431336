/* eslint-disable no-unused-vars */
import React from "react";
import { BsBell, BsFillPersonFill } from "react-icons/bs";
// import { FiMessageSquare } from "react-icons/fi";
import MenuDropdown from "./Dashboard/MenuDropdown";
import { useState } from "react";
// import Dropdown from "./Dashboard/Dropdown";
import ebn from "../../assets/ebn.svg";
import { GiHamburgerMenu } from "react-icons/gi";
import { useSelector } from "react-redux";

const Navbar = ({ toggleSidebar }) => {
  const [settingsDropdown, setSettingsDropdown] = useState(false);
  //  const [messageDropdown, setMessageDropdown] = useState(false);
  //  const [notificationDropdown, setNotificationDropdown] = useState(false);
  const { user } = useSelector((store) => store.auth);

  const handleSettingsDropdown = () => {
    setSettingsDropdown(!settingsDropdown);
    // setMessageDropdown(false);
    // setNotificationDropdown(false);
  };
  //  const handleMessageDropdown = () => {
  //   // setMessageDropdown(!messageDropdown);
  //   setSettingsDropdown(false);
  //   setNotificationDropdown(false);
  //  };
  //  const handleNotificationDropdown = () => {
  //   setNotificationDropdown(!notificationDropdown);
  //   // setMessageDropdown(false);
  //   setSettingsDropdown(false);
  //  };
  return (
    <header className="navbar">
      <div className="flex flex-row md:gap-4 items-center w-full md:w-[30%]">
        <GiHamburgerMenu
          className="text-2xl cursor-pointer"
          onClick={toggleSidebar}
        />
        <figure className="flex items-center justify-between gap-2">
          <img src={ebn} alt="logo" className="w-[50px] md:w-[100px]" />
          <h2>{user.name}</h2>
        </figure>
      </div>

      <nav className="navbar-nav">
        {/* <input
     type="search"
     name=""
     id=""
     className="navbar-navSearch"
     placeholder="Search here"
    /> */}
        <div className="navbar-navMenu">
          {/* <article
      onClick={handleNotificationDropdown}
      className="navbarIconContainer"
     >
      <BsBell className="navbarIcon" />
      <small className="navbarIconValue">7</small>
      {notificationDropdown && <Dropdown title="Notifications" />}
     </article> */}

          {/* <article onClick={handleMessageDropdown} className="navbarIconContainer">
      <FiMessageSquare className="navbarIcon" />
      <small className="navbarIconValue">7</small>
      {messageDropdown && <Dropdown title="Messages" />}
     </article> */}

          <article
            onClick={handleSettingsDropdown}
            className="navbarIconContainer"
          >
            <BsFillPersonFill className="navbarIcon" />
            {settingsDropdown && <MenuDropdown />}
          </article>
          {/* <div>
            <GiHamburgerMenu
              className="text-2xl cursor-pointer"
              onClick={toggleSidebar}
            />
          </div> */}
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
