import { useEffect, useState } from "react";
import api from "../../../services/api";
import { useSelector } from "react-redux";
import { formatDate, formatPrice } from "../../../components/FormatPrice";
import Loading from "../../../components/Loading";
import ReactPaginate from "react-paginate";

const AuctionedProducts = () => {
  const [displayType, setDisplayType] = useState("ongoing");
  const [auctioned, setAuctioned] = useState([]);
  const [loading, setLoading] = useState(false);

  const { user } = useSelector((store) => store.auth);
  const role = user.role;

  useEffect(() => {
    setLoading(true);
    if (role === "vendor") {
      api.vendor.auction
        .getAllAuctions()
        .then((res) => {
          setAuctioned(res.data?.auctions);
        })
        .catch((res) => console.log(res)).finally(()=>setLoading(false));
    } else {
      api.admin.bidding
        .getAllAuctions()
        .then((res) => {
          setAuctioned(res.data?.auctions);
        })
        .catch((res) => console.log(res)).finally(()=>setLoading(false));
    }
  }, [role]);

  // pagination
  const itemsPerPage = 7;
  const [currentPage, setCurrentPage] = useState(0);

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };
  const pageCount = Math.ceil(auctioned.length / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = auctioned.slice(startIndex, endIndex);

  return (
    <div>
      <h1 className="roleHeaders">All Auctions</h1>
      <div className="flex gap-4 justify-center items-center my-6">
        <button
          type="button"
          onClick={() => setDisplayType("ongoing")}
          className={displayType === "ongoing" ? "currentVendor" : "vendorBtn"}
        >
          Ongoing Auctions
        </button>
        <button
          type="button"
          onClick={() => setDisplayType("ended")}
          className={displayType === "ended" ? "currentVendor" : "vendorBtn"}
        >
          Ended Auctions
        </button>
      </div>
      <section>
        <div className="px-3 overflow-x-auto">
          {loading ? (
            <div className="flex justify-center">
              <Loading className={"w-[40px]"} />
            </div>
          ) : (
            <div>
              <table className="min-w-full divide-y divide-gray-200 border">
                <thead>
                  <tr className="tableHeadRow">
                    <th className="tableHead">S/N</th>
                    <th className="tableHead">Auction Id</th>
                    <th className="tableHead">Product Details</th>
                    <th className="tableHead">Auction Price</th>
                    <th className="tableHead">Start time</th>
                    <th className="tableHead">End time</th>
                    <th className="tableHead">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {displayType === "ongoing" &&
                    paginatedData
                      .filter((auction) => auction.status === "open")
                      .map((auction, index) => {
                        return (
                          <tr className="py-[10px] tableRow" key={auction.id}>
                            <td>{index + 1}</td>
                            <td>#{auction.id}</td>
                            <td className="">
                              <div className="flex flex-col gap-2">
                                <p>{auction.product_name}</p>
                                <img
                                  src={
                                    "https://ebn.itl.ng/public/upload/" +
                                    auction.thumbnail
                                  }
                                  alt=""
                                />
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              ₦{formatPrice(auction.auction_price)}
                            </td>
                            <td>{formatDate(auction.start_time)}</td>
                            <td>{formatDate(auction.end_time)}</td>
                            <td>{auction.status}</td>
                          </tr>
                        );
                      })}
                  {displayType === "ended" &&
                    paginatedData
                      .filter((auction) => auction.status === "close")
                      .map((auction, index) => {
                        return (
                          <tr className="py-[10px] tableRow" key={auction.id}>
                            <td>{index + 1}</td>
                            <td>#{auction.id}</td>
                            <td className="">
                              <div className="flex flex-col gap-2">
                                <p>{auction.product_name}</p>
                                <img
                                  src={
                                    "https://ebn.ng/public/upload/" +
                                    auction.thumbnail
                                  }
                                  alt=""
                                />
                              </div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap">
                              ₦{formatPrice(auction.auction_price)}
                            </td>
                            <td>{formatDate(auction.start_time)}</td>
                            <td>{formatDate(auction.end_time)}</td>
                            <td>{auction.status}</td>
                          </tr>
                        );
                      })}
                </tbody>
              </table>
              <div className="my-8">
                <ReactPaginate
                  previousLabel={"⇽"}
                  nextLabel={"⇾"}
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageChange}
                  containerClassName="paginateContainer"
                  pageClassName="cursor-pointer py-1 px-2 rounded-full bg-gray-200 font-bold hover:bg-green-500 hover:text-white"
                  previousClassName="nextPrev"
                  nextClassName="nextPrev"
                  activeClassName="bg-green-500 text-white"
                />
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default AuctionedProducts;
