import styled from "styled-components";
import Deals from "../components/landingPage/Deals";
import Vendor from "../components/landingPage/Vendor";
import BottomFeaturedCategory from "../components/landingPage/BottomFeaturedCategory";
import Subscription from "../components/landingPage/Subscription";
import NewProduct from "../components/landingPage/NewProduct";
import Showcase from "../components/landingPage/Showcase";
import { useEffect, useState } from "react";
import api from "../services/api";
import Modal from "../components/Cards/Modal";
import { formatPrice } from "../components/FormatPrice";
import TopFeaturedcategory from "../components/landingPage/TopFeaturedcategory";
import FeaturedProducts from "../components/landingPage/FeaturedProducts";
import OtherProducts from "../components/landingPage/OtherProducts";
import { addToCart } from "../redux/Features/cartSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";

const Container = styled.div`
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
`;

const LandingPage = () => {
  const [products, setProducts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [adding, setAdding] = useState(false);

  const [details, setDetails] = useState({
    size: "",
    quantity: 1,
    color: "",
  });

  const handleDetails = (e) => {
    const { name, value } = e.target;
    setDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const dispatch = useDispatch()

  const finalPrice = selectedProduct?.discount_price > 0 ? selectedProduct?.discount_price
    : selectedProduct?.selling_price;

  let sizes = [];
  if (selectedProduct && selectedProduct.product_size) {
    try {
      sizes = JSON.parse(selectedProduct.product_size);
    } catch (error) {
      console.error("Error parsing product sizes:", error);
    }
  }
  let colors = [];
  if (selectedProduct && selectedProduct?.product_color) {
    try {
      colors = JSON.parse(selectedProduct?.product_color);
    } catch (error) {
      console.error("Error parsing product sizes:", error);
    }
  }

  const handleView = (productId) => {
    const product = products.find(p => p.id === productId);
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedProduct(null);
  };

  useEffect(() => {
    api.customer.products
      .getProducts()
      .then((res) => {
        const products = res.data?.products.filter(
          (product) => product.status !== "inactive"
        );
        setProducts(products);
      })
      .catch((res) => console.log(res));
  }, []);

  const addingToCart = () => {
    setAdding(true)
    const { product_name, id, vendor_id } = selectedProduct;

    if ((sizes.length > 0 && colors.length > 0) && (details.color === "" || details.size === "")) {
      toast.warn('Kindly add a color or a size');
      setAdding(false)
      return;
    }
    const formData = new FormData();
    formData.append("product_name", product_name);
    formData.append("quantity", details.quantity);
    formData.append("color", details.color);
    formData.append("size", details.size);
    formData.append("vendor", vendor_id);

    // Dispatch the thunk action
    dispatch(addToCart({ id, formData }))
      .unwrap()
      .then((res) => {
        toast.success(`${product_name} added to cart`);
      })
      .catch((err) => {
        console.log(err);
        toast.error(`Failed to add ${product_name} to cart`);
      }).finally(()=>setAdding(false))
  };

  return (
    <Container>
      <Showcase />
      <TopFeaturedcategory />
      <BottomFeaturedCategory products={products} />
      <NewProduct onView={handleView} products={products}/>
      <FeaturedProducts onView={handleView} products={products}/>
      <OtherProducts onView={handleView} products={products}/>
      <Deals products={products} />
      <Vendor />
      <Subscription />
      <Modal isOpen={isModalOpen} onClose={handleCloseModal}>
        {selectedProduct && (
          <section className="flex flex-col lg:flex-row gap-8 mt-[50px] w-[80%] mx-auto">
            {/* Product slider */}
            <div className="w-full lg:w-[40%]">
              <img
                src={"https://api.ebn.ng/public" + selectedProduct.product_thumbnail}
                alt={"just"}
                className="w-full  object-contain"
              />
            </div>
            {/* Product Details */}
            <aside className="w-full lg:w-[55%]">
              <h3 className="text-xl lg:text-2xl w-full">
                {selectedProduct?.product_name}
              </h3>

              <div className="flex flex-col gap-4 my-6">
                <article className="flex gap-3 items-center justify-between">
                  <p>Size :</p>
                  <select name="size" id="size" value={details.size} className="w-[80%]" onChange={handleDetails}>
                    <option value="">Select a size</option>
                    {sizes.map((size) => {
                      return (
                        <option key={size} value={size}>
                          {size}
                        </option>
                      );
                    })}
                  </select>
                </article>
                <article className="flex gap-3  items-center justify-between">
                  <p>Color :</p>
                  <select name="color" id="color" value={details.color} onChange={handleDetails} className="w-[80%]">
                    <option value="">Select a color</option>
                    {colors.map((color) => {
                      return (
                        <option key={color} value={color}>
                          {color}
                        </option>
                      );
                    })}
                  </select>
                </article>
              </div>

              {/* the ratings and reviews with price section */}
              <div className="mt-[20px]">
                {/* details */}
                <div className="flex gap-3 items-center">
                  <h3 className="text-xl lg:text-2xl my-2 font-bold">
                    ₦{formatPrice(finalPrice)}
                  </h3>
                  {selectedProduct.discount_price > 0 && (
                    <h3 className="text-xl lg:text-2xl my-2 font-bold text-red-900">
                      ₦
                      <strike>
                        {formatPrice(selectedProduct.selling_price)}
                      </strike>
                    </h3>
                  )}
                </div>
                {/* extra details */}
                <div>
                  {/* buttons redux */}
                  <div className="mt-[20px]">
                    <div className="mt-4">
                      <button
                        type="button"
                        disabled={adding === true}
                        onClick={addingToCart}
                        className="py-3 px-5 bg-green-700 text-white font-medium rounded-md"
                      >
                        {adding ? <CircularProgress size={20} sx={{color:'whitesmoke'}}/> :'Add to Cart'}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </aside>
          </section>
        )}
      </Modal>
    </Container>
  );
};

export default LandingPage;
