import React from "react";

const Cards = ({cardTitle, cardBg, cardValue}) => {
 return (
  <div className="w-auto h-[150px] border-[3px] border-blue-400 rounded-md py-4 px-3 flex flex-col gap-6 hover:bg-blue-400 hover:text-white transition-colors ease-in duration-1000 cursor-pointer">
   <h3 className="font-bold text-xl">{cardTitle}</h3>
   <p className="font-semibold text-lg">{cardValue}</p>
  </div>
 );
};

export default Cards;
