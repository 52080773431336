import React from "react";
import styled from "styled-components";
import FeaturedCard from "../Cards/FeaturedCard";
import { shuffleArray } from "../FormatPrice";
import { useEffect } from "react";
import api from "../../services/api";
import { useState } from "react";

const TopFeaturedcategory = () => {
  const [categories, setCategories] = useState([])
  
  useEffect(() => {
    const fetchCategoriesWithProducts = async () => {
      try {
        // Fetch the list of categories
        const res = await api.customer.category.getCategories();
        const categories = res.data?.categories;
  
        // Fetch products for each category using their ID
        const categoriesWithProducts = await Promise.all(
          categories.map(async (category) => {
            try {
              const categoryProducts = await api.customer.products.productsByCategory(category.id);
              return {
                ...category,
                productsCount: categoryProducts.data?.['products-counts']
              };
            } catch (err) {
              console.error(`Error fetching products for category ${category.id}`, err);
              return { ...category, productsCount: 0 }; 
            }
          })
        );
        setCategories(categoriesWithProducts);
      } catch (err) {
        console.error('Error fetching categories', err);
      }
    };
  
    fetchCategoriesWithProducts();
  }, []);

  const shuffledCategories = shuffleArray(categories).slice(0, 5)
 return (
  <Container className="justify-start md:justify-center">

    {shuffledCategories.map((val, idx) => (
     <FeaturedCard
      featureImage={val.category_image}
      category={val.category_name}
      categoryId={val.id}
      featureStock={val.productsCount}
      key={idx}
     />
    ))}

  </Container>
 );
};

export default TopFeaturedcategory;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  padding: 0 5px;
  align-items: center;
  margin: 30px auto;
  overflow-x:scroll

  &::-webkit-scrollbar {
    display: none;
  }
`;

